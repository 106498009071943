import React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';


const Navbar = () => {
  const { logOut } = useContext(AuthContext);
  const logout = () => {
    logOut()
  }

  const token = localStorage.getItem('cribbeeToken');
  let publicUrl = process.env.PUBLIC_URL + '/'
  return (
    <div>
      <div className="navbar-area">
        <nav className="navbar navbar-area navbar-expand-lg">
          <div className="container nav-container">
            <div className="responsive-mobile-menu">
              <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse" data-target="#realdeal_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                <span className="icon-left" />
                <span className="icon-right" />
              </button>
            </div>
            <div className="logo readeal-top logo-wrapper mobile-logo">
              <Link to="/"><img className="img-logo" src={process.env.PUBLIC_URL + "/assets/img/logo.png"} alt="Cribbee"/></Link>
            </div>
            <div className="collapse navbar-collapse" id="realdeal_main_menu">
              <ul className="navbar-nav menu-open readeal-top">
                <li className="menu-item-has-children current-menu-item">
                  <li><Link to="/">Inicio</Link></li>
                  <ul className="sub-menu">
                    <li><Link to="/cribbee">¿Qué es cribbee?</Link></li>
                    <li><Link to="/cribbee">¿Cómo funciona cribbee?</Link></li>
                  </ul>
                </li>
                <li><Link to="/propiedades">Propiedades</Link></li>
                <li><Link to="/paquetes">Paquetes</Link></li>
                <li><a href="https://blog.casyter.com" target="_blank">Blog</a></li>
                {
                  token != null ?
                    <li className="menu-item-has-children current-menu-item">
                      <li><Link to="/mi-cuenta" >Mi cuenta</Link></li>
                      <ul className="sub-menu">
                        <li><Link to="/mi-cuenta">Actualizar perfil</Link></li>
                        <li><a href="#" onClick={() => logout()}>Cerrar Sesión</a></li>
                      </ul>
                    </li>
                    :
                    <li><Link to="/iniciar-sesion">Iniciar Sesión</Link></li>
                }
                 <li className="property-mobile"><Link to="/publicar-propiedad">Publicar Propiedad</Link></li>
              </ul>
            </div>
            <div className="nav-right-part nav-right-part-desktop readeal-top">
              <Link className="btn btn-yellow" to="/publicar-propiedad">PUBLICAR PROPIEDAD <span className="right"><i className="la la-plus" /></span></Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}


export default Navbar