import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropiedadesRecomendadas from './propiedades-recomendadas';
import { cribbeeAPI } from '../../api/cribbeeAPI';




const Featured = (props) => {
  let Customclass = props.Customclass ? props.Customclass : 'pd-top-60';
  const [data, setData] = useState({ principal: { image: '', url: '', externalTitle: '', description: '', precio: 0 }, otras: [] });

  useEffect(() => {
    const listProperties = async () => {
      try {
        const { data: { data: info } } = await cribbeeAPI.get(`/list-properties?limit=${7}&users=${localStorage.getItem('cribbeeUsuario')}&filterP=1`);
        let principal = {
          principal: {
            image: "https://other.casyter.com/medium-image.png",
            url: ``,
            externalTitle: "",
            description: "",
            precio: "",
            plan: "",
            likeProperty: ""
          }
        };
        if (info.length > 0) {
          principal = {
            principal: {
              image: info[0].image[0] != undefined ? info[0].image[0].source_path : "https://other.casyter.com/medium-image.png",
              url: `/detalle-propiedad/${info[0].id}`,
              externalTitle: info[0].title,
              description: info[0].description,
              precio: info[0].price,
              plan: info[0].plan_id,
              likeProperty: info[0].likeProperty
            }
          };

        }
        const otras = [];
        for (var index = 1; index < info.length; index++) {
          const arrayServices = [];

          if (info[index].rooms != null) {
            arrayServices.push({ icon: 'fa fa-bed', value: info[index].rooms, title: "" });
          }
          if (info[index].bathrooms != null) {
            arrayServices.push({ icon: 'fa fa-bath', value: info[index].bathrooms, title: "" });
          }
          if (info[index].square_meters_build != null) {
            arrayServices.push({ icon: 'fa fa-object-ungroup', value: info[index].square_meters_build, title: "mts2" });
          }
          if (info[index].square_meters_build != null) {
            arrayServices.push({ icon: 'fa fa-car', value: info[index].parking_lots, title: "" });
          }


          if (arrayServices.length == 0) {
            for (var indexS = 0; indexS < 2; indexS++) {
              if (typeof info[index].servicios[indexS] !== 'undefined') {
                arrayServices.push({ icon: 'fa fa-check', value: info[index].servicios[indexS].id, title: info[index].servicios[indexS].service });
              }
            }
          }
          if (arrayServices.length == 0) {
            if (info[index].amenidades.length > 0) {
              for (var indexA = 0; indexA < 2; indexA++) {
                if (typeof info[index].amenidades[indexA] !== 'undefined') {
                  arrayServices.push({ icon: 'fa fa-check', value: info[index].amenidades[indexA].id, title: info[index].amenidades[indexA].amenitie });
                }
              }
            }
          }

          if (arrayServices.length == 0) {
            if (info[index].espacios.length > 0) {
              for (var indexS = 0; indexS < 2; indexS++) {
                if (typeof info[index].espacios[indexS] !== 'undefined') {
                  arrayServices.push({ icon: 'fa fa-check', value: info[index].espacios[indexS].id, title: info[index].espacios[indexS].space });
                }
              }
            }
          }

          otras.push({
            image:
            {
              principal: info[index].image[0] != undefined ? info[index].image[0].source_path : "https://other.casyter.com/medium-image.png",
              secundaria: info[index].image[0] != undefined ? info[index].image[0].small_source_path : "https://other.casyter.com/small-image.png",
              secundarias: []
            },
            vendedor: `${info[index].city}, ${info[index].state}`,
            externalTitle: info[index].title,
            precio: info[index].price,
            items: arrayServices,
            url: `/detalle-propiedad/${info[index].id}`,
            description: info[index].description,
            plan: info[index].plan_id,
            id: info[index].id,
            likeProperty: info[index].likeProperty,
          })
        }

        setData({ ...principal, otras: otras });
      } catch (error) {
        console.error(error)
      }
    }
    listProperties();
  }, []);

  const handleViewProperty = async (property, url) => {
    try {
      await cribbeeAPI.post('/view-property-web', { property: property, user: localStorage.getItem('cribbeeUsuario'), });
      // <Redirect to={url}></Redirect>
      window.location.href = `#${url}`;
    } catch (error) {
      // console.error(error);
      window.location.href = `#${url}`;
      // <Redirect to={url}></Redirect>
    }
  }
  return <div className={"featured-area  " + Customclass}>
    <div className="container">
      <div className="section-title text-center">
        <h2 className="title">Propiedades destacadas</h2>
      </div>
      <div className="row justify-content-center">
        <div className="col-xl-6 col-lg-8">
          <div className="single-leading-feature">
            <div className="slf-overlay" />
            <div className={data.principal.plan == 1 ? "thumb iconOro" : "thumb iconPlata "}>
              <img src={data.principal.image} alt="Titulo" />
            </div>
            <div className="details">
              <h4 className="title readeal-top"><Link to={data.principal.url}>{data.principal.externalTitle}</Link></h4>
              <h5 className="price">{`${data.principal.precio}`}/MX</h5>
              <br />
              <a className="btn btn-yellow mt-2 px-3" style={{ cursor: 'pointer', color: 'white', height: '40px', lineHeight: '40px' }} onClick={() => {
                handleViewProperty(data.principal.id, data.principal.url);
              }}>
                Ver detalles
              </a>
            </div>
          </div>
        </div>
        <PropiedadesRecomendadas data={data.otras} />
      </div>
    </div>
  </div>

}

export default Featured
