import React, { useState, useEffect } from 'react';
import { cribbeeAPI } from '../../api/cribbeeAPI';

const OurPartner = () => {

  const [partner, setPartner] = useState([]);

  useEffect(() => {
    const listProperties = async () => {
      try {
        const { data: { data: info } } = await cribbeeAPI.get(`/list-partner`);

        const arrayImage = [];
        info.forEach(({ imagen1, url1 }) => {
          arrayImage.push({ imagen: imagen1, url: url1 })
        });

        setPartner(arrayImage);
      } catch (error) {
        console.error(error)
      }
    }
    listProperties();
  }, []);


  return <div className="client-area pd-top-92 pd-bottom-100">
    <div className="container">
      <div className="section-title text-center">
        <h2 className="title">Nuestros Aliados</h2>
      </div>
      <div className="row d-flex justify-content-center">
        {partner.map(({ imagen, url }, i) => <div key={i} className="col-3 mb-2 text-center"><a href={url} target="_aliado"><img src={imagen} alt="client" /></a></div>)}
      </div>


    </div>
  </div>
}

export default OurPartner