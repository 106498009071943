import React from 'react'

const Checkbox = (props) => {
    const { field, text, name, onChangeArray, otrosLabel, otrosInput, value } = props;
    let attributeValue = {};
    if (value != undefined) {
        if (value.length > 0) {
            const check = value.find(({ field: fieldC }) => fieldC === field);
            if (check != undefined) {
                attributeValue = {
                    "checked": "true"
                };
            }
        }
    }
    return (
        <>
            <label className="inputCheck">
                {text}
                <input
                    type="checkbox"
                    name={name}
                    onChange={
                        (e) => {
                            onChangeArray(name, { selected: e.target.checked, field: field, value: text })
                        }
                    }
                    {...(otrosInput)}
                    {...(attributeValue)}
                />
                <span className="checkmark" {...(otrosLabel)}></span>
            </label>
        </>
    )


}

export default Checkbox;
