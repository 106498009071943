
import React, { useEffect, useState, useRef } from 'react';

import GoogleMapReact from 'google-map-react';

import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';

import { useForm } from '../hooks/useForm';

import Select from './form-components/Select';
import Input from './form-components/Input';
import Textarea from './form-components/Textarea';
import Checkbox from './form-components/Checkbox';
import PropertyDetailsSection from './section-components/property-details';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { cribbeeAPI } from '../api/cribbeeAPI';

const axios = require('axios').default;



const initStatePropiedad = {
    id: "nuevo",
    imagesPropiedad: [],
    precio: 0,
    externalTitle: "",
    description: "",
    direccion: {
        ocultar: false,
        calle: "",
        colonia: "",
        noExt: "",
        cp: "",
        ciudad: "",
        estado: "",
        lat: 0,
        lng: 0
    },
    vendedor: {
        image: localStorage.getItem('cribbeeImage'),
        nombre: localStorage.getItem('cribbeeFullName'),
        tel: localStorage.getItem('cribbeePhone'),
        email: localStorage.getItem('cribbeeEmail'),
        direccion: "",
    },
    itemsProperty: [],
    servicios: [],
    espacios: [],
    amenidades: [],
    planos: [],
    recorrido: [],
    videos: [],
};




const EditProperty = (props) => {
    const { match: { params: { id } } } = props;


    //Funciones y useState para Wizard
    const initStateWizard = {
        tab1: true,
        item1: true,
        tab2: false,
        item2: false,
        tab3: false,
        item3: false,
        view: false,
        btnPrevious: false,
        btnSubmit: false,
        btnNext: true,
    };

    const [wizard, setWizard] = useState(initStateWizard);
    const { tab1, tab2, tab3, item1, item2, item3, view, btnPrevious, btnSubmit, btnNext } = wizard;

    const [loading, setLoading] = useState(true)

    const actionNext = () => {
        if (tab1) {
            setWizard({
                ...wizard,
                ["tab1"]: false,
                ["tab2"]: true,
                ["item1"]: false,
                ["item2"]: true,
                ["btnPrevious"]: true,
            });

        } else if (tab2) {

            let btnSubmitConfirm = false;
            if (showConfirmacion) {
                btnSubmitConfirm = true;
            }
            setWizard({
                ...wizard,
                ["tab2"]: false,
                ["tab3"]: true,
                ["item2"]: false,
                ["item3"]: true,
                ["view"]: true,
                ["btnNext"]: false,
                ["btnSubmit"]: btnSubmitConfirm,
            });

        }
        window.scrollTo({
            top: 400,
            behavior: "smooth"
        });
        setLoading(true);
    }

    const actionPrevious = () => {
        setShowConfirmacion(false);
        if (tab3) {
            setWizard({
                ...initStateWizard,
                ["tab2"]: true,
                ["item2"]: true,
                ["tab3"]: false,
                ["item3"]: false,
                ["btnPrevious"]: true,
            });
        } else if (tab2) {
            setWizard({
                ...wizard,
                ["tab1"]: true,
                ["item1"]: true,
                ["tab2"]: false,
                ["item2"]: false,
                ["btnPrevious"]: false,
            });
        }
        window.scrollTo({
            top: 400,
            behavior: "smooth"
        });
    }

    const actionItem = (item) => {
        setShowConfirmacion(false);
        setLoading(true);


        if (item == 1) {
            setWizard({
                ...initStateWizard,
            });
        } else if (item == 2) {
            setWizard({
                ...initStateWizard,
                ["tab1"]: false,
                ["tab2"]: true,
                ["item1"]: false,
                ["item2"]: true,
                ["btnPrevious"]: true,
            });
        } else if (item == 3) {
            let btnSubmitConfirm = false;
            if (showConfirmacion) {
                btnSubmitConfirm = true;
            }
            setWizard({
                ...initStateWizard,
                ["tab1"]: false,
                ["tab3"]: true,
                ["item1"]: false,
                ["item3"]: true,
                ["view"]: true,
                ["btnPrevious"]: true,
                ["btnSubmit"]: btnSubmitConfirm,
                ["btnNext"]: false,
            });
        }
        window.scrollTo({
            top: 400,
            behavior: "smooth"
        });
    }

    const [previewProperty, setPreviewProperty] = useState(initStatePropiedad)
    const handlePreviewProperty = () => {
        const filesPreview = listImages.sort((a, b) => {
            if (a.principal < b.principal) return 1;
            if (a.principal > b.principal) return -1;

            return 0;
        });
        const arrayPreview = [];
        filesPreview.forEach(({ imagen, file, name, principal }) => {
            arrayPreview.push(imagen);
        });

        const itemsProperty = [];

        bedRoom !== '' && itemsProperty.push({ icon: "fa fa-check", value: bedRoom, title: "Recámaras" });
        bathRoom !== '' && itemsProperty.push({ icon: "fa fa-check", value: bathRoom, title: "Baños" });
        halfBath !== '' && itemsProperty.push({ icon: "fa fa-check", value: halfBath, title: "1/2 Baños" });
        squareMetersBuild !== '' && itemsProperty.push({ icon: "fa fa-check", value: squareMetersBuild, title: "M2 Construcción" });
        squareMetersLand !== '' && itemsProperty.push({ icon: "fa fa-check", value: squareMetersLand, title: "M2 Terreno" });
        m2Deep !== '' && itemsProperty.push({ icon: "fa fa-check", value: m2Deep, title: "M2 Fondo" });
        m2Front !== '' && itemsProperty.push({ icon: "fa fa-check", value: m2Front, title: "M2 Frente" });
        parking !== '' && itemsProperty.push({ icon: "fa fa-check", value: parking, title: "Estacionamiento" });
        floor !== '' && itemsProperty.push({ icon: "fa fa-check", value: floor, title: "No. de plantas" });
        maintenance !== '' && itemsProperty.push({ icon: "fa fa-check", value: maintenance, title: "Mantenimiento" });

        if (regularIrregular !== 0) {
            const valueRegularIrregular = regularIrregular == 1 ? "Regular" : "Irregular";
            itemsProperty.push({ icon: "fa fa-check", value: valueRegularIrregular, title: "Regular o Irregular" });
        }
        if (antiquity !== 0) {
            const valueAntiquity = antiquity == 1 ? "A estrenar" : numYear !== '' ? `numYear Años` : "0 Años";
            itemsProperty.push({ icon: "fa fa-check", value: valueAntiquity, title: "Antigüedad" });
        }
        const servicesProperty = [];
        if (services.length > 0) {
            services.forEach(({ selected, value }) => {
                if (selected) {
                    servicesProperty.push({ value: value });
                }
            });
        }
        const spacesProperty = [];
        if (spaces.length > 0) {
            spaces.forEach(({ selected, value }) => {
                if (selected) {
                    spacesProperty.push({ value: value });
                }
            });
        }
        const amenitiesProperty = [];
        if (amenities.length > 0) {
            amenities.forEach(({ selected, value }) => {
                if (selected) {
                    amenitiesProperty.push({ value: value });
                }
            });
        }
        const arrayPlansPreview = [];
        plans.forEach(({ imagen, file, name, principal }) => {
            arrayPlansPreview.push(imagen);
        });
        assignValue("coords", {
            lat: coordsMaps.lat,
            lng: coordsMaps.lng
        })
        setPreviewProperty({
            id: "nuevo",
            imagesPropiedad: arrayPreview,
            externalTitle: externalTitle,
            precio: price,
            description: description,
            direccion: {
                ocultar: hideAddress,
                calle: street,
                colonia: colony,
                noExt: externalNumber,
                cp: cp,
                ciudad: city,
                estado: state,
                lat: coordsMaps.lat,
                lng: coordsMaps.lng
            },
            vendedor: {
                image: localStorage.getItem('cribbeeImage'),
                nombre: localStorage.getItem('cribbeeFullName'),
                tel: localStorage.getItem('cribbeePhone'),
                email: localStorage.getItem('cribbeeEmail'),
                direccion: "",
            },
            itemsProperty: itemsProperty,
            servicios: servicesProperty,
            espacios: spacesProperty,
            amenidades: amenitiesProperty,
            planos: arrayPlansPreview,
            recorrido: travels,
            videos: urls,
        });
    }



    //Validacion de informaciones



    const initialState = {
        id: 0, //id
        type_of_sale: "rent", //type_of_sale  Tipo Renta o Venta
        externalTitle: "", //title  Titulo Exterior
        internalTitle: "", //subtitle Titulo Exterior
        price: "", //price Precio
        propertyType: 0, //property_type Tipo Propiedad
        subTypeProperty: 0, //idSubtypeProperty Tipo Propiedad
        bedRoom: "", //rooms
        bathRoom: "", // bathRoom
        halfBath: "", // halfBath
        squareMetersBuild: "", // square_meters_build
        squareMetersLand: "", // square_meters_land
        m2Deep: "", // m2Deep
        m2Front: "", // m2Front
        parking: "", // parking_lots
        floor: "", //floor
        maintenance: "", // maintenance
        regularIrregular: 0, //regularIrregular
        antiquity: 0, //antiquity
        numYear: 0, //numYear
        description: "", //description
        services: [], // services
        spaces: [], // spaces
        amenities: [], // amenities
        street: "",  // street
        colony: "",  // colony
        externalNumber: "",  // externalNumber
        cp: "",  // cp
        city: "",  // city
        state: "",  // state
        hideAddress: false, // hideAddress
        coords: {
            lat: 0, // latitude
            lng: 0 // longitude
        },
        files: [], //property_resources

        urls: [], //Videos
        plans: [], //plans
        travels: [], //travels

    };
    const {
        type_of_sale, externalTitle, internalTitle, price, propertyType, subTypeProperty, bedRoom, bathRoom, halfBath, squareMetersBuild, squareMetersLand, m2Deep, m2Front, parking, floor, maintenance, regularIrregular, antiquity, numYear, description,
        services,
        spaces,
        amenities,
        street, colony, externalNumber, cp, city, state, hideAddress,
        coords: { lat: coordsLat, lng: coordsLng },
        files: listImages, urls, plans, travels,
        fieldRef, onChange, onChangeArray, assignValue, onRef, form, setFormValue } = useForm(initialState);



    const initialServices = [
        { text: "Gas Natural", field: "naturalGas" },
        { text: "Gas Estacionario", field: "stationaryGas" },
        { text: "Energía Solar", field: "solarEnergy" },
        { text: "Servicio de Agua", field: "water" },
        { text: "Calefacción", field: "calefaction" },
        { text: "Cable", field: "cable" },
        { text: "Electricidad", field: "electricity" },
        { text: "Pavimento", field: "paviment" },
        { text: "Aire acondicionado", field: "airConditioning" },
        { text: "Internet", field: "internet" },
        { text: "Instalación Eléctrica subterránea", field: "undergroundElectricity" },
    ];
    const initialEspacios = [
        { text: "Jardín", field: "privateGarden" },
        { text: "Balcón", field: "balcony" },
        { text: "Hidromasaje", field: "hydromassage" },
        { text: "Despensa", field: "pantry" },
        { text: "Cuarto de servicio", field: "serviceRoom" },
        { text: "Estudio", field: "study" },
        { text: "Parrilla", field: "grill" },
        { text: "Ático", field: "attic" },
        { text: "Oficina", field: "office" },
        { text: "Baño de servicio", field: "bathService" },
        { text: "Sala de cine", field: "homeTheather" },
        { text: "Terraza", field: "terrace" },
        { text: "Cuarto de Almacenamiento", field: "cellar" },
        { text: "Vestíbulo", field: "lobby" },
        { text: "Galería", field: "galery" },
    ];
    const initialAmenidades = [
        { text: "Gimnasio", field: "gym" },
        { text: "Cancha de tennis", field: "tennisCourt" },
        { text: "Jacuzzi", field: "jacuzzi" },
        { text: "Sauna", field: "sauna" },
        { text: "Piscina", field: "pool" },
        { text: "Cancha de fútbol", field: "soccerField" },
        { text: "Parrilla", field: "grill" },
        { text: "Lavandería", field: "laundry" },
        { text: "Área Infantil", field: "childrenArea" },
        { text: "Área de fiestas", field: "partyArea" },
        { text: "Sala de juegos", field: "gameRoom" },
        { text: "Co-working", field: "coWorking" },
    ];


    //Funciones y useState para Componente Informacion de Propiedad

    const actionSale = () => {
        assignValue("type_of_sale", "rent");
    }

    const actionIncome = () => {
        assignValue("type_of_sale", "sell");
    }

    //Funcion para mostrar los Substitutos de la propiedad
    const initStatePropertyType = [
        { value: 1, title: "Casa" },
        { value: 2, title: "Departamento" },
        { value: 3, title: "Terreno" },
        { value: 4, title: "Comercial" },
    ];

    const [subtypeProperty, setSubtypeProperty] = useState([]);
    const initStateProperty = {
        house: true,
        department: true,
        terrain: true,
        commercial: true
    };
    const [{ house, department, terrain, commercial }, setHideProperty] = useState(initStateProperty);
    useEffect(() => {
        if (propertyType != 0) {
            assignValue("bedRoom", "")
            assignValue("bathRoom", "")
            assignValue("halfBath", "")
            assignValue("squareMetersBuild", "")
            assignValue("squareMetersLand", "")
            assignValue("m2Deep", "")
            assignValue("m2Front", "")
            assignValue("parking", "")
            assignValue("floor", "")
            assignValue("maintenance", "")
            assignValue("regularIrregular", 0)
            assignValue("antiquity", 0)
            assignValue("numYear", 0)
        }
        switch (propertyType) {
            case 1:
                setSubtypeProperty([
                    { value: 1, title: "Casa" },
                    { value: 2, title: "Casa de playa" },
                    { value: 3, title: "Rancho" },
                    { value: 4, title: "Quinta" },
                    { value: 5, title: "Casa compartida" },
                    { value: 6, title: "Desarrollo horizontal" },
                    { value: 7, title: "Casa dentro de fraccionamiento" }
                ]);
                setHideProperty({
                    ...initStateProperty,
                    ["house"]: false,
                })
                setHideAntiquity(true);


                break;
            case 2:
                setSubtypeProperty([
                    { value: 1, title: "Departamento" },
                    { value: 2, title: "Condominio" },
                    { value: 3, title: "Studio" },
                    { value: 4, title: "Loft" },
                    { value: 5, title: "Departamento compartido" },
                    { value: 6, title: "Desarrollo vertical" },
                ]);
                setHideProperty({
                    ...initStateProperty,
                    ["department"]: false,
                })
                setHideAntiquity(true);
                break;
            case 3:
                setSubtypeProperty([
                    { value: 1, title: "Terreno" },
                    { value: 2, title: "Terreno industrial" },
                    { value: 3, title: "Terreno comercial" },
                ]);
                setHideProperty({
                    ...initStateProperty,
                    ["terrain"]: false,
                })
                setHideAntiquity(true);
                break;
            case 4:
                setSubtypeProperty([
                    { value: 1, title: "Comercial" },
                    { value: 2, title: "Bodega industrial" },
                    { value: 3, title: "Local comercial" },
                    { value: 4, title: "Nave industrial" },
                    { value: 5, title: "Oficina" },
                    { value: 6, title: "Local en plaza comercial" },
                ]);
                setHideProperty({
                    ...initStateProperty,
                    ["commercial"]: false,
                })
                setHideAntiquity(true);
                break;

            default:
                setSubtypeProperty([]);
                setHideProperty({
                    ...initStateProperty,
                })
                setHideAntiquity(true);
                break;
        }


    }, [propertyType])

    //Mostrar Antigüedad
    const [hideAntiquity, setHideAntiquity] = useState(true);

    useEffect(() => {
        if (antiquity == 2) {
            setHideAntiquity(false)
        } else {
            setHideAntiquity(true)
        }
    }, [antiquity])

    //Botones de ver mas 
    const [buttonText, setButtonText] = useState(true);
    const [buttonText2, setButtonText2] = useState(true);
    const [buttonText3, setButtonText3] = useState(true);


    //Google Maps 

    const initStateMapsCoords = { lat: 0, lng: 0, center: { lat: 24.2828918, lng: -100.8766044, zoom: 5 } };
    const [coordsMaps, setCoordsMaps] = useState(initStateMapsCoords);

    // Dirección desestructurada
    const [mapsAddress, setMapsAddress] = useState({});

    const getCoordsAddress = async (street, colony, externalNumber, cp, city, state) => {
        try {
            if (colony == '' || cp == '' || city == '' || state == '') {
                return;
            }
            const req = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(`${street} ${externalNumber},${colony},${cp} ${city} ${state},Mexico`)}&key=AIzaSyDjl4Ux0-5hYmLEVMdhB63ldRYCeIqcx7A`);

            const { status, results } = req.data;
            if (status == "OK") {
                let coloniaGoogle = "";
                let calleGoogle = "";
                let numExteriorGoogle = "";
                let cogidoPostalGoogle = "";
                let ciudadGoogle = "";
                let estadoGoogle = "";
                let paisGoogle = "";
                results[0].address_components.forEach(({ long_name, types }) => {
                    coloniaGoogle = types.findIndex((valor) => valor === "sublocality") !== -1 ? long_name : types.findIndex((valor) => valor === "route") !== -1 ? long_name : "";
                    calleGoogle = types.findIndex((valor) => valor === "route") !== -1 ? long_name : "";
                    numExteriorGoogle = types.findIndex((valor) => valor === "street_number") !== -1 ? long_name : "";
                    cogidoPostalGoogle = types.findIndex((valor) => valor === "postal_code") !== -1 ? long_name : "";
                    ciudadGoogle = types.findIndex((valor) => valor === "locality") !== -1 ? long_name : "";
                    estadoGoogle = types.findIndex((valor) => valor === "administrative_area_level_1") !== -1 ? long_name : "";
                    paisGoogle = types.findIndex((valor) => valor === "country") !== -1 ? long_name : "";
                })
                // setMapsAddress({
                //     calleGoogle: calleGoogle,
                //     numExteriorGoogle: numExteriorGoogle,
                //     coloniaGoogle: coloniaGoogle,
                //     cogidoPostalGoogle: cogidoPostalGoogle,
                //     ciudadGoogle: ciudadGoogle,
                //     estadoGoogle: estadoGoogle,
                //     paisGoogle: paisGoogle,
                //     addressCompleteGoogle: results[0].formatted_address,
                // });
                setMapsAddress({
                    calleGoogle: results[0].address_components[1].long_name,
                    numExteriorGoogle: results[0].address_components[0].long_name,
                    coloniaGoogle: results[0].address_components[2].long_name,
                    cogidoPostalGoogle: results[0].address_components[6].long_name,
                    ciudadGoogle: results[0].address_components[3].long_name,
                    estadoGoogle: results[0].address_components[4].long_name,
                    paisGoogle: results[0].address_components[5].long_name,
                    addressCompleteGoogle: results[0].formatted_address,
                });
                setCoordsMaps({
                    lat: results[0].geometry.location.lat,
                    lng: results[0].geometry.location.lng,
                    center: {
                        lat: results[0].geometry.location.lat,
                        lng: results[0].geometry.location.lng,
                        zoom: 18
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getCoordsAddress(street, colony, externalNumber, cp, city, state);
    }, [street, externalNumber, colony, cp, city, state]);


    const MyMarker = () => (<><img src={process.env.PUBLIC_URL + "/assets/img/icon.png"} width={20} style={{ marginLeft: "-12px", marginTop: "-10px" }} /></>);

    //Multimedia
    const [imageDelete, setImageDelete] = useState([]);
    const [ImageSelectedPrevious, setImageSelectedPrevious] = useState([]);
    const [arrayFiles, setArrayFiles] = useState([]);
    const [errorImage, setErrorImage] = useState(false);
    const fieldButtonDeleteImage = useRef({});


    const postHiecToBase64 = async (file) => {
        try {
            const form_data = new FormData();
            form_data.append("file", file);
            const { data: { data } } = await cribbeeAPI.post('/upload-heic-base64', form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return data;
        } catch (e) {
            console.log(e);
        }
    }

    const encoded = (file) => {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.onload = function (e) {
                resolve({ base64: e.target.result, name: file.name });
            }
            reader.readAsDataURL(file);
        })
    }


    const changeImage = async ({ target }) => {
        try {

            let array = [];
            const files = target.files;
            for (var i = 0; i < files.length; i++) {
                const file = files[i];
                const principal = i == 0 ? true : false;
                let imgBase64 = '';
                if (file.name.indexOf(".heic") != -1) {
                    const datos = await postHiecToBase64(file);

                    imgBase64 = datos;
                } else {
                    const { base64: image } = await encoded(file);
                    imgBase64 = image;
                }

                array.push({ imagen: imgBase64, name: file.name, principal: principal, id: null, delete: false });
            }
            setArrayFiles(array);
            setErrorImage(false);

            target.value = null;
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteImage = (index) => {

        if (Object.keys(fieldButtonDeleteImage.current).length !== 0) {
            for (const property in fieldButtonDeleteImage.current) {
                if (fieldButtonDeleteImage.current[property] !== null) {
                    fieldButtonDeleteImage.current[property].checked = false;
                }
            }
        }

        if (ImageSelectedPrevious[index].id === null) {
            ImageSelectedPrevious.splice(index, 1);
            ImageSelectedPrevious.filter(function () { return true; });
        } else {
            ImageSelectedPrevious[index].imagen = null;
            ImageSelectedPrevious[index].principal = false;
            ImageSelectedPrevious[index].delete = true;
            setImageDelete([...imageDelete, ImageSelectedPrevious[index]]);
            ImageSelectedPrevious.splice(index, 1);
            ImageSelectedPrevious.filter(function () { return true; });
        }

        let imagePrincipalIndex = 0;
        if (index === 0 && ImageSelectedPrevious.length > 0) {
            imagePrincipalIndex = index + 1;
        } else {
            imagePrincipalIndex = ImageSelectedPrevious.findIndex(x => x.principal === false);
        }

        setImageSelectedPrevious([...ImageSelectedPrevious]);
        assignValue("files", [...ImageSelectedPrevious]);
        setArrayFiles([]);
        const valIndexIS = ImageSelectedPrevious.indexOf(x => x.principal === true);
        if (valIndexIS === -1) {
            if (ImageSelectedPrevious.length > 0) {
                ImageSelectedPrevious[imagePrincipalIndex].principal = true;
            }
        }
    }

    const handlePrincipalImage = (e, index) => {
        ImageSelectedPrevious.forEach((v, i) => {
            if (i === index) {
                ImageSelectedPrevious[i] = { ...ImageSelectedPrevious[i], principal: e.target.checked };
            } else {
                ImageSelectedPrevious[i] = { ...ImageSelectedPrevious[i], principal: false };
            }

        });
        setImageSelectedPrevious(ImageSelectedPrevious);
        assignValue("files", ImageSelectedPrevious);
        setArrayFiles([]);
    }

    const srcImg = ImageSelectedPrevious.map(
        ({ imagen, file, name, principal, delete: deleteImage }, index) => <div className="col-md-4 mb-2" key={index}>
            <div className="p-2" style={{ border: `1px dashed #${principal ? "CD7920" : "FBB138"}` }}>
                <div className="d-flex align-items-center justify-content-center">
                    <img src={imagen} className="img-fluid" style={{ height: "200px", width: "200px", objectFit: "cover" }} />
                </div>
                <p className="text-center">
                    {name.slice(0, 20) + (name.length > 20 ? "[...]" : "")}
                </p>
                <hr style={{ borderTop: `1px dashed #${principal ? "CD7920" : "FBB138"}` }} />
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-md-8">
                        <label className="content-input" >
                            <input type="radio" name="mainImage" id={`mainImage${index}`} onClick={e => handlePrincipalImage(e, index)}
                                ref={
                                    el => {
                                        fieldButtonDeleteImage.current = {
                                            ...fieldButtonDeleteImage.current,
                                            [`mainImage${index}`]: el
                                        };
                                        if (principal) {
                                            if (el !== null) {
                                                el.checked = true;
                                            }
                                        }
                                    }
                                }
                            />
                            <span className="checkmark-radio" style={{ height: "20px", width: "20px", top: "3px" }}></span>
                            <span className="ml-4">Foto principal</span>
                        </label>

                    </div>
                    <div className="col-md-3">
                        <button type="button" className="btn btn-sm text-danger p-1" style={{ height: "30px", lineHeight: "100%", width: "30px", background: "#dc354526", borderRadius: "5px" }} onClick={e => handleDeleteImage(index)}><i className="fa fa-times"></i></button>
                    </div>
                </div>
            </div>
        </div>
    )

    //Planos

    const [plansSelectedPrevious, setPlansSelectedPrevious] = useState([])
    const [arrayFilesPlanos, setArrayFilesPlanos] = useState([])

    const changePlanos = async ({ target }) => {
        try {
            let array = [];
            const files = target.files;
            for (var i = 0; i < files.length; i++) {
                const file = files[i];
                const principal = i == 0 ? true : false;
                const { base64: image } = await encoded(file);

                array.push({ imagen: image, name: file.name, principal: principal });
            }
            setArrayFilesPlanos(array);
            target.value = null;
        } catch (error) {
            console.error(error)
        }
    }

    const handleAddPlan = () => {
        (arrayFilesPlanos.length > 0) && setPlansSelectedPrevious([...plansSelectedPrevious, ...arrayFilesPlanos]);
        assignValue("plans", [...plansSelectedPrevious, ...arrayFilesPlanos]);
        setArrayFilesPlanos([])
    }

    const handleDeletePlanos = (index) => {
        plansSelectedPrevious.splice(index, 1);
        setPlansSelectedPrevious([...plansSelectedPrevious]);
        assignValue("plans", [...plansSelectedPrevious]);
        setArrayFilesPlanos([]);
    }

    const handlePrincipalPlanos = (e, index) => {
        plansSelectedPrevious.forEach((v, i) => {
            if (i === index) {
                plansSelectedPrevious[i] = { imagen: plansSelectedPrevious[i].imagen, file: plansSelectedPrevious[i].file, name: plansSelectedPrevious[i].name, principal: e.target.checked };
            } else {
                plansSelectedPrevious[i] = { imagen: plansSelectedPrevious[i].imagen, file: plansSelectedPrevious[i].file, name: plansSelectedPrevious[i].name, principal: false };
            }

        });
        setPlansSelectedPrevious(plansSelectedPrevious);
        assignValue("plans", plansSelectedPrevious);
        setArrayFilesPlanos([]);
    }

    const plansDiv = plansSelectedPrevious.map(
        ({ imagen, file, name, principal }, index) => <div className="col-md-4 mb-2" key={index}>
            <div className="p-2" style={{ border: `1px dashed #${principal ? "CD7920" : "FBB138"}` }}>
                <div className="d-flex align-items-center justify-content-center">
                    <img src={imagen} className="img-fluid" style={{ height: "200px", width: "200px", objectFit: "cover" }} />
                </div>
                <p className="text-center">
                    {name.slice(0, 20) + (name.length > 20 ? "[...]" : "")}
                </p>
                <hr style={{ borderTop: `1px dashed #${principal ? "CD7920" : "FBB138"}` }} />
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-md-8">
                        <div className="form-check ">
                            <input className="form-check-input" type="radio" name="mainPlans" id={`mainPlans${index}`} defaultChecked={principal} onClick={e => handlePrincipalPlanos(e, index)} />
                            <label className="form-check-label" htmlFor={`mainPlans${index}`}>
                                Foto principal
                            </label>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <button type="button" className="btn btn-sm text-danger p-1" style={{ height: "30px", lineHeight: "100%", width: "30px", background: "#dc354526", borderRadius: "5px" }} onClick={e => handleDeletePlanos(index)}><i className="fa fa-times"></i></button>
                    </div>
                </div>
            </div>
        </div>
    )

    //Videos
    const [inputURL, setInputURL] = useState("");
    const [arrayInputURL, setArrayInputURL] = useState([]);

    const ValidationURL = () => {
        var pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        if (pattern.test(inputURL)) {
            if (inputURL.includes('https://www.youtube.com/watch?v=')) {
                setArrayInputURL([...arrayInputURL, `https://www.youtube.com/embed/${inputURL.substr(32)}`]);
                assignValue("urls", [...arrayInputURL, `https://www.youtube.com/embed/${inputURL.substr(32)}`]);
            } else {
                setArrayInputURL([...arrayInputURL, inputURL]);
                assignValue("urls", [...arrayInputURL, inputURL]);
            }
            setInputURL("");
        }
    }

    const handleDeleteURL = (index) => {
        arrayInputURL.splice(index, 1);
        setArrayInputURL([...arrayInputURL]);
        assignValue("urls", [...arrayInputURL]);
    }

    //Videos
    const [inputRecorrido, setInputRecorrido] = useState("");
    const [arrayInputRecorrido, setArrayInputRecorrido] = useState([]);
    const [errorURLRecorrido, setErrorURLRecorrido] = useState(false);

    const ValidationRecorrido = () => {
        var pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        if (pattern.test(inputRecorrido)) {
            if (inputRecorrido.includes('https://kuula.co/share/collection/')) {
                setArrayInputRecorrido([...arrayInputRecorrido, inputRecorrido]);
                assignValue("travels", [...arrayInputRecorrido, inputRecorrido]);
                setInputRecorrido("");
                setErrorURLRecorrido(false);
            } else {
                setErrorURLRecorrido(true);
            }
        } else {
            setErrorURLRecorrido(true);
        }
    }

    const handleDeleteRecorrido = (index) => {
        arrayInputRecorrido.splice(index, 1);
        setArrayInputRecorrido([...arrayInputRecorrido]);
        assignValue("travels", [...arrayInputRecorrido]);
    }

    //Vista Previa del la publicacion de la Propiedad y Realizar tu pago
    const [showConfirmacion, setShowConfirmacion] = useState(false)

    if (view && loading) {
        setTimeout(() => {
            setLoading(false);
            handlePreviewProperty()
        }, 3000);
    }

    //Registrar información

    const initStateRegistro = {
        companyName: '',
        taxStatus: '',
        rfc: '',
        areaCode: '',
        email: '',
        tel: '',
        cardholder: '',
        CardNumber: '',
        expiration: '',
        securityCode: '',
        termsAndConditions: false,
        stripeID: '',
    };

    const [errorTermsAndConditions, setErrorTermsAndConditions] = useState(false);

    const {
        companyName, taxStatus, rfc, areaCode, email, tel, cardholder, CardNumber, expiration, securityCode, termsAndConditions, stripeID,
        fieldRef: fieldRefLR, onChange: onchangeLR, onChangeArray: onChangeArrayLR, assignValue: assignValueLR, onRef: onRefLR, form: formLR, setFormValue: setFormValueLR } = useForm(initStateRegistro);

    const handleSubmit = async () => {
        try {
            const { data: { data } } = await cribbeeAPI.post('/edit-properties',
                {
                    dataProperty: form,
                    dataAddressProperty: mapsAddress,
                    imageDelete: imageDelete,
                    paymentMethod: null
                }
            );
            return { status: true, info: "Se ha guardado correctamente" };

        } catch (error) {
            console.log(error);
            return { status: false, info: error };
        }
    }


    const handleLoading = () => {
        const resolveWithSomeData = new Promise((resolve, reject) => setTimeout(async () => {
            let { status, info } = await handleSubmit();
            if (status) {
                resolve(info)
            } else {
                reject(info)
            }
        }, 1000));
        toast.promise(
            resolveWithSomeData,
            {
                pending: {
                    render() {
                        return "Editando..."
                    },
                    icon: true,
                },
                success: {
                    render({ data }) {
                        setTimeout(() => window.location = '#/mi-cuenta', 3000)
                        return data
                    },
                    icon: true,
                },
                error: {
                    render({ data }) {
                        return data.message
                    }
                }
            }
        )


    }



    useEffect(() => {
        const getPropiedad = async () => {
            try {
                const { data: { data: info } } = await cribbeeAPI.get(`/list-properties-usuario?propiedad=${id}&usuario=${localStorage.getItem('cribbeeToken')}`);
                const arrayServicios = [];
                if (info[0].servicios.length > 0) {
                    for (var indexS = 0; indexS < info[0].servicios.length; indexS++) {
                        if (typeof info[0].servicios[indexS] !== 'undefined') {
                            const serviosDB = initialServices.find(({ text }) => text === info[0].servicios[indexS].service);
                            if (serviosDB != undefined) {
                                arrayServicios.push({ selected: true, field: serviosDB.field, value: serviosDB.text });
                            }
                        }
                    }
                }

                const arrayAmenidades = [];
                if (info[0].amenidades.length > 0) {
                    for (var indexA = 0; indexA < info[0].amenidades.length; indexA++) {
                        if (typeof info[0].amenidades[indexA] !== 'undefined') {
                            const amenidadesBD = initialAmenidades.find(({ text }) => text === info[0].amenidades[indexA].amenitie);
                            if (amenidadesBD != undefined) {
                                arrayAmenidades.push({ selected: true, field: amenidadesBD.field, value: amenidadesBD.text });
                            }

                        }
                    }
                }

                const arrayEspacios = [];
                if (info[0].espacios.length > 0) {
                    for (var indexS = 0; indexS < info[0].espacios.length; indexS++) {
                        if (typeof info[0].espacios[indexS] !== 'undefined') {
                            const espaciosBD = initialEspacios.find(({ text }) => text === info[0].espacios[indexS].space);
                            if (espaciosBD != undefined) {
                                arrayEspacios.push({ selected: true, field: espaciosBD.field, value: espaciosBD.text });
                            }
                        }
                    }
                }

                const arrayImagenes = [];
                if (info[0].image.length > 0) {
                    for (var indexS = 0; indexS < info[0].image.length; indexS++) {
                        if (typeof info[0].image[indexS] !== 'undefined') {

                            arrayImagenes.push({ id: info[0].image[indexS].id, imagen: info[0].image[indexS].base64, name: `${indexS}-imagen.png`, principal: info[0].image[indexS].principal === 1 ? true : false, delete: false });
                        }
                    }
                }


                const arrayPlanos = [];
                if (info[0].planos.length > 0) {
                    for (var indexS = 0; indexS < info[0].planos.length; indexS++) {
                        if (typeof info[0].planos[indexS] !== 'undefined') {
                            if (indexS === 0) {
                                arrayPlanos.push({ imagen: info[0].planos[indexS].base64, name: `${indexS}-planos-imagen.png`, principal: true });
                            } else {
                                arrayPlanos.push({ imagen: info[0].planos[indexS].base64, name: `${indexS}-planos-imagen.png`, principal: false });
                            }
                        }
                    }
                }

                const arrayVideos = [];
                if (info[0].videos.length > 0) {
                    for (var indexS = 0; indexS < info[0].videos.length; indexS++) {
                        if (typeof info[0].videos[indexS] !== 'undefined') {
                            arrayVideos.push(info[0].videos[indexS].url);
                        }
                    }
                }

                const arrayRecorrido = [];
                if (info[0].recorrido.length > 0) {
                    for (var indexS = 0; indexS < info[0].recorrido.length; indexS++) {
                        if (typeof info[0].recorrido[indexS] !== 'undefined') {
                            arrayRecorrido.push(info[0].recorrido[indexS].travel);
                        }
                    }
                }
                setFormValue({
                    id: info[0].id, //type_of_sale  Tipo Renta o Venta
                    type_of_sale: info[0].type_of_sale, //type_of_sale  Tipo Renta o Venta
                    externalTitle: info[0].title, //title  Titulo Exterior
                    internalTitle: info[0].subtitle, //subtitle Titulo Exterior
                    price: info[0].price, //price Precio
                    propertyType: info[0].property_type, //property_type Tipo Propiedad
                    subTypeProperty: info[0].idSubtypeProperty, //idSubtypeProperty Tipo Propiedad
                    bedRoom: info[0].rooms, //rooms
                    bathRoom: info[0].bathrooms, // bathrooms
                    halfBath: info[0].halfBath, // halfBath
                    squareMetersBuild: info[0].square_meters_build, // square_meters_build
                    squareMetersLand: info[0].square_meters_land, // square_meters_land
                    m2Deep: info[0].m2Deep, // m2Deep
                    m2Front: info[0].m2Front, // m2Front
                    parking: info[0].parking_lots, // parking_lots
                    floor: info[0].floor, //floor
                    maintenance: info[0].maintenance, // maintenance
                    regularIrregular: info[0].regularIrregular, //regularIrregular
                    antiquity: info[0].antiquity, //antiquity
                    numYear: info[0].numYear === '' ? 0 : info[0].numYear, //numYear
                    description: info[0].description, //description
                    services: arrayServicios, // services
                    spaces: arrayEspacios, // spaces
                    amenities: arrayAmenidades, // amenities
                    street: info[0].street,  // street
                    colony: info[0].colony,  // colony
                    externalNumber: info[0].externalNumber,  // externalNumber
                    cp: info[0].cp,  // cp
                    city: info[0].city,  // city
                    state: info[0].state,  // state
                    hideAddress: info[0].hideAddress == 0 ? false : true, // hideAddress
                    coords: {
                        lat: info[0].latitude, // latitude
                        lng: info[0].longitude // longitude
                    },
                    files: arrayImagenes, //property_resources
                    urls: [], //Videos
                    plans: [], //plans
                    travels: [], //travels

                });

                setImageSelectedPrevious(arrayImagenes);

                setArrayInputURL(arrayVideos);
                setPlansSelectedPrevious(arrayPlanos);
                setArrayInputRecorrido(arrayRecorrido);

            } catch (error) {
                console.error(error)
            }
        }
        getPropiedad();
        window.scrollTo({
            top: 400,
            behavior: "smooth"
        });
        setTimeout(() =>{
            const $ = window.$;

            var preLoder = $("#preloader");
            preLoder.attr("style", "display:none");
        }, 2000)
    }, [id]);


    return (
        <>
            <Navbar />
            <PageHeader headertitle="Editar propiedad" />
            <div className="add-new-property-area pd-top-90 mg-bottom-100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-11 col-lg-12">
                            <div className="section-title text-center">
                                <h3>Publicar propiedad</h3>
                            </div>
                            <div className="border-bottom mb-4">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-3">
                                        <div className="single-intro style-two text-center" style={{ cursor: 'pointer' }} onClick={() => actionItem(1)}>
                                            <div className="thumb" style={item1 ? { background: "#C9792C" } : { background: '#FFB71A' }}>
                                                1
                                            </div>
                                            <div className="details">
                                                <h5 className="title">Información de propiedad</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="single-intro style-two text-center" style={{ cursor: 'pointer' }} onClick={() => actionItem(2)}>
                                            <div className="thumb" style={item2 ? { background: "#C9792C" } : { background: '#FFB71A' }}>
                                                2
                                            </div>
                                            <div className="details">
                                                <h5 className="title">Multimedia</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="single-intro style-two text-center" style={{ cursor: 'pointer' }} onClick={() => actionItem(3)}>
                                            <div className="thumb" style={item3 ? { background: "#C9792C" } : { background: '#FFB71A' }}>
                                                3
                                            </div>
                                            <div className="details">
                                                <h5 className="title">Vista Previa</h5>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="tab-content" >

                                <div className={tab1 ? "tab-pane fade show active" : "tab-pane fade"}>

                                    {/*------------------------ Información de la propiedad ------------------------*/}
                                    <h4 className="text-center">Información de la propiedad</h4>
                                    <div className="text-center add-property-btn-wrap pt-4">
                                        <button type="button"
                                            className="btn btn-yellow rounded-0 px-5"
                                            style={type_of_sale === "sell" ? { background: "#C9792C" } : { background: "#FFFFFF", color: "#000000", border: "1px solid #dee2e6" }}
                                            onClick={actionIncome}>
                                            Venta
                                        </button>
                                        <button type="button"
                                            className="btn btn-yellow rounded-0 px-5 mr-md-3"
                                            style={type_of_sale === "rent" ? { background: "#C9792C" } : { background: "#FFFFFF", color: "#000000", border: "1px solid #dee2e6" }}
                                            onClick={actionSale}>
                                            Renta
                                        </button>
                                    </div>
                                    <div className="row pt-4">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-lg-6 mb-3">
                                                    <Input type="text" placeholder="Titulo Externo" name="externalTitle" value={externalTitle} onChange={onChange} required={true} message="Se requiere el Titulo de Externo" inputRef={onRef} section={1} />
                                                </div>

                                                <div className="col-lg-6 mb-3">
                                                    <Input type="text" placeholder="Titulo Interno" name="internalTitle" value={internalTitle} onChange={onChange} required={true} message="Se requiere el Titulo de Interno" otros={{ "data-toggle": "tooltip", "data-placement": "right", "title": "Este no aparecerá de manera pública, \npuede ser un nombre corto para que lo identifiques mas rápido" }} inputRef={onRef} section={1} />
                                                </div>

                                                <div className="col-lg-6 mb-3">
                                                    <Select options={initStatePropertyType} defaultOption={"Tipo de propiedad"} value={propertyType} name="propertyType" required={true} message="Se requiere el tipo de propiedad" onChange={onChange} inputRef={onRef} section={1} />
                                                </div>

                                                <div className="col-lg-6 mb-3">
                                                    <Input type="text" placeholder="Precio de la propiedad" name="price" value={price} onChange={onChange} required={true} message="Se requiere el Precio de la propiedad" inputRef={onRef} section={1} moneda={true} pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" />
                                                </div>

                                                <div className="col-lg-12 mb-3">
                                                    <Select options={subtypeProperty} defaultOption={"Subtipo de propiedad"} value={subTypeProperty} name="subTypeProperty" required={true} message="Se requiere el Subtipo de la propiedad" onChange={onChange} inputRef={onRef} section={1} />
                                                </div>
                                                {
                                                    (house && department) ?
                                                        <></>
                                                        :
                                                        <div className="col-lg-3 mb-3">
                                                            <Input type="number" placeholder="Recámaras" name="bedRoom" value={bedRoom} onChange={onChange} required={true} message="Se requiere las recámaras" inputRef={onRef} section={1} />
                                                        </div>
                                                }
                                                {
                                                    (house && department && commercial) ?
                                                        <></>
                                                        :
                                                        <div className="col-lg-3 mb-3">
                                                            <Input type="number" placeholder="Baños" name="bathRoom" value={bathRoom} onChange={onChange} message="Se requiere los Baños" inputRef={onRef} section={1} />
                                                        </div>
                                                }

                                                {
                                                    (house && department && commercial) ?
                                                        <></>
                                                        :
                                                        <div className="col-lg-3 mb-3">
                                                            <Input type="number" placeholder="1/2 Baños" name="halfBath" value={halfBath} onChange={onChange} message="Se requiere los 1/2 Baños" inputRef={onRef} section={1} />
                                                        </div>
                                                }

                                                {
                                                    (house && department && commercial) ?
                                                        <></>
                                                        :
                                                        <div className="col-lg-3 mb-3">
                                                            <Input type="number" placeholder="M2 Construcción" name="squareMetersBuild" value={squareMetersBuild} onChange={onChange} required={true} message="Se requiere los M2 Construcción" inputRef={onRef} section={1} />
                                                        </div>
                                                }

                                                {
                                                    (house && terrain && commercial) ?
                                                        <></>
                                                        :
                                                        <div className="col-lg-3 mb-3">
                                                            <Input type="number" placeholder="M2 Terreno" name="squareMetersLand" value={squareMetersLand} onChange={onChange} required={true} message="Se requiere los M2 Terreno" inputRef={onRef} section={1} />
                                                        </div>
                                                }

                                                {
                                                    (house && terrain && commercial) ?
                                                        <></>
                                                        :
                                                        <div className="col-lg-3 mb-3">
                                                            <Input type="number" placeholder="M2 Fondo" name="m2Deep" value={m2Deep} onChange={onChange} required={false} message="Se requiere los M2 Fondo" inputRef={onRef} section={1} />
                                                        </div>
                                                }
                                                {
                                                    (house && department && terrain && commercial) ?
                                                        <></>
                                                        :
                                                        <div className="col-lg-3 mb-3">
                                                            <Input type="number" placeholder="M2 Frente" name="m2Front" value={m2Front} onChange={onChange} required={false} message="Se requiere los M2 Frente" inputRef={onRef} section={1} />
                                                        </div>
                                                }
                                                {
                                                    (house && department && commercial) ?
                                                        <></>
                                                        :
                                                        <div className="col-lg-3 mb-3">
                                                            <Input type="number" placeholder="Estacionamiento" name="parking" value={parking} onChange={onChange} required={true} message="Se requiere el Estacionamiento" inputRef={onRef} section={1} />
                                                        </div>
                                                }

                                                {
                                                    (house && department && commercial) ?
                                                        <></>
                                                        :
                                                        <div className="col-lg-3 mb-3" >
                                                            <Input type="number" placeholder="No. de plantas" name="floor" value={floor} onChange={onChange} required={false} message="Se requiere el No. de plantas" inputRef={onRef} section={1} />
                                                        </div>
                                                }

                                                {
                                                    (house && department && terrain) ?
                                                        <></>
                                                        :
                                                        <div className="col-lg-3 mb-3" >
                                                            <Input type="number" placeholder="Mantenimiento" name="maintenance" value={maintenance} onChange={onChange} required={false} message="Se requiere el Mantenimiento" inputRef={onRef} section={1} />
                                                        </div>
                                                }
                                                {
                                                    (terrain) ?
                                                        <></>
                                                        :
                                                        <div className="col-lg-3 mb-3" >
                                                            <Select options={[{ value: 1, title: "Regular" }, { value: 2, title: "Irregular" }]} defaultOption={"Regular o Irregular"} name="regularIrregular" message="Se requiere si es regular o Irregular" onChange={onChange} inputRef={onRef} section={1} />
                                                        </div>
                                                }
                                                {
                                                    (house && department && commercial) ?
                                                        <></>
                                                        :
                                                        <div className="col-lg-3 mb-3" >
                                                            <div className="rld-single-select">
                                                                <Select options={[{ value: 1, title: "A estrenar" }, { value: 2, title: "Años" }]} value={antiquity} defaultOption={"Antigüedad"} name="antiquity" message="Se requiere la Antigüedad" onChange={onChange} inputRef={onRef} section={1} />
                                                            </div>
                                                        </div>
                                                }
                                                {
                                                    hideAntiquity ?
                                                        <></>
                                                        :
                                                        <div className="col-lg-3 mb-3" >
                                                            <Input type="number" placeholder="No. de años" name="numYear" value={numYear} onChange={onChange} required={false} message="Se requiere el No. de años" inputRef={onRef} section={1} />
                                                        </div>
                                                }
                                                <div className="col-lg-12 mb-3">
                                                    <Textarea placeholder="Descripción del la propiedad" name="description" value={description} required={true} maxLength={1500} message="Se requiere la description de la propiedad" onChange={onChange} inputRef={onRef} section={1} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    {/*------------------------ Servicios ------------------------*/}
                                    <div className="row pd-top-80">
                                        <div className="col-md-12">
                                            <div className="section-title mb-0">
                                                <h4>Servicios</h4>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2 mb-1">

                                            <div className="row mb-2">
                                                <div className="col-sm-3">
                                                    <Checkbox text="Seguridad privada" name="services" value={services} field="privateSecurity" onChangeArray={onChangeArray} assignValue={assignValue} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <Checkbox text="Gas Estacionario" name="services" value={services} field="stationaryGas" onChangeArray={onChangeArray} assignValue={assignValue} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <Checkbox text="Energía Solar" name="services" value={services} field="solarEnergy" onChangeArray={onChangeArray} assignValue={assignValue} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <Checkbox text="Agua de grifo" name="services" value={services} field="water" onChangeArray={onChangeArray} assignValue={assignValue} />
                                                </div>
                                            </div>
                                            <div className="collapse multi-collapse" id="content_servicios">
                                                <div className="row mb-2">
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Calefacción" name="services" value={services} field="calefaction" onChangeArray={onChangeArray} assignValue={assignValue} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Cable" name="services" value={services} field="cable" onChangeArray={onChangeArray} assignValue={assignValue} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Electricidad" name="services" value={services} field="electricity" onChangeArray={onChangeArray} assignValue={assignValue} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Pavimento" name="services" value={services} field="paviment" onChangeArray={onChangeArray} assignValue={assignValue} />
                                                    </div>
                                                </div>
                                                <div className="row ">
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Aire acondicionado" name="services" value={services} field="airConditioning" onChangeArray={onChangeArray} assignValue={assignValue} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Internet" name="services" value={services} field="internet" onChangeArray={onChangeArray} assignValue={assignValue} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Instalación Eléctrica subterránea" name="services" value={services} field="undergroundElectricity" onChangeArray={onChangeArray} assignValue={assignValue} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Gas Natural" name="services" value={services} field="naturalGas" onChangeArray={onChangeArray} assignValue={assignValue} />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-12 text-center d-flex justify-content-center">
                                            {buttonText ?
                                                <button className="btn btn-yellow btn-block " style={{ height: "30px", lineHeight: "30px", width: "30%" }} type="button" data-toggle="collapse" data-target="#content_servicios" aria-expanded="false" aria-controls="content_servicios" onClick={() => { setButtonText(false) }}><i className="fa fa-plus"></i> Ver más</button>
                                                :
                                                <button className="btn btn-yellow btn-block" style={{ height: "30px", lineHeight: "30px", width: "30%" }} type="button" data-toggle="collapse" data-target="#content_servicios" aria-expanded="false" aria-controls="content_servicios" onClick={() => { setButtonText(true) }}><i className="fa fa-minus"></i> Ver menos</button>
                                            }
                                        </div>
                                    </div>

                                    {/*------------------------ Espacios ------------------------*/}
                                    <div className="row pd-top-60">
                                        <div className="col-md-12">
                                            <div className="section-title">
                                                <h4>Espacios</h4>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2 mb-1">
                                            <div className="row mb-2">
                                                <div className="col-sm-2">
                                                    <Checkbox text="Jardín privado" name="spaces" value={spaces} field="privateGarden" onChangeArray={onChangeArray} />
                                                </div>
                                                <div className="col-sm-2">
                                                    <Checkbox text="Balcón" name="spaces" value={spaces} field="balcony" onChangeArray={onChangeArray} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <Checkbox text="Hidromasaje" name="spaces" value={spaces} field="hydromassage" onChangeArray={onChangeArray} />
                                                </div>
                                                <div className="col-sm-2">
                                                    <Checkbox text="Despensa" name="spaces" value={spaces} field="pantry" onChangeArray={onChangeArray} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <Checkbox text="Cuarto de servicio" name="spaces" value={spaces} field="serviceRoom" onChangeArray={onChangeArray} />
                                                </div>
                                            </div>
                                            <div className="collapse multi-collapse" id="content_espacios">
                                                <div className="row mb-2">
                                                    <div className="col-sm-2">
                                                        <Checkbox text="Estudio" name="spaces" value={spaces} field="study" onChangeArray={onChangeArray} />
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <Checkbox text="Parrilla" name="spaces" value={spaces} field="grill" onChangeArray={onChangeArray} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Ático" name="spaces" value={spaces} field="attic" onChangeArray={onChangeArray} />
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <Checkbox text="Oficina" name="spaces" value={spaces} field="office" onChangeArray={onChangeArray} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Baño de servicio" name="spaces" value={spaces} field="bathService" onChangeArray={onChangeArray} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-2">
                                                        <Checkbox text="Sala de cine" name="spaces" value={spaces} field="homeTheather" onChangeArray={onChangeArray} />
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <Checkbox text="Terraza" name="spaces" value={spaces} field="terrace" onChangeArray={onChangeArray} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Bodega de guardado" name="spaces" value={spaces} field="cellar" onChangeArray={onChangeArray} />
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <Checkbox text="Vestíbulo" name="spaces" value={spaces} field="lobby" onChangeArray={onChangeArray} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Galería" name="spaces" value={spaces} field="galery" onChangeArray={onChangeArray} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center d-flex justify-content-center">
                                            {buttonText2 ?
                                                <button className="btn btn-yellow btn-block" style={{ height: "30px", lineHeight: "30px", width: "30%" }} type="button" data-toggle="collapse" data-target="#content_espacios" aria-expanded="false" aria-controls="content_espacios" onClick={() => { setButtonText2(false) }}><i className="fa fa-plus"></i> Ver más</button>
                                                :
                                                <button className="btn btn-yellow btn-block" style={{ height: "30px", lineHeight: "30px", width: "30%" }} type="button" data-toggle="collapse" data-target="#content_espacios" aria-expanded="false" aria-controls="content_espacios" onClick={() => { setButtonText2(true) }}><i className="fa fa-minus"></i> Ver menos</button>
                                            }
                                        </div>
                                    </div>

                                    {/*------------------------ Amenidades ------------------------*/}
                                    <div className="row pd-top-60">
                                        <div className="col-md-12">
                                            <div className="section-title">
                                                <h4>Amenidades</h4>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row mb-2">
                                                <div className="col-sm-3">
                                                    <Checkbox text="Gimnasio" name="amenities" value={amenities} field="gym" onChangeArray={onChangeArray} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <Checkbox text="Cancha de tennis" name="amenities" value={amenities} field="tennisCourt" onChangeArray={onChangeArray} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <Checkbox text="Jacuzzi" name="amenities" value={amenities} field="jacuzzi" onChangeArray={onChangeArray} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <Checkbox text="Sauna" name="amenities" value={amenities} field="sauna" onChangeArray={onChangeArray} />
                                                </div>
                                            </div>
                                            <div className="collapse multi-collapse" id="content_amenidades">
                                                <div className="row mb-2">
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Piscina" name="amenities" value={amenities} field="pool" onChangeArray={onChangeArray} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Cancha de fútbol" name="amenities" value={amenities} field="soccerField" onChangeArray={onChangeArray} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Parrilla" name="amenities" value={amenities} field="grill" onChangeArray={onChangeArray} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Lavandería" name="amenities" value={amenities} field="laundry" onChangeArray={onChangeArray} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Área Infantil" name="amenities" value={amenities} field="childrenArea" onChangeArray={onChangeArray} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Área de fiestas" name="amenities" value={amenities} field="partyArea" onChangeArray={onChangeArray} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Sala de juegos" name="amenities" value={amenities} field="gameRoom" onChangeArray={onChangeArray} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Checkbox text="Co-working" name="amenities" value={amenities} field="coWorking" onChangeArray={onChangeArray} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center d-flex justify-content-center">
                                            {buttonText3 ?
                                                <button className="btn btn-yellow btn-block" style={{ height: "30px", lineHeight: "30px", width: "30%" }} type="button" data-toggle="collapse" data-target="#content_amenidades" aria-expanded="false" aria-controls="content_amenidades" onClick={() => { setButtonText3(false) }}><i className="fa fa-plus"></i> Ver más</button>
                                                :
                                                <button className="btn btn-yellow btn-block" style={{ height: "30px", lineHeight: "30px", width: "30%" }} type="button" data-toggle="collapse" data-target="#content_amenidades" aria-expanded="false" aria-controls="content_amenidades" onClick={() => { setButtonText3(true) }}><i className="fa fa-minus"></i> Ver menos</button>
                                            }
                                        </div>
                                    </div>

                                    {/*------------------------ Direccion ------------------------*/}
                                    <div className="row pd-top-80">
                                        <div className="col-md-12">
                                            <div className="section-title">
                                                <h4>Dirección</h4>
                                                <h5 style={{ backgroundColor:"#C9792C", color:"white", textAlign:"center", padding:"2"}}>En caso de no visualizar correctamente la dirección en el mapa, te invitamos ingresar a <strong><a href="https://maps.google.com" target="_blank">Google Maps </a></strong>para verificar la ubicación exacta</h5>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-7 mb-3">
                                                    <label><strong>Agregar calle sin número exterior</strong></label>
                                                    <Input type="text" placeholder="Calle" name="street" value={street} onChange={onChange} required={false} message="Se requiere la calle" inputRef={onRef} section={1} />
                                                </div>
                                                <div className="col-lg-2 mb-3">
                                                    <label><strong>Número Exterior</strong></label>
                                                    <Input type="text" placeholder="No Ext." name="externalNumber" value={externalNumber} required={false} onChange={onChange} message="Se requiere el No Ext." inputRef={onRef} section={1} />
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <label><strong>Código postal</strong></label>
                                                    <Input type="text" placeholder="Código postal" name="cp" value={cp} onChange={onChange} required={true} message="Se requiere el Código postal" inputRef={onRef} section={1} />
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <label><strong>Colonia</strong></label>
                                                    <Input type="text" placeholder="Colonia" name="colony" value={colony} onChange={onChange} required={false} message="Se requiere la Colonia" inputRef={onRef} section={1} />
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <label><strong>Ciudad</strong></label>
                                                    <Input type="text" placeholder="Ciudad" name="city" value={city} onChange={onChange} required={true} message="Se requiere la Ciudad" inputRef={onRef} section={1} />
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <label><strong>Estado</strong></label>
                                                    <Input type="text" placeholder="Estado" name="state" value={state} onChange={onChange} required={true} message="Se requiere el Estado" inputRef={onRef} section={1} />
                                                </div>
                                                <div className="col-sm-4 mb-3">
                                                    <button type="button" className="btn btn-yellow btn-block" style={{ height: "30px", lineHeight: "30px" }} onClick={() => { onChange("hideAddress", hideAddress ? false : true) }}>
                                                        <i className={!hideAddress ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                                                        {
                                                            !hideAddress ?
                                                                ` Ocultar dirección exacta`
                                                                :
                                                                ` Mostrar dirección exacta`
                                                        }
                                                    </button>
                                                    {/* <h5>
                                                        <label className="inputCheck">
                                                            Ocultar dirección exacta
                                                            <input
                                                                type="checkbox"
                                                                name="hideAddress"
                                                                onChange={
                                                                    e => {
                                                                        onChange(e.target.name, e.target.checked)
                                                                    }
                                                                }
                                                                checked={hideAddress == true ? true : false}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </h5> */}
                                                </div>
                                                <div className="col-sm-12" style={{ height: "500px" }}>
                                                    {!hideAddress ?
                                                        <GoogleMapReact
                                                            bootstrapURLKeys={{
                                                                // remove the key if you want to fork
                                                                key: "AIzaSyDjl4Ux0-5hYmLEVMdhB63ldRYCeIqcx7A",
                                                                language: "es",
                                                                region: "MX"
                                                            }}
                                                            center={!hideAddress ? { lat: coordsMaps.center.lat, lng: coordsMaps.center.lng } : { lat: initStateMapsCoords.center.lat, lng: initStateMapsCoords.center.lng }}
                                                            zoom={!hideAddress ? coordsMaps.center.zoom : initStateMapsCoords.center.zoom}
                                                            // GeocoderLocationType
                                                            GeocoderLocationType={() => console.log}
                                                        >
                                                            <MyMarker key={1} lat={coordsMaps.lat} lng={coordsMaps.lng} text={1} tooltip="Propiedad" />
                                                        </GoogleMapReact>
                                                        :
                                                        <div className="property-news-single-card"><iframe src={`https://maps.google.com/maps?width=100%&height=100%&hl=es&q=${encodeURI(`${colony}, ${city}, ${state}, México`)}&t=&z=15&ie=UTF8&iwloc=B&output=embed`} style={{ border: 0, height: "500px" }} allowFullScreen /></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className={tab2 ? "tab-pane fade show active" : "tab-pane fade"}>
                                    <div className="row pt-3">
                                        <div className="col-sm-12">
                                            <div className="section-title">
                                                <h4 className="text-center">Multimedia</h4>
                                            </div>
                                        </div>
                                        <div className="col-sm-2 d-flex align-items-center justify-content-center">
                                            <h5 className="text-center">Fotos</h5>
                                        </div>
                                        <div className="col-sm-8 ">
                                            <div className="inputFile">
                                                <div className="image-upload-wrap">
                                                    <input
                                                        className="file-upload-input"
                                                        type="file"
                                                        accept="image/*"
                                                        name="inputImage"
                                                        multiple
                                                        onChange={(e) => {
                                                            changeImage(e);
                                                        }}
                                                        ref={
                                                            (el) => {
                                                                onRef("inputImage", el);
                                                            }
                                                        }
                                                    />
                                                    <div className="my-4 text-center">
                                                        {(arrayFiles.length > 0) ?
                                                            <p>Tiene "{arrayFiles.length}" imagenes para agregar</p>
                                                            :
                                                            <p>Arrastra o agrega las imagenes de tu propiedad y da click en "Cargar"</p>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-sm-2 d-flex align-items-center">
                                            <button className="btn btn-yellow" type="button" onClick={() => { (arrayFiles.length > 0) && setImageSelectedPrevious([...ImageSelectedPrevious, ...arrayFiles]); assignValue("files", [...ImageSelectedPrevious, ...arrayFiles]); setArrayFiles([]) }}>Cargar</button>
                                        </div>
                                        {
                                            errorImage &&
                                            <div className="col-sm-12 text-danger text-center">
                                                Se requieren fotos de la propiedad
                                            </div>
                                        }
                                        <div className="my-3 col-sm-12 row">{srcImg}</div>

                                        <div className="my-3 col-sm-12"></div>
                                        <div className="col-sm-2 d-flex align-items-center justify-content-center">
                                            <h5 className="text-center">
                                                Videos <br /> (Opcional)
                                            </h5>
                                        </div>
                                        <div className="col-sm-8" >
                                            <input style={{ border: "1px dashed #FBB138", width: "100%", height: "100%", textAlign: "center" }} placeholder="URL del video de tú propiedad aquí" value={inputURL} onChange={(e) => setInputURL(e.target.value)} />
                                        </div>
                                        <div className="col-sm-2 d-flex align-items-center">
                                            <button className="btn btn-yellow" type="button" onClick={ValidationURL}>Cargar</button>
                                        </div>
                                        <div className="my-3 col-sm-12 row">
                                            {
                                                arrayInputURL.map(
                                                    (value, index) => <div className="col-md-4 mb-2" key={index}>
                                                        <div className="p-2" style={{ border: `1px dashed #FBB138` }}>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <iframe src={value} allowFullScreen />
                                                            </div>
                                                            <hr style={{ borderTop: `1px dashed #FBB138` }} />
                                                            <div className="row d-flex align-items-center justify-content-end">
                                                                <div className="col-md-3">
                                                                    <button type="button" className="btn btn-sm text-danger p-1" style={{ height: "30px", lineHeight: "100%", width: "30px", background: "#dc354526", borderRadius: "5px" }} onClick={e => handleDeleteURL(index)}><i className="fa fa-times"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="my-3 col-sm-12"></div>
                                        <div className="col-sm-2 d-flex align-items-center justify-content-center">
                                            <h5 className="text-center">
                                                Planos <br /> (Opcional)
                                            </h5>
                                        </div>
                                        <div className="col-sm-8 ">
                                            <div className="inputFile">
                                                <div className="image-upload-wrap">
                                                    <input
                                                        className="file-upload-input"
                                                        type="file"
                                                        accept="image/*"
                                                        multiple
                                                        onChange={(e) => {
                                                            changePlanos(e);
                                                        }}
                                                    />
                                                    <div className="my-4 text-center">
                                                        {(arrayFilesPlanos.length > 0) ?
                                                            <p>Tiene "{arrayFilesPlanos.length}" imagenes para agregar</p>
                                                            :
                                                            <p>Arrastra o agrega las imagenes de tu propiedad y da click en "Cargar"</p>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-sm-2 d-flex align-items-center">
                                            <button className="btn btn-yellow" type="button" onClick={() => { handleAddPlan() }}>Cargar</button>
                                        </div>
                                        <div className="my-3 col-sm-12 row">{plansDiv}</div>
                                        <div className="my-3 col-sm-12"></div>
                                        <div className="col-sm-2 d-flex align-items-center justify-content-center">
                                            <h5 className="text-center">
                                                Recorrido 360 <br /> (Opcional)
                                            </h5>
                                        </div>
                                        <div className="col-sm-8" >
                                            <input style={{ border: "1px dashed #FBB138", width: "100%", height: "100%", textAlign: "center" }} placeholder="Embedded code del video de tu propiedad aquí" value={inputRecorrido} onChange={(e) => setInputRecorrido(e.target.value)} />
                                        </div>
                                        <div className="col-sm-2 d-flex align-items-center">
                                            <button className="btn btn-yellow" type="button" onClick={ValidationRecorrido}>Cargar</button>
                                        </div>

                                        {
                                            errorURLRecorrido &&
                                            <div className="col-sm-12 text-danger text-center">
                                                La url ingresada no es correcta.
                                            </div>
                                        }
                                        <div className="my-3 col-sm-12 row">
                                            {
                                                arrayInputRecorrido.map(
                                                    (value, index) => <div className="col-md-12 mb-2" key={index}>
                                                        <div className="p-4" style={{ border: `1px dashed #FBB138` }}>
                                                            <div className="float-right" style={{ marginTop: '-25px', marginRight: '-20px' }}>
                                                                <button type="button" className="btn btn-sm text-danger p-1" style={{ height: "20px", lineHeight: "100%", width: "20px", background: "#dc354526", borderRadius: "50%" }} onClick={e => handleDeleteRecorrido(index)}><i className="fa fa-times" style={{ paddingTop: '-10px', marginRight: '-2px' }}></i></button>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <iframe style={{ width: "100%", height: "500px", border: "none" }} border="0" allowFullScreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" src={value} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className={tab3 ? "tab-pane fade show active" : "tab-pane fade"}>

                                    <h4 className="text-center">Vista Previa</h4>
                                    {
                                        view && !loading ?
                                            <div className="py-2" style={{ border: "2px dashed #FBB138", height: "500px", overflowY: "scroll", overflowX: "hidden" }}>
                                                <PropertyDetailsSection propiedad={previewProperty} />
                                            </div>
                                            :
                                            <div className="py-2 loader-container text-center" style={{ border: "2px dashed #FBB138", height: "500px", overflowY: "scroll", overflowX: "hidden" }}>
                                                <div>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <div className="loader"></div>
                                                    </div>
                                                    <br />
                                                    <b style={{ color: "#fbb138" }}>Cargando información para la vista previa</b>
                                                </div>

                                            </div>
                                    }
                                </div>


                            </div>
                            <br /><hr />
                            <div className="d-flex justify-content-between pt-3">
                                <div className="mr-2">
                                    <button type="button" className="btn" onClick={actionPrevious} style={!btnPrevious ? { "display": "none" } : {}}>Anterior</button>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-yellow" style={!btnSubmit ? { "display": "none" } : {}}
                                        onClick={
                                            () => {
                                                handleLoading();
                                            }
                                        }
                                    >
                                        Actualizar Propiedad
                                    </button>
                                    <button type="button" className="btn btn-orange" style={!btnNext ? { "display": "none" } : {}} onClick={() => actionNext()}>Siguiente</button>
                                    <button type="button" className="btn btn-yellow rounded-0 px-5 mr-md-3" style={showConfirmacion || !tab3 ? { "display": "none" } : {}}
                                        onClick={
                                            () => {
                                                setShowConfirmacion(true);
                                                setWizard({
                                                    ...wizard,
                                                    ["btnSubmit"]: true,
                                                });
                                                setFormValueLR(initStateRegistro);
                                                setErrorTermsAndConditions(false)
                                            }
                                        }
                                    >Confirmar</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <ToastContainer style={{ width: "auto", marginTop: "180px" }} />
            </div>

            <Footer />
        </>
    );
}

export default EditProperty

