import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import { cribbeeAPI } from '../api/cribbeeAPI';
const terminos = () => {
    return (
        <>
            <Navbar />
            <PageHeader headertitle="Términos y condiciones" subheader="Térmimos y condiciones" />
            <div className="properties-area pd-top-92 pd-bottom-92">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 row">
                            <div className="col-md-12 text-center">
                                <h3>TÉRMINOS Y CONDICIONES</h3>
                            </div>
                            <div className="col-md-12 text-justify">
                                <p>Esta sección establece los términos y condiciones (en adelante, las “Condiciones Generales”) para el uso de los contenidos y servicios (en adelante, los “Servicios”) del sitio web.</p>
                                <p>El acceso a este sistema conlleva la aceptación del usuario a todas las estipulaciones de este documento y las condiciones aplicables. Este documento constituye un acuerdo por parte del cliente, en adelante denominado el <strong>USUARIO </strong>para el acceso al sistema de Servicios Virtuales de <strong>CASYTER</strong>. </p>
                                <p>El Usuario declara y acepta que el uso del Sitio Web sus Servicios y los contenidos se efectúan bajo su única y exclusiva responsabilidad.</p>
                                <ol>

                                    <li>Advertencia - Los términos y condiciones aquí contenidos serán de obligatorio cumplimiento y aceptación por parte de quienes accedan a la información que <strong>CASYTER</strong> tiene disponibles. Por favor lea estos términos y condiciones cuidadosamente. Al acceder a este sistema de operación y a cualquiera de sus páginas, el <strong>USUARIO</strong> acepta los términos y condiciones que aparecen a continuación, así como también aquellas obligaciones que surgen de la naturaleza de la herramienta tecnológica que se está utilizando. Si no está de acuerdo con los términos y condiciones a continuación presentados, NO debe continuar y debe SALIR del sistema. La utilización por el usuario del Sitio Web se entenderá como aceptación plena y sin reservas de las Condiciones Generales aquí establecidas.</li>

                                    <li>
                                        Propiedad de la información - <strong>CASYTER</strong> es el titular de los derechos de autor y propiedad intelectual sobre los signos distintivos en general y marcas comerciales, contenidos en estas páginas. Los derechos de autor sobre las páginas, las imágenes y en general todo lo que aparece en pantalla, así como sobre la información y el material contenido en las mismas, es propiedad de <strong>CASYTER</strong> salvo indicación en contrario.
                                    </li>
                                    <li>
                                        Consultas habilitadas - El usuario mediante la utilización de estas páginas podrá realizar consultas de aquellos servicios que se encuentren posibilitados tecnológicamente para ser accedidos por este medio.
                                        <p>El Usuario se obliga a abstenerse de utilizar el Sitio Web con fines o efectos ilícitos, lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar el Sitio Web o impedir la normal utilización del Sitio Web por parte de los Usuarios.</p>
                                    </li>
                                    <li>
                                        Alcance de la información - <strong>CASYTER</strong> ha dispuesto sus mejores esfuerzos para que la información y los materiales aquí contenidos, incluyendo los textos, gráficos, enlaces y demás ítems, sean idóneos para la correcta operación por parte del USUARIO. Sin embargo, <strong>CASYTER</strong> no asumirá pagos por concepto de daños directos, indirectos, accidentales, consecuenciales, punitivos, incluyendo pérdidas y lucro cesante que subjetivamente se imputen a (i) la información contenida en la Página Web de CASYTER, (ii) cualquier información transmitida vía la Página Web de CASYTER o (iii) el funcionamiento o no de cualquier Página Web de CASYTER. <p>CASYTER no asumirá monto alguno por daños o pérdidas de equipo o software que se imputen subjetivamente a virus, defectos o mal funcionamiento relativos a accesos o utilización de la Página Web de CASYTER.</p>
                                    </li>
                                    <li>
                                        Transmisión de datos - <strong>CASYTER</strong> ha dispuesto de una organización tecnológica en procura de que el usuario pueda efectuar la transmisión de datos a través de Internet, o utilizando otro tipo de comunicación en red, o cualquier otra vía electrónica. Antes de suministrar cualquier información el <strong>USUARIO</strong> deberá autorizar a <strong>CASYTER</strong> para almacenar y tratar sus datos. Dichos datos serán tratados con altas medidas de confidencialidad.
                                    </li>
                                    <li>
                                        Uso permitido del Sitio - Reglas generales: Los Usuarios tienen prohibido utilizar el Sitio Web para transmitir, distribuir, almacenar o destruir material (i) violando la normativa vigente, (ii) de forma que se infrinjan derechos de terceros o se viole la confidencialidad, honor, privacidad, imagen u otros derechos personales de otras personas.
                                        Reglas de Seguridad del Sitio Web: Los Usuarios tienen prohibido violar o intentar violar la seguridad del Sitio Web, incluyendo pero no limitándose a: (i) el acceso a datos que no estén destinados a tal usuario o entrar en un servidor o cuenta cuyo acceso no está autorizado al Usuario, (ii) evaluar o probar la vulnerabilidad de un sistema o red, o violar las medidas de seguridad o identificación sin la adecuada autorización, (iii) intentar impedir el Servicio a cualquier Usuario, anfitrión o red, incluyendo, pero sin limitación, mediante el envío de virus al Sitio Web, o mediante saturación o ataques de denegación de Servicio, (iv) enviar correos no pedidos, incluyendo promociones y/o publicidad de productos o servicios, o (v) falsificar cualquier cabecera de paquete TCP/IP o cualquier parte de la información de la cabecera de cualquier correo electrónico o en mensajes de foros de debate.
                                        Las violaciones de la seguridad del sistema o de la red constituyen delitos penales y pueden derivar en responsabilidades civiles. CASYTER investigará los casos de violaciones a la seguridad del sistema, pudiendo dirigirse a la autoridad judicial o administrativa competente a los efectos de perseguir a los Usuarios involucrados en tales violaciones.
                                    </li>
                                    <li>
                                        Usos  prohibidos - El Sitio Web sólo podrá ser utilizado con fines lícitos, para acceder a información referida a los Servicios disponibles a través del mismo. CASYTER prohíbe específicamente cualquier utilización del Sitio Web para:

                                        <ol>
                                            <li>
                                                Usar cualquier mecanismo para impedir o intentar impedir el adecuado funcionamiento de este Sitio Web o cualquier actividad que se esté realizando en este Sitio Web.
                                            </li>
                                            <li>
                                                El uso o intento de uso de cualquier máquina, software, herramienta, agente u otro mecanismo para navegar o buscar en este Sitio Web que sean distintos a las herramientas de búsqueda provistos por CASYTER en este Sitio Web.
                                            </li>
                                            <li>
                                                Intentar descifrar, descompilar u obtener el código fuente de cualquier programa de software de este Sitio Web.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Terminación del acceso - <strong>CASYTER</strong> se reserva el derecho de cancelar unilateralmente el acceso a este sistema de operación y transacción en cualquier tiempo, sin que para ello tenga que aducir alguna justa causa. La cancelación del acceso no implica la terminación de las relaciones contractuales que tenga el USUARIO con <strong>CASYTER</strong>.
                                    </li>
                                    <li>
                                        Menores de edad - Queda prohibida la utilización del Sitio Web y/o de los Servicios ofrecidos a través del mismo por personas que carezcan de capacidad legal para contratar o menores de edad. En caso que ello ocurra, los menores de edad o incapaces deben obtener previamente permiso de sus padres, tutores o representantes legales, quienes serán considerados responsables de todos los actos realizados por las personas a su cargo.
                                        <p>
                                            Al acceder al Sitio Web y utilizar los Servicios el Usuario confirma que es mayor de edad.
                                        </p>
                                    </li>
                                    <li>
                                        Modificaciones y revisiones al presente acuerdo - <strong>CASYTER</strong> se reserva el derecho de revisar el presente acuerdo en cualquier momento y de modificarlo sin previo aviso.
                                    </li>
                                    <li>
                                        Aceptación - El acceso a este conllevan la aceptación del usuario a todas las estipulaciones de este acuerdo.
                                    </li>
                                    <li>
                                        Política de manejo de la privacidad del usuario-  <strong>CASYTER</strong>  garantiza que la información personal que usted envía cuenta con la seguridad necesaria. Puede revisar más a detalle en nuestra <strong>POLÍTICA DE PRIVACIDAD</strong> los derechos de cambiar o de modificar estos términos sin previo aviso.
                                    </li>
                                    <li>
                                        Datos personales del usuario, registro en el sitio web
                                        <ol>
                                            <li>Al momento del registro en el Sitio Web, se le solicitara que aporte a <strong>CASYTER</strong> cierta información que incluirá ciertos datos personales, entre ellos, nombre y apellido, dirección, teléfono y una dirección válida de correo electrónico, (de corresponder), entre otros.</li>

                                            <li><strong>CASYTER</strong> se reserva el derecho de ofrecerle servicios y/o productos de terceros basados en las preferencias que Usted indicó al momento de registrarse o en cualquier momento posterior; dichas ofertas pueden ser efectuadas por <strong>CASYTER</strong> directamente por terceros para lo cual <strong>CASYTER</strong> podrá compartir con dichos terceros la información proporcionada por el Usuario de conformidad con el Aviso de Privacidad de <strong>CASYTER</strong>.</li>

                                            <li>Se recomienda consultar el Aviso de Privacidad del Sitio Web para conocer los detalles respecto del tratamiento de sus Datos Personales.</li>

                                            <li>El Usuario será responsable de todos los usos de su cuenta, tanto si están autorizados o no por Usted. Se deberá notificar inmediatamente a <strong>CASYTER</strong> sobre cualquier uso sin autorización de su cuenta o contraseña.</li>

                                            <li>Los Usuarios registrados y/o que utilicen los Servicios de <strong>CASYTER</strong> garantizan la veracidad, exactitud, vigencia y autenticidad de la información facilitada, y se comprometen a mantenerlos debidamente actualizados, informando a <strong>CASYTER</strong> sobre cualquier modificación a través de la sección “Contacto” disponible en la página principal del Sitio Web.</li>

                                            <li>El Usuario autoriza a que la información personal suministrada sea compartida con los clientes de <strong>CASYTER</strong> y terceros aliados y colaboradores comerciales referidos anteriormente.</li>
                                        </ol>

                                    </li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default terminos

