import React, { useRef } from 'react'

const Input = (props) => {
    const { type, placeholder, name, required, message, inputRef, section, onChange, value, moneda, pattern } = props;
    let { otros } = props;
    const field = useRef(false)
    if (value != '') {
        otros = {
            ...otros,
            "defaultValue": value
        }
    }
    if (pattern !== undefined) {
        if (pattern !== "") {
            otros = {
                ...otros,
                "pattern": pattern
            }
        }
    }


    function formatNumber(n) {
        // format number 1000000 to 1,234,567
        return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    function formatCurrency(input, blur, type) {
        // appends $ to value, validates decimal side
        // and puts cursor back in right position.

        // get input value
        var input_val = input.value;


        // don't validate empty input
        if (input_val === "") { return; }

        // original length
        var original_len = input_val.length;

        // initial caret position 
        var caret_pos = input.selectionStart;

        // check for decimal
        if (input_val.indexOf(".") >= 0) {

            // get position of first decimal
            // this prevents multiple decimals from
            // being entered
            var decimal_pos = input_val.indexOf(".");

            // split number by decimal point
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);

            // add commas to left side of number
            left_side = formatNumber(left_side);

            // validate right side
            right_side = formatNumber(right_side);

            // On blur make sure 2 numbers after decimal
            if (blur === "blur") {
                right_side += "00";
            }

            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);

            // join number by .
            input_val = "$" + left_side + "." + right_side;

        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = formatNumber(input_val);
            input_val = "$" + input_val;

            // final formatting
            if (blur === "blur") {
                input_val += ".00";
            }
        }
        // send updated string to input
        // input.value = "$" +input_val;
        input.value = input_val;

        // put caret back in the right position
        var updated_len = input_val.length;
        caret_pos = updated_len - original_len + caret_pos;
        input.setSelectionRange(caret_pos, caret_pos);
        const valueCurrent = parseFloat(input_val.replace(/,/g, "").replace("$", ""));
        const typeMoneda = input_val;

        if (valueCurrent >= 0) {
            return type == "moneda" ? typeMoneda : valueCurrent;
        } else {
            return 0;
        }
    }

    return (
        <>

            <div className="rld-single-input">
                <input type={type} placeholder={placeholder} name={name} required={required} {...(otros)}
                    onChange={
                        (e) => {
                            if(required === true) {
                                field.current && e.target.value !== '' ? field.current.classList.remove("is-invalid") : field.current.classList.add("is-invalid");
                            }
                        }
                    }
                    onKeyUp={
                        e => {
                            if (moneda !== undefined) {
                                if (moneda === true) {
                                    onChange(name, formatCurrency(e.target, "", "moneda"));
                                }
                            }
                            if (moneda === undefined) {
                                onChange(name, e.target.value);
                            }
                        }
                    }
                    ref={
                        (el) => {
                            inputRef(name, el);
                            field.current = el;
                        }
                    }
                    data-section={section}
                />
                <div className="invalid-feedback">{message}</div>
            </div>
        </>
    )


}

export default Input;
