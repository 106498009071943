import React from 'react'
import { Route } from 'react-router'

import inicio from './inicio';
import propiedades from './propiedades';
import paquetes from './paquetes';
import MiCuenta from './mi-cuenta';
import Blog from './blog';
import AddNew from './publicar-propiedad';
import DetallePropiedad from './detalle-propiedad';
import SignIn from './iniciar-sesion';
import Registraion from './registration';
import { Redirect } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import aviso from './aviso-privacidad'
import terminos from './terminos-condiciones';
import cribbee from './cribbee';
import editarPropiedad from './editar-propiedad';
import faq from './faq';
import contacto from './contacto';



export const PrivateRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <>
            {/* Ruta de inicio */}
            <Route path="/" exact component={inicio} />

            {/* Ruta de Propiedades */}
            <Route path="/propiedades" component={propiedades} />

            {/* Ruta de Paquetes */}
            <Route path="/paquetes" component={paquetes} />

            {/* Ruta de Blog */}
            <Route path="/blog" component={Blog} />

            {/* Ruta de Detalle Propiedad */}
            <Route path="/publicar-propiedad" component={AddNew} />

            {/* Ruta de Publicar Propiedad */}
            <Route path="/detalle-propiedad/:id" component={DetallePropiedad} />

            {/* Ruta de Mi cuenta */}
            <Route path="/mi-cuenta" component={MiCuenta} />

            {/* Ruta de terminos */}
            <Route path="/terminos-condiciones" component={terminos} />

            {/* Ruta de aviso de privacidad */}
            <Route path="/aviso-privacidad" component={aviso} />

            {/* Ruta de aviso de cribbee */}
            <Route path="/cribbee" component={cribbee} />

            {/* Editar Propiedad */}
            <Route path="/editar-propiedad/:id" component={editarPropiedad} />

            {/* Ruta de Registrar */}
            <Route path="/registration" component={Registraion} />

            {/* Ruta de Iniciar Sesión */}
            <Route path="/iniciar-sesion" component={SignIn} />

            {/* Ruta de Contacto */}
            <Route path="/contacto" component={contacto} />

            {/* Ruta de faq */}
            <Route path="/faq" component={faq} />


        </>
    )
}
