import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { cribbeeAPI } from '../api/cribbeeAPI';
const RecoverPassword = (props) => {
	const { match: { params: { token } } } = props;

	const handleLoadingEmail = (e) => {
		e.preventDefault();
		const resolveWithSomeData = new Promise((resolve, reject) => setTimeout(async () => {
			let { status, info } = await postSendEmail();
			if (status) {
				resolve(info)
			} else {
				reject(info)
			}
		}, 1000));
		toast.promise(
			resolveWithSomeData,
			{
				pending: {
					render() {
						return "Enviando Correo"
					},
					icon: true,
				},
				success: {
					render({ data }) {
						setTimeout(() =>window.location.href = '/', 1000)
						return data;
					},
					icon: true,
				},
				error: {
					render({ data }) {
						return data
					}
				}
			}
		)
	}
	const postSendEmail = async () => {
		try {
			const formData = new FormData(document.querySelector(`form[name="form-send-email"]`));
			if(formData.get("ConfirmPassword") != formData.get("password")) throw("Valide que las contraseñas sean iguales");
			const { status, message, data } = await cribbeeAPI.put(`/recovery-password`, { password: formData.get("password"),  u: token });
			return { status: true, info: "Se ha cambiado su contraseña" }
		} catch (error) {
			if(error == "Valide que las contraseñas sean iguales"){
				return { status: false, info: "Valide que las contraseñas sean iguales"}
			}else{
				return { status: false, info: error.response.data.message }
			}
		}
	}
	return (
		<>
			<Navbar />
			<PageHeader headertitle="Nueva Contraseña" />
			<div className="contact-area pd-top-100 pd-bottom-65">
				<div className="container">
					<div className="row d-flex justify-content-center">
						<div className="col-lg-5 text-center">
							<div className="contact-form-wrap contact-form-bg">
								<form onSubmit={handleLoadingEmail} name="form-send-email">
									<h3>Nueva Contraseña</h3>
									<br />
									<div className="rld-single-input">
										<input type="password" name="password" placeholder="Nueva Contraseña" required />
									</div>
									<div className="rld-single-input">
										<input type="password" name="ConfirmPassword" placeholder="Confirmar Contraseña" required />
									</div>
									<br />
									<div className="btn-wrap text-center">
										<button className="btn btn-yellow mt-0 mr-2" type="submit">Actualizar Contraseña</button>
									</div>

								</form>

							</div>


						</div>
					</div>
				</div>
			</div>
			<ToastContainer style={{ width: "auto", marginTop: "180px" }} />
			<Footer />
		</>
	);
}

export default RecoverPassword

