import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

class Ads extends Component {


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.ads

        const inlineStyle = {
            backgroundImage: 'url(/assets/img/bg/1.jpg)'
        }
//******ESTE ES EL CÓDIGO QUE SE UTILIZA PARA DEJARLO COMO ESTABA */
// <h3 className="title">{data.title} <br />{data.title2}</h3>
//<Link className="btn btn-white" to={ data.url }>{ data.btntxt }</Link>
    return <div className="call-to-action-area pd-top-70">
              <div className="container">
                <div className="call-to-action" style={ inlineStyle }>
                  <div className="cta-content">
                    <h3 className="title"><br /></h3>
                    <a href="https://exni.mx/"  target="_blank" className="btn btn-white">{ data.btntxt }</a>
                  </div>
                </div>
              </div>
          </div>


        }
}

export default Ads