import { BrowserRouter as Router, Switch, HashRouter, Redirect } from "react-router-dom";
import React from 'react'
import { useContext } from "react";
import { AuthContext } from "./auth/AuthContext";

import { PrivateRoutes } from "./components/PrivateRoutes";
import { PublicRoutes } from "./components/PublicRoutes";

const AppRouter = (props) => {

    const { logged } = useContext(AuthContext);
    return (
        <Router>
            <HashRouter>
                <>
                    <Switch>
                        {
                            logged ? <PrivateRoutes /> : <PublicRoutes />
                        }
                    </Switch>
                </>
            </HashRouter>
        </Router>
    )
}

export default AppRouter;