import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';

import Footer from './global-components/footer';

const contacto = () => {
	return <div>
		<Navbar />
		<PageHeader headertitle="Contacto" />
		<div className="contact-area pd-top-100 pd-bottom-65">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="contact-page-map">
							<iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.118578433784!2d-101.68597109999999!3d21.147678799999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842bbf4716c9e4c1%3A0x2d4a8c26c01b01!2sBlvd.%20Campestre%20116%2C%20Jardines%20del%20Moral%2C%2037160%20Le%C3%B3n%2C%20Gto.%2C%20Mexico!5e0!3m2!1sen!2sus!4v1661327654202!5m2!1sen!2sus" style={{ border: 0 }} allowFullScreen />
						</div>
					</div>
					{/* <div className="col-lg-4">
		        <form className="contact-form-wrap contact-form-bg">
		          <h4>Contáctanos</h4>
		          <div className="rld-single-input">
		            <input type="text" placeholder="Nombre" />
		          </div>
		          <div className="rld-single-input">
		            <input type="text" placeholder="Teléfonos" />
		          </div>
		          <div className="rld-single-input">
		            <input type="text" placeholder="Email" />
		          </div>
		          <div className="rld-single-input">
		            <textarea rows={10} placeholder="Message" defaultValue={""} />
		          </div>
		          <div className="btn-wrap text-center">
		            <button className="btn btn-yellow">Enviar</button>
		          </div>
		        </form>
		      </div> */}
				</div>
				<div className="row pd-top-92">
					{/* <div className="col-xl-4 col-sm-6">
		        <div className="single-contact-info">
		          <p><i className="fa fa-phone" />Llámanos:</p>
		          <h5>(477) 111 222 333</h5>
		        </div>
		      </div> */}
					<div className="col-xl-6 col-sm-6 d-flex justify-content-center" >
						<div className="single-contact-info">
							<p><i className="fa fa-envelope" />Escríbenos</p>
							<h5>hola@casyter.com</h5>
						</div>
					</div>
					<div className="col-xl-6 col-sm-6 d-flex justify-content-center">
						<div className="single-contact-info">
							<p><i className="fa fa-phone" />Dirección</p>
							<h5>
								Blvd. Campestre 116, Jardines del Moral, <br></br>
								37160 León, Gto., Mexico
							</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
}

export default contacto
