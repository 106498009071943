import React from "react";
import { AuthProvider } from "./auth/AuthContext";
import AppRouter from "./router";

import ReactGA from 'react-ga';

const App = () => {

    ReactGA.initialize('253881641');
    ReactGA.pageview(window.location.pathname + window.location.search);
    
    const AppState = ({ children }) => {

        return (
            <AuthProvider>
                {children}
            </AuthProvider>
        )
    }

    return (
        <>
            <AppState>
                <AppRouter />
            </AppState>
        </>

    )
}

export default App;