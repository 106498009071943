
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyAw93YDWAyhJqjH7XNsSkwTd1P9Mx3VF_4",
  authDomain: "casyter-da36f.firebaseapp.com",
  projectId: "casyter-da36f",
  storageBucket: "casyter-da36f.appspot.com",
  messagingSenderId: "976356348448",
  appId: "1:976356348448:web:dc379e538fddb3df8aa245",
  measurementId: "G-MJHGHJJXD7"
};

const app = initializeApp(firebaseConfig);

export default app;
