import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import footerdata from '../../data/footerdata.json';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { cribbeeAPI } from '../../api/cribbeeAPI';
import 'bootstrap-icons/font/bootstrap-icons.css';

const onSubmitEmail = (event) => {
  event.preventDefault();
  const resolveWithSomeData = new Promise((resolve, reject) => setTimeout(async () => {
    let { status, info } = await postEnviarCorreo();
    if (status) {
      resolve(info)
    } else {
      reject(info)
    }
  }, 1000));
  toast.promise(
    resolveWithSomeData,
    {
      pending: {
        render() {
          return "Enviando información"
        },
        icon: true,
      },
      success: {
        render({ data }) {
          return data
        },
        icon: true,
      },
      error: {
        render({ data }) {
          return data.message
        }
      }
    }
  )


}

const currentDateBD = (newDate = "") => {
  let date = "";
  if (newDate == "") {
    date = new Date().toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }).replace(/ /g, "")
  } else {
    date = new Date(newDate).toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }).replace(/ /g, "")
  }
  const arrayDate = date.split(",");
  const fecha = arrayDate[0].split("/");
  const time = arrayDate[1];
  return `${fecha[2]}-${fecha[1].length == 1 ? `0${fecha[1]}` : fecha[1]}-${fecha[0].length == 1 ? `0${fecha[0]}` : fecha[0]}T${time}`;
}

const postEnviarCorreo = async () => {
  try {

    const formData = new FormData(document.querySelector(`form[name="form-footer-contacto"]`));
    if (formData.get("nombre") == '') throw new Error("Valide que los campos no estén vacíos");
    const { status, message, data } = await cribbeeAPI.get(`/email-suscripcion?nombre=${formData.get("nombre")}&correo=${formData.get("correo")}&currentDate=${currentDateBD()}`);

    if (!status) throw (message);
    document.querySelector(`form[name="form-footer-contacto"]`).reset();
    return { status: true, info: "¡Gracias por tu suscripción a la colmena digital!" }
  } catch (error) {
    return { status: false, info: error }
  }
}

class Footer_v1 extends Component {

  componentDidMount() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);
  }

  // email-suscripcion
  render() {

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imgattr = "Footer logo"
    const inlineStyle = {
      backgroundImage: 'url(' + publicUrl + footerdata.footerbg + ')'
    }

    return (
      <footer className="footer-area">
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-sm-4">
                {/* <a className="footer-logo" href="#"><img src={ publicUrl+footerdata.footerlogo } alt={ imgattr } /></a> */}
              </div>
              <div className="col-sm-8">
                <div className="footer-social text-sm-right">
                  <span>SÍGUENOS</span>
                  <ul className="social-icon">

                    <li>
                      <a href="https://www.facebook.com/Cribbee-107491544900238" target="_blank"><i className="fa fa-facebook" /></a>
                    </li>
                    <li>
                      <a href="https://twitter.com/Cribbeeapp" target="_blank"><i className="fa fa-twitter" /></a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/cribbeeapp/" target="_blank"><i className="fa fa-instagram" /></a>
                    </li>
                    <li>
                      <a href="https://www.tiktok.com/@cribbeeapp" target="_blank"><i className="bi bi-tiktok" /></a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/cribbee/" target="_blank"><i className="fa fa-linkedin" /></a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UCqgCj_tIKciyqEb0rX3T8vA" target="_blank"><i className="fa fa-youtube" /></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row">

              <div className="col-lg-4 col-sm-6">
                <div className="widget widget_nav_menu">
                  <h4 className="widget-title">{footerdata.Homepress.title}</h4>
                  <ul>
                    <li className="readeal-top"><Link to="/cribbee">¿Quiénes somos?</Link></li>
                    <li className="readeal-top"><Link to="/faq">Preguntas frecuentes</Link></li>
                    <li className="readeal-top"><a href="https://blog.casyter.com" target="_new_blog">Blog posts</a></li>
                    <li className="readeal-top"><Link to="/contacto">Contacto</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="widget widget_nav_menu">
                  <h4 className="widget-title">{footerdata.quicklink.title}</h4>
                  <ul>
                    {footerdata.quicklink.links.map((item, i) =>
                      <li className="readeal-top" key={i}><Link to={item.url} >{item.title}</Link></li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <form className="widget widget-subscribe" onSubmit={onSubmitEmail} name="form-footer-contacto">
                  <div className="rld-single-input">
                    <input type="text" name="nombre" placeholder="Nombre" required />
                  </div>
                  <div className="rld-single-input">
                    <input type="email" name="correo" placeholder="Email" required />
                  </div>
                  <button type="submit" className="btn btn-yellow w-100">Suscribir</button>
                </form>
              </div>
            </div>
          </div>
          <div className="copy-right text-center" dangerouslySetInnerHTML={{ __html: footerdata.copyrighttext }}></div>
        </div>
        <ToastContainer style={{ width: "auto", marginTop: "180px" }} />
      </footer>

    )
  }
}


export default Footer_v1