import React, { useEffect, useState, useContext, useRef } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import { cribbeeAPI } from '../api/cribbeeAPI';
import { AuthContext } from '../auth/AuthContext';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const MiCuenta = (props) => {
    let params = new URLSearchParams(props.location.search);
    const section = params.get('s');

    const arrayPrefijos = [
        { codigo: "+93", pais: "Afganistán +93" },
        { codigo: "+213", pais: "Albania +213" },
        { codigo: "+355", pais: "Albania +355" },
        { codigo: "+49", pais: "Alemania +49" },
        { codigo: "+376", pais: "Andorra +376" },
        { codigo: "+1264", pais: "Angola +1264 (ANG)" },
        { codigo: "+244", pais: "Angola +244" },
        { codigo: "+1268", pais: "Antigua y Barbuda +1268 (ANT)" },
        { codigo: "+599", pais: "Antillas Neerlandesas +599" },
        { codigo: "+966", pais: "Arabia Saudita +966" },
        { codigo: "+54", pais: "Argentina +54" },
        { codigo: "+374", pais: "Armenia +374" },
        { codigo: "+297", pais: "Aruba +297" },
        { codigo: "+61", pais: "Australia +61" },
        { codigo: "+43", pais: "Austria +43" },
        { codigo: "+994", pais: "Azerbaiyán +994" },
        { codigo: "+1242", pais: "Bahamas +1242 (BAH)" },
        { codigo: "+880", pais: "Bangladesh +880" },
        { codigo: "+1246", pais: "Barbados +1246" },
        { codigo: "+973", pais: "Baréin +973" },
        { codigo: "+32", pais: "Bélgica +32" },
        { codigo: "+501", pais: "Belice +501" },
        { codigo: "+229", pais: "Benín +229" },
        { codigo: "+375", pais: "Bielorrusia +375" },
        { codigo: "+95", pais: "Birmania +95" },
        { codigo: "+591", pais: "Bolivia +591" },
        { codigo: "+387", pais: "Bosnia y Herzegovina +387" },
        { codigo: "+267", pais: "Botsuana +267" },
        { codigo: "+55", pais: "Brasil +55" },
        { codigo: "+673", pais: "Brunéi +673" },
        { codigo: "+359", pais: "Bulgaria +359" },
        { codigo: "+226", pais: "Burkina Faso +226" },
        { codigo: "+257", pais: "Burundi +257" },
        { codigo: "+975", pais: "Bután +975" },
        { codigo: "+238", pais: "Cabo Verde +238" },
        { codigo: "+855", pais: "Camboya +855" },
        { codigo: "+237", pais: "Camerún +237" },
        { codigo: "+1", pais: "Canadá +1" },
        { codigo: "+235", pais: "Chad +235" },
        { codigo: "+56", pais: "Chile +56" },
        { codigo: "+86", pais: "China +86" },
        { codigo: "+357", pais: "Chipre +357" },
        { codigo: "+57", pais: "Colombia +57" },
        { codigo: "+269", pais: "Comoras +269" },
        { codigo: "+225", pais: "Costa de Marfil +225" },
        { codigo: "+506", pais: "Costa Rica +506" },
        { codigo: "+53", pais: "Cuba +53" },
        { codigo: "+45", pais: "Dinamarca +45" },
        { codigo: "+1767", pais: "Dominica +1767 (ROS)" },
        { codigo: "+593", pais: "Ecuador +593" },
        { codigo: "+20", pais: "Egipto +20" },
        { codigo: "+503", pais: "El Salvador +503" },
        { codigo: "+971", pais: "Emiratos Árabes Unidos +971" },
        { codigo: "+421", pais: "Eslovaquia +421" },
        { codigo: "+386", pais: "Eslovenia +386" },
        { codigo: "+34", pais: "España +34" },
        { codigo: "+291", pais: "Estado de Eritrea +291" },
        { codigo: "+691", pais: "Estados Federados de Micronesia +691" },
        { codigo: "+1", pais: "Estados Unidos de América +1" },
        { codigo: "+372", pais: "Estonia +372" },
        { codigo: "+251", pais: "Etiopía +251" },
        { codigo: "+63", pais: "Filipinas +63" },
        { codigo: "+358", pais: "Finlandia +358" },
        { codigo: "+679", pais: "Fiyi +679" },
        { codigo: "+33", pais: "Francia +33" },
        { codigo: "+241", pais: "Gabón +241" },
        { codigo: "+220", pais: "Gambia +220" },
        { codigo: "+995", pais: "Georgia +995" },
        { codigo: "+233", pais: "Ghana +233" },
        { codigo: "+350", pais: "Gibraltar +350" },
        { codigo: "+1473", pais: "Granada +1473" },
        { codigo: "+30", pais: "Grecia +30" },
        { codigo: "+299", pais: "Groenlandia +299" },
        { codigo: "+590", pais: "Guadalupe +590" },
        { codigo: "+1671", pais: "Guam +1671" },
        { codigo: "+502", pais: "Guatemala +502" },
        { codigo: "+594", pais: "Guayana Francesa +594" },
        { codigo: "+224", pais: "Guinea +224" },
        { codigo: "+240", pais: "Guinea Ecuatorial +240" },
        { codigo: "+245", pais: "Guinea-Bisáu +245" },
        { codigo: "+592", pais: "Guyana +592" },
        { codigo: "+509", pais: "Haití +509" },
        { codigo: "+504", pais: "Honduras +504" },
        { codigo: "+852", pais: "Hong Kong, Región Administrativa Especial +852" },
        { codigo: "+36", pais: "Hungría +36" },
        { codigo: "+91", pais: "India +91" },
        { codigo: "+62", pais: "Indonesia +62" },
        { codigo: "+964", pais: "Irak +964" },
        { codigo: "+98", pais: "Irán, República Islámica de +98" },
        { codigo: "+353", pais: "Irlanda +353" },
        { codigo: "+672", pais: "Isla Norfolk +672" },
        { codigo: "+354", pais: "Islandia +354" },
        { codigo: "+1", pais: "Islas Caimán +1 345" },
        { codigo: "+682", pais: "Islas Cook +682" },
        { codigo: "+298", pais: "Islas Feroe +298" },
        { codigo: "+500", pais: "Islas Malvinas +500" },
        { codigo: "+1670", pais: "Islas Marianas del Norte +1670" },
        { codigo: "+692", pais: "Islas Marshall +692" },
        { codigo: "+870", pais: "Islas Pitcairn +870" },
        { codigo: "+677", pais: "Islas Salomón +677" },
        { codigo: "+47", pais: "Islas Svalbard y Jan Mayen +47" },
        { codigo: "+1649", pais: "Islas Turcas y Caicos +1 649" },
        { codigo: "+1284", pais: "Islas Vírgenes Británicas +1 284 (BVI)" },
        { codigo: "+1340", pais: "Islas Vírgenes de los Estados Unidos +1 340" },
        { codigo: "+681", pais: "Islas Wallis y Futuna +681" },
        { codigo: "+972", pais: "Israel +972" },
        { codigo: "+39", pais: "Italia +39" },
        { codigo: "+1876", pais: "Jamaica +1876" },
        { codigo: "+81", pais: "Japón +81" },
        { codigo: "+962", pais: "Jordania +962" },
        { codigo: "+7", pais: "Kazajistán +7 6 o 7" },
        { codigo: "+254", pais: "Kenia +254" },
        { codigo: "+996", pais: "Kirguistán +996" },
        { codigo: "+686", pais: "Kiribati +686" },
        { codigo: "+965", pais: "Kuwait +965" },
        { codigo: "+856", pais: "Laos (República Democrática Popular Lao) +856" },
        { codigo: "+1441", pais: "Las islas Bermudas +1441" },
        { codigo: "+266", pais: "Lesoto +266" },
        { codigo: "+371", pais: "Letonia +371" },
        { codigo: "+961", pais: "Líbano +961" },
        { codigo: "+231", pais: "Liberia +231" },
        { codigo: "+218", pais: "Libia +218" },
        { codigo: "+423", pais: "Liechtenstein +423" },
        { codigo: "+370", pais: "Lituania +370" },
        { codigo: "+352", pais: "Luxemburgo +352" },
        { codigo: "+853", pais: "Macao (RAE de China) +853" },
        { codigo: "+389", pais: "Macedonia del Norte +389" },
        { codigo: "+261", pais: "Madagascar +261" },
        { codigo: "+60", pais: "Malasia +60" },
        { codigo: "+265", pais: "Malaui +265" },
        { codigo: "+960", pais: "Maldivas +960" },
        { codigo: "+223", pais: "Malí +223" },
        { codigo: "+356", pais: "Malta +356" },
        { codigo: "+212", pais: "Marruecos y Sahara Occidental +212" },
        { codigo: "+596", pais: "Martinica +596" },
        { codigo: "+230", pais: "Mauricio +230" },
        { codigo: "+222", pais: "Mauritania +222" },
        { codigo: "+262", pais: "Mayotte +262" },
        { codigo: "+52", pais: "México +52" },
        { codigo: "+373", pais: "Moldavia +373" },
        { codigo: "+377", pais: "Mónaco +377" },
        { codigo: "+976", pais: "Mongolia +976" },
        { codigo: "+382", pais: "Montenegro +382" },
        { codigo: "+1664", pais: "Montserrat +1664 (MOI)" },
        { codigo: "+258", pais: "Mozambique +258" },
        { codigo: "+264", pais: "Namibia +264" },
        { codigo: "+674", pais: "Nauru +674" },
        { codigo: "+977", pais: "Nepal +977" },
        { codigo: "+505", pais: "Nicaragua +505" },
        { codigo: "+227", pais: "Níger +227" },
        { codigo: "+234", pais: "Nigeria +234" },
        { codigo: "+683", pais: "Niue +683" },
        { codigo: "+47", pais: "Noruega +47" },
        { codigo: "+687", pais: "Nueva Caledonia +687" },
        { codigo: "+64", pais: "Nueva Zelanda +64" },
        { codigo: "+968", pais: "Omán +968" },
        { codigo: "+31", pais: "Países Bajos +31" },
        { codigo: "+92", pais: "Pakistán +92" },
        { codigo: "+680", pais: "Palaos +680" },
        { codigo: "+507", pais: "Panamá +507" },
        { codigo: "+675", pais: "Papúa Nueva Guinea +675" },
        { codigo: "+595", pais: "Paraguay +595" },
        { codigo: "+51", pais: "Perú +51" },
        { codigo: "+689", pais: "Polinesia Francesa +689" },
        { codigo: "+48", pais: "Polonia +48" },
        { codigo: "+351", pais: "Portugal +351" },
        { codigo: "+1787", pais: "Puerto Rico +1 787 o 939" },
        { codigo: "+974", pais: "Qatar +974" },
        { codigo: "+44", pais: "Reino Unido +44" },
        { codigo: "+236", pais: "República Centroafricana +236" },
        { codigo: "+420", pais: "República Checa +420" },
        { codigo: "+82", pais: "República de Corea del Sur +82" },
        { codigo: "+385", pais: "República de Croacia +385" },
        { codigo: "+242", pais: "República del Congo (Congo-Brazzaville) +242" },
        { codigo: "+243", pais: "República Democrática del Congo (Congo-Kinsasa) +243" },
        { codigo: "+1809", pais: "República Dominicana +1809, 829, 849" },
        { codigo: "+850", pais: "República Popular Democrática de Corea +850" },
        { codigo: "+255", pais: "República Unida de Tanzanía +255" },
        { codigo: "+262", pais: "Reunión y Mayotte +262" },
        { codigo: "+250", pais: "Ruanda +250" },
        { codigo: "+40", pais: "Rumania +40" },
        { codigo: "+7", pais: "Rusia +7" },
        { codigo: "+685", pais: "Samoa +685" },
        { codigo: "+1684", pais: "Samoa Americana +1684" },
        { codigo: "+1869", pais: "San Cristóbal y Nieves +1 869" },
        { codigo: "+378", pais: "San Marino +378" },
        { codigo: "+508", pais: "San Pedro y Miquelón +508" },
        { codigo: "+1784", pais: "San Vicente y las Granadinas +1 784" },
        { codigo: "+290", pais: "Santa Elena, Ascensión y Tristán de Acuña +290" },
        { codigo: "+1758", pais: "Santa Lucía +1 758" },
        { codigo: "+379", pais: "Santa Sede (Estado de la Ciudad del Vaticano) +379" },
        { codigo: "+239", pais: "Santo Tomé y Príncipe +239" },
        { codigo: "+221", pais: "Senegal +221" },
        { codigo: "+381", pais: "Serbia +381" },
        { codigo: "+248", pais: "Seychelles +248" },
        { codigo: "+232", pais: "Sierra Leona +232" },
        { codigo: "+65", pais: "Singapur +65" },
        { codigo: "+963", pais: "Siria +963" },
        { codigo: "+252", pais: "Somalia +252" },
        { codigo: "+94", pais: "Sri Lanka +94" },
        { codigo: "+268", pais: "Suazilandia +268" },
        { codigo: "+27", pais: "Sudáfrica +27" },
        { codigo: "+249", pais: "Sudán +249" },
        { codigo: "+46", pais: "Suecia +46" },
        { codigo: "+41", pais: "Suiza +41" },
        { codigo: "+597", pais: "Surinam +597" },
        { codigo: "+66", pais: "Tailandia +66" },
        { codigo: "+886", pais: "Taiwán, República de China +886" },
        { codigo: "+992", pais: "Tayikistán +992" },
        { codigo: "+970", pais: "Territorio Palestino Ocupado +970" },
        { codigo: "+670", pais: "Timor Oriental +670" },
        { codigo: "+228", pais: "Togo +228" },
        { codigo: "+690", pais: "Tokelau +690" },
        { codigo: "+676", pais: "Tonga +676" },
        { codigo: "+1868", pais: "Trinidad y Tobago +1 868" },
        { codigo: "+216", pais: "Túnez +216" },
        { codigo: "+993", pais: "Turkmenistán +993" },
        { codigo: "+90", pais: "Turquía +90" },
        { codigo: "+688", pais: "Tuvalu +688" },
        { codigo: "+380", pais: "Ucrania +380" },
        { codigo: "+256", pais: "Uganda +256" },
        { codigo: "+598", pais: "Uruguay +598" },
        { codigo: "+998", pais: "Uzbekistán +998" },
        { codigo: "+678", pais: "Vanuatu +678" },
        { codigo: "+58", pais: "Venezuela +58" },
        { codigo: "+84", pais: "Vietnam +84" },
        { codigo: "+967", pais: "Yemen +967" },
        { codigo: "+253", pais: "Yibuti +253" },
        { codigo: "+260", pais: "Zambia +260" },
        { codigo: "+263", pais: "Zimbabue +263" },

    ];

    const [usuario, setUsuario] = useState({ nombreCompleto: '', correo: '', prefijo: '', tel: '', imageProfile: '', flagImageProfile: false, empresa: '', website: '', pais: '' });
    const { nombreCompleto, correo, tel, imageProfile, flagImageProfile, prefijo, empresa, website, pais } = usuario;
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [loadingLikes, setLoadingLikes] = useState(true);
    const [loadingVisitados, setLoadingVisitados] = useState(true);

    const [data, setData] = useState({ pagination: [], data: [], currentPage: 1 });

    const [dataFavorite, setDataFavorite] = useState("");
    const [dataVisit, setDataVisit] = useState([]);
    const [statistics, setStatistics] = useState({ contacts: '', likes: '', views: '' });
    const fieldStats = useRef({});
    const fieldImage = useRef();
    useEffect(() => {
        window.scrollTo({
            top: 400,
            behavior: "smooth"
        });
        setTimeout(() => {
            const $ = window.$;

            var preLoder = $("#preloader");
            preLoder.attr("style", "display:none");
        }, 2000)
    }, [])

    const [dataFilter, setDataFilter] = useState({
        tipo: "",
        buscador: "",
    });
    const filedRef = useRef({});
    const handleFilter = () => {
        const tipoValue = filedRef.current.tipo.options[filedRef.current.tipo.selectedIndex].value;
        const buscadorValue = filedRef.current.buscador.value;
        setPage(1);
        setData({ pagination: [], data: [], currentPage: 1 });
        setDataFilter({
            ...dataFilter,
            tipo: tipoValue,
            buscador: buscadorValue,
        })
    }

    const getUsuario = async () => {
        try {
            const { data: { data: info } } = await cribbeeAPI.get(`/user/${localStorage.getItem('cribbeeUsuario')}`);

            const { fullname, email, phone, photo, prefijo, company, website: websiteBD, pais: paisBD } = info;
            localStorage.setItem('cribbeeImage', photo === null ? "https://other.casyter.com/cribbee-icon.png" : photo);
            localStorage.setItem('cribbeeFullName', fullname);
            localStorage.setItem('cribbeeEmail', email);
            localStorage.setItem('cribbeePhone', `${prefijo}${phone}`);
            setUsuario({ nombreCompleto: fullname, correo: email, tel: phone, prefijo: prefijo, imageProfile: photo === null ? "https://other.casyter.com/cribbee-icon.png" : photo, flagImageProfile: false, empresa: company, website: websiteBD, pais: paisBD });
        } catch (error) {
            console.error(error)
        }
    }
    const getPropiedad = async ({ buscador, tipo }) => {
        try {
            const { data: { data: { currentPage, rows, totalItems, totalPages } } } = await cribbeeAPI.get(`/list-properties-usuario-filter?page=${page}&size=6&usuario=${localStorage.getItem('cribbeeToken')}&search=${buscador}&type=${tipo}`);
            let strPropiedades = '';
            const otras = [];
            const pagination = fetchPageNumbers(totalPages, currentPage, 1);

            for (var index = 0; index < rows.length; index++) {
                const arrayServices = [];
                if (rows[index].rooms != null) {
                    arrayServices.push({ icon: 'fa fa-bed', value: rows[index].rooms, title: "" });
                }
                if (rows[index].bathrooms != null) {
                    arrayServices.push({ icon: 'fa fa-bath', value: rows[index].bathrooms, title: "" });
                }
                if (rows[index].square_meters_build != null) {
                    arrayServices.push({ icon: 'fa fa-object-ungroup', value: rows[index].square_meters_build, title: "mts2" });
                }
                if (rows[index].square_meters_build != null) {
                    arrayServices.push({ icon: 'fa fa-car', value: rows[index].parking_lots, title: "" });
                }
                if (arrayServices.length == 0) {
                    for (var indexS = 0; indexS < 2; indexS++) {
                        if (typeof rows[index].servicios[indexS] !== 'undefined') {
                            arrayServices.push({ icon: 'fa fa-check', value: rows[index].servicios[indexS].id, title: rows[index].servicios[indexS].service });
                        }
                    }
                }
                if (arrayServices.length == 0) {
                    if (rows[index].amenidades.length > 0) {
                        for (var indexA = 0; indexA < 2; indexA++) {
                            if (typeof rows[index].amenidades[indexA] !== 'undefined') {
                                arrayServices.push({ icon: 'fa fa-check', value: rows[index].amenidades[indexA].id, title: rows[index].amenidades[indexA].amenitie });
                            }
                        }
                    }
                }

                if (arrayServices.length == 0) {
                    if (rows[index].espacios.length > 0) {
                        for (var indexS = 0; indexS < 2; indexS++) {
                            if (typeof rows[index].espacios[indexS] !== 'undefined') {
                                arrayServices.push({ icon: 'fa fa-check', value: rows[index].espacios[indexS].id, title: rows[index].espacios[indexS].space });
                            }
                        }
                    }
                }

                otras.push({
                    image:
                    {
                        principal: rows[index].image.length > 0 ? rows[index].image[0].source_path : "https://other.casyter.com/medium-image.png",
                        secundarias: []
                    },
                    id: rows[index].id,
                    vendedor: `${rows[index].city}, ${rows[index].state}`,
                    externalTitle: rows[index].title,
                    internoTitle: rows[index].subtitle,
                    precio: rows[index].price,
                    items: arrayServices,
                    url: `/detalle-propiedad/${rows[index].id}`,
                    urlEdit: `/editar-propiedad/${rows[index].id}`,
                    description: rows[index].description,
                    plan: rows[index].plan_id,
                    likeProperty: rows[index].likeProperty,
                    statistics: rows[index].statistics,
                })
            }
            strPropiedades = otras.length > 0 ? otras.map((item, i) => {
                let flagPlan = `thumb `;
                if (item.plan === 1) {
                    flagPlan += `iconOro`;
                } else if (item.plan === 2) {
                    flagPlan += `iconPlata`;
                }
                return <div key={i} className=" design-property-item">
                    <div className="single-feature row">
                        <div className="col-5 d-flex align-items-center">
                            <div className={flagPlan} >
                                <img src={`${item.image.principal}`} alt={item.externalTitle} />
                            </div>
                        </div>
                        <div className="details col-7">
                            <div style={{ display: 'flex' }} >
                                <p style={{ fontSize: "25px", paddingTop: "3px" }}>
                                    &nbsp;
                                    &nbsp;
                                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`https://www.casyter.com/#/detalle-propiedad/${item.id}`)}`} target="_new_Facebook">
                                        <i className="fa fa-facebook" />
                                    </a>
                                    &nbsp;
                                    &nbsp;
                                    <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(`https://www.casyter.com/#/detalle-propiedad/${item.id}`)}&text=${item.externalTitle}`} target="_new_Twitter">
                                        <i className="fa fa-twitter" />
                                    </a>
                                    &nbsp;
                                    &nbsp;
                                    <a onClick={() => {
                                        var win = window.open(`https://api.whatsapp.com/send/?phone&text=${encodeURIComponent(`https://www.casyter.com/#/detalle-propiedad/${item.id}`)}&app_absent=0`, '_new_Whast');
                                        win.focus();
                                    }} style={{ cursor: "pointer" }}>
                                        <i className="fa fa-whatsapp" />
                                    </a>
                                    &nbsp;
                                    &nbsp;
                                    <a onClick={() => copyToClipboard(`https://www.casyter.com/#/detalle-propiedad/${item.id}`, 1)} style={{ cursor: "pointer" }}>
                                        <i className="fa fa-copy" />
                                    </a>

                                </p>
                            </div>

                            <p className="author"><i className="fa fa-map-marker" /> {item.vendedor}</p>
                            <h6 className="title" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                                {item.internoTitle}
                            </h6>
                            <h6 className="price">{`${item.precio}`}</h6>
                            <p>
                                {item.description.replace(/<br\s*[\/]?>/gi, "").substr(0, 120)}...
                                {/* {wordWrap(item.description, 30).replace(/<br\s*[\/]?>/gi, "\n").substr(0, 65)}... */}
                            </p>
                            <ul className="info-list">
                                {item.items.map((items, i) =>
                                    <li key={i} ><i className={items.icon} /> {items.value} {items.title}</li>
                                )}
                            </ul>
                            <ul className="contact-list text-center">
                                <li>
                                    <Link className="btn btn-orange" to={item.url}>Ver</Link>
                                </li>
                                <li>
                                    <Link className="btn btn-yellow" to={item.urlEdit}>Editar</Link>
                                </li>
                                <li>
                                    <button type="button" className="btn btn-yellow" style={{ background: "grey" }} onClick={() => loaderDeleteProperties(item.id)}>Eliminar</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            })
                : <div className="col-12 text-center d-flex align-items-center justify-content-center">
                    No encontramos una búsqueda que cumpla tus criterios, te invitamos a seguir navegando en la colmena.
                    {/* <Link className="btn btn-yellow" to="/publicar-propiedad">Publica tu primera propiedad <span className="right"><i className="la la-plus" /></span></Link> */}
                </div>
            setData({ pagination: pagination, data: strPropiedades, currentPage: currentPage });
            setLoading(false);
            window.scrollTo({
                top: 400,
                behavior: "smooth"
            });
        } catch (error) {
            console.error(error)
        }
    }
    const getPropiedadFavorite = async () => {
        try {
            setLoadingLikes(true);
            const { data: { data: info } } = await cribbeeAPI.get(`/list-properties-favorites-usuario?usuario=${localStorage.getItem('cribbeeToken')}`);
            let strPropiedadesLikes = '';
            const otras = [];
            for (var index = 0; index < info.length; index++) {
                const arrayServices = [];
                if (info[index].rooms != null) {
                    arrayServices.push({ icon: 'fa fa-bed', value: info[index].rooms, title: "" });
                }
                if (info[index].bathrooms != null) {
                    arrayServices.push({ icon: 'fa fa-bath', value: info[index].bathrooms, title: "" });
                }
                if (info[index].square_meters_build != null) {
                    arrayServices.push({ icon: 'fa fa-object-ungroup', value: info[index].square_meters_build, title: "mts2" });
                }
                if (info[index].square_meters_build != null) {
                    arrayServices.push({ icon: 'fa fa-car', value: info[index].parking_lots, title: "" });
                }
                if (arrayServices.length == 0) {
                    for (var indexS = 0; indexS < 2; indexS++) {
                        if (typeof info[index].servicios[indexS] !== 'undefined') {
                            arrayServices.push({ icon: 'fa fa-check', value: info[index].servicios[indexS].id, title: info[index].servicios[indexS].service });
                        }
                    }
                }
                if (arrayServices.length == 0) {
                    if (info[index].amenidades.length > 0) {
                        for (var indexA = 0; indexA < 2; indexA++) {
                            if (typeof info[index].amenidades[indexA] !== 'undefined') {
                                arrayServices.push({ icon: 'fa fa-check', value: info[index].amenidades[indexA].id, title: info[index].amenidades[indexA].amenitie });
                            }
                        }
                    }
                }

                if (arrayServices.length == 0) {
                    if (info[index].espacios.length > 0) {
                        for (var indexS = 0; indexS < 2; indexS++) {
                            if (typeof info[index].espacios[indexS] !== 'undefined') {
                                arrayServices.push({ icon: 'fa fa-check', value: info[index].espacios[indexS].id, title: info[index].espacios[indexS].space });
                            }
                        }
                    }
                }

                otras.push({
                    image:
                    {
                        principal: info[index].image.length > 0 ? info[index].image[0].source_path : "",
                        secundarias: []
                    },
                    id: info[index].id,
                    vendedor: `${info[index].city}, ${info[index].state}`,
                    externalTitle: info[index].title,
                    precio: info[index].price,
                    items: arrayServices,
                    url: `/detalle-propiedad/${info[index].id}`,
                    urlEdit: `/editar-propiedad/${info[index].id}`,
                    description: info[index].description,
                    plan: info[index].plan_id,
                    likeProperty: info[index].likeProperty,
                })
            }


            strPropiedadesLikes = otras.length > 0 ? otras.map((item, i) => {
                let flagPlan = `thumb `;
                if (item.plan === 1) {
                    flagPlan += `iconOro`;
                } else if (item.plan === 2) {
                    flagPlan += `iconPlata`;
                }
                return <div key={i} className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <div className="single-feature">
                        <div className={flagPlan}>
                            <img src={`${item.image.principal}`} alt="img" />
                        </div>
                        <div className="details">
                            <p className="feature-logo like-active" style={{ fontSize: "20px", paddingTop: "3px", cursor: "pointer" }}
                                onClick={() => {
                                    handleLikeProperty(item.id);
                                }}
                            >
                                <i className="fa fa-heart" style={{ color: "#eb4d61" }}></i>
                            </p>
                            <p className="author"><i className="fa fa-map-marker" /> {item.vendedor}</p>
                            <h6 className="title"><Link to={item.url}>{item.externalTitle}</Link></h6>
                            <h6 className="price">{`${item.precio}`}</h6>
                            <ul className="info-list">
                                {item.items.map((items, i) =>
                                    <li key={i} ><i className={items.icon} /> {items.value} {items.title}</li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            })
                :
                <div className="col-12 text-center d-flex align-items-center justify-content-center">
                    <Link className="btn btn-yellow" to="/propiedades">Te invitamos a navegar en la colmena digital <span className="right"><i className="la la-home" /></span></Link>
                </div>;
            setDataFavorite(strPropiedadesLikes);
            setLoadingLikes(false);
        } catch (error) {
            console.error(error)
        }
    }
    const getPropiedadVisit = async () => {
        try {
            const { data: { data: info } } = await cribbeeAPI.get(`/list-properties-visits-usuario?usuario=${localStorage.getItem('cribbeeToken')}`);

            const otras = [];
            for (var index = 0; index < info.length; index++) {
                const arrayServices = [];
                if (info[index].rooms != null) {
                    arrayServices.push({ icon: 'fa fa-bed', value: info[index].rooms, title: "" });
                }
                if (info[index].bathrooms != null) {
                    arrayServices.push({ icon: 'fa fa-bath', value: info[index].bathrooms, title: "" });
                }
                if (info[index].square_meters_build != null) {
                    arrayServices.push({ icon: 'fa fa-object-ungroup', value: info[index].square_meters_build, title: "mts2" });
                }
                if (info[index].square_meters_build != null) {
                    arrayServices.push({ icon: 'fa fa-car', value: info[index].parking_lots, title: "" });
                }
                if (arrayServices.length == 0) {
                    for (var indexS = 0; indexS < 2; indexS++) {
                        if (typeof info[index].servicios[indexS] !== 'undefined') {
                            arrayServices.push({ icon: 'fa fa-check', value: info[index].servicios[indexS].id, title: info[index].servicios[indexS].service });
                        }
                    }
                }
                if (arrayServices.length == 0) {
                    if (info[index].amenidades.length > 0) {
                        for (var indexA = 0; indexA < 2; indexA++) {
                            if (typeof info[index].amenidades[indexA] !== 'undefined') {
                                arrayServices.push({ icon: 'fa fa-check', value: info[index].amenidades[indexA].id, title: info[index].amenidades[indexA].amenitie });
                            }
                        }
                    }
                }

                if (arrayServices.length == 0) {
                    if (info[index].espacios.length > 0) {
                        for (var indexS = 0; indexS < 2; indexS++) {
                            if (typeof info[index].espacios[indexS] !== 'undefined') {
                                arrayServices.push({ icon: 'fa fa-check', value: info[index].espacios[indexS].id, title: info[index].espacios[indexS].space });
                            }
                        }
                    }
                }
                otras.push({
                    image:
                    {
                        principal: info[index].image.length > 0 ? info[index].image[0].source_path : "",
                        secundarias: []
                    },
                    id: info[index].id,
                    vendedor: `${info[index].city}, ${info[index].state}`,
                    externalTitle: info[index].title,
                    precio: info[index].price,
                    items: arrayServices,
                    url: `/detalle-propiedad/${info[index].id}`,
                    urlEdit: `/editar-propiedad/${info[index].id}`,
                    description: info[index].description,
                    plan: info[index].plan_id,
                    likeProperty: info[index].likeProperty,
                })
            }

            setDataVisit(otras);
            setLoadingVisitados(false);
        } catch (error) {
            console.error(error)
        }
    }

    /** Eliminar propiedades */
    const loaderDeleteProperties = (property) => {
        const resolveWithSomeData = new Promise((resolve, reject) => setTimeout(async () => {
            let { status, info } = await deleteProperty(property);
            if (status) {
                resolve(info)
            } else {
                reject(info)
            }
        }, 1000));
        toast.promise(
            resolveWithSomeData,
            {
                pending: {
                    render() {
                        
                        return "¡Se ha eliminado!"
                    },
                    icon: true,
                },
                success: {
                    render({ data }) {
                        return data
                    },
                    icon: true,
                },
                error: {
                    render({ data }) {
                        return data.message
                    }
                }
            }
        )
    }

    const deleteProperty = async (property) => {
        try {
            const { status, message, data } = await cribbeeAPI.post(`/delete-property`, { property: property });
            if (!status) throw (message);
            handleFilter();
            return { status: true, info: "¡Se ha eliminado!" }
        } catch (error) {
            return { status: false, info: error }
        }
    }

    const getStatistics = async () => {
        try {
            const { data: { data: { statistics: { contact, like, view } } } } = await cribbeeAPI.get(`user-statistics-web`);

            setStatistics({
                contacts: contact.map((item, i) => {
                    let flagPlan = `thumb `;
                    if (item.plan === 1) {
                        flagPlan += `iconOro`;
                    } else if (item.plan === 2) {
                        flagPlan += `iconPlata`;
                    }
                    return <div key={i} className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <div className="single-feature">
                            <div className={flagPlan}>
                                <img src={`${item.pathImage}`} alt="img" />
                            </div>
                            <div className="details">
                                <h6 className="title"><Link to={`/detalle-propiedad/${item.id}`}>{item.title}</Link></h6>
                                <p className="author row">
                                    <div className="col-1">
                                        <i className="fa fa-user" />
                                    </div>
                                    <div className="col">
                                        {item.nameUser}
                                    </div>
                                </p>
                                <p className="author row">
                                    <div className="col-1">
                                        <i className="flaticon flaticon-sent-mail before-ml-0 before-fs-0" />
                                    </div>
                                    <div className="col" style={{ whiteSpace: "normal" }}>
                                        {item.emailUser}
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                }),
                likes: like.map((item, i) => {
                    let flagPlan = `thumb `;
                    if (item.plan === 1) {
                        flagPlan += `iconOro`;
                    } else if (item.plan === 2) {
                        flagPlan += `iconPlata`;
                    }
                    return <div key={i} className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <div className="single-feature">
                            <div className={flagPlan}>
                                <img src={`${item.pathImage}`} alt="img" />
                            </div>
                            <div className="details">
                                <h6 className="title"><Link to={`/detalle-propiedad/${item.id}`}>{item.title}</Link></h6>
                                <p className="author row">
                                    <div className="col-1">
                                        <i className="fa fa-user" />
                                    </div>
                                    <div className="col">
                                        {item.nameUser}
                                    </div>
                                </p>
                                <p className="author row">
                                    <div className="col-1">
                                        <i className="flaticon flaticon-sent-mail before-ml-0 before-fs-0" />
                                    </div>
                                    <div className="col" style={{ whiteSpace: "normal" }}>
                                        {item.emailUser}
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                }),
                views: view.map((item, i) => {
                    let flagPlan = `thumb `;
                    if (item.plan === 1) {
                        flagPlan += `iconOro`;
                    } else if (item.plan === 2) {
                        flagPlan += `iconPlata`;
                    }
                    return <div key={i} className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <div className="single-feature">
                            <div className={flagPlan}>
                                <img src={`${item.pathImage}`} alt="img" />
                            </div>
                            <div className="details">
                                <h6 className="title"><Link to={`/detalle-propiedad/${item.id}`}>{item.title}</Link></h6>
                                <p className="author row">
                                    <div className="col-1">
                                        <i className="fa fa-user" />
                                    </div>
                                    <div className="col">
                                        {item.nameUser}
                                    </div>
                                </p>
                                <p className="author row">
                                    <div className="col-1">
                                        <i className="flaticon flaticon-sent-mail before-ml-0 before-fs-0" />
                                    </div>
                                    <div className="col" style={{ whiteSpace: "normal" }}>
                                        {item.emailUser}
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                }),
            });
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getPropiedad(dataFilter);
    }, [dataFilter, page]);

    useEffect(() => {
        getPropiedadFavorite();
        getPropiedadVisit();
        getUsuario();
        getStatistics();
    }, []);


    const initStatePI = {
        btnDatos: section !== null ? false : true,
        btnPropiedades: section !== null ? true : false,
        btnFavorite: false,
        btnStatistics: false,
        btnVisits: false,
        btnPassword: false,
    };
    const initStatePI2 = {
        btnDatos: true,
        btnPropiedades: false,
        btnFavorite: false,
        btnStatistics: false,
        btnVisits: false,
        btnPassword: false,
    };
    const [miCuenta, setMiCuenta] = useState(initStatePI);
    const { btnDatos, btnPropiedades, btnFavorite, btnStatistics, btnVisits, btnPassword } = miCuenta;

    const actionWizard = (tab) => {
        if (tab === 1) {
            setMiCuenta({
                ...initStatePI2,
            });
        } else if (tab === 2) {
            setMiCuenta({
                ...initStatePI2,
                ["btnDatos"]: false,
                ["btnPropiedades"]: true,
            });
        } else if (tab === 3) {
            setMiCuenta({
                ...initStatePI2,
                ["btnDatos"]: false,
                ["btnFavorite"]: true,
            });
        } else if (tab === 4) {
            setMiCuenta({
                ...initStatePI2,
                ["btnDatos"]: false,
                ["btnStatistics"]: true,
            });

        } else if (tab === 5) {
            setMiCuenta({
                ...initStatePI2,
                ["btnDatos"]: false,
                ["btnVisits"]: true,
            });
        } else if (tab === 6) {
            setMiCuenta({
                ...initStatePI2,
                ["btnDatos"]: false,
                ["btnPassword"]: true,
            });

        }
    }



    const { logOut } = useContext(AuthContext);
    const logout = () => {
        logOut();
        window.location.href = "#/";
    }




    let strPropiedadesVisit = '';
    if (Array.isArray(dataVisit)) {
        strPropiedadesVisit = dataVisit.map((item, i) => {
            let flagPlan = `thumb `;
            if (item.plan === 1) {
                flagPlan += `iconOro`;
            } else if (item.plan === 2) {
                flagPlan += `iconPlata`;
            }
            return <div key={i} className="col-xl-4 col-lg-4  col-md-6 col-sm-6">
                <div className="single-feature">
                    <div className={flagPlan}>
                        <img src={`${item.image.principal}`} alt="img" />
                    </div>
                    <div className="details">
                        <p className="author"><i className="fa fa-map-marker" /> {item.vendedor}</p>
                        <h6 className="title"><Link to={item.url}>{item.externalTitle}</Link></h6>
                        <h6 className="price">{`${item.precio}`}</h6>
                        <ul className="info-list">
                            {item.items.map((items, i) =>
                                <li key={i} ><i className={items.icon} /> {items.value} {items.title}</li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        });
    }
    const onSubmitEmail = (event) => {
        event.preventDefault();
        const resolveWithSomeData = new Promise((resolve, reject) => setTimeout(async () => {
            let { status, info } = await postActualizarDatos();
            if (status) {
                resolve(info)
            } else {
                reject(info)
            }
        }, 1000));
        toast.promise(
            resolveWithSomeData,
            {
                pending: {
                    render() {
                        return "Actualizando"
                    },
                    icon: true,
                },
                success: {
                    render({ data }) {
                        return data
                    },
                    icon: true,
                },
                error: {
                    render({ data }) {
                        return data.message
                    }
                }
            }
        )


    }

    const postActualizarDatos = async () => {
        try {
            const formData = new FormData(document.querySelector(`form[name="form-actualizar"]`));
            const { status, message, data } = await cribbeeAPI.put(`/update-usuario`, { fullname: formData.get('fullname'), phone: formData.get('phone'), prefijo: formData.get('prefijo'), empresa: formData.get('empresa'), website: formData.get('website') });
            if (!status) throw (message);
            if (flagImageProfile === true) {
                await cribbeeAPI.put(`/user/upload-image-web/${localStorage.getItem('cribbeeUsuario')}`, { imageProfile: imageProfile });
                fieldImage.current.innerHTML = `Subir imagen de perfil "da click aquí"<br></br>(Solo se permiten .jpg, .jpeg y .png)`;
                getUsuario();
            }
            return { status: true, info: "¡Se ha actualizado con éxito!" }
        } catch (error) {
            return { status: false, info: error }
        }
    }

    const onSubmitPassword = (event) => {
        event.preventDefault();
        const resolveWithSomeData = new Promise((resolve, reject) => setTimeout(async () => {
            let { status, info } = await postActualizarPassword();
            if (status) {
                resolve(info)
            } else {
                reject(info)
            }
        }, 1000));
        toast.promise(
            resolveWithSomeData,
            {
                pending: {
                    render() {
                        return "Actualizando..."
                    },
                    icon: true,
                },
                success: {
                    render({ data }) {
                        return data
                    },
                    icon: true,
                },
                error: {
                    render({ data }) {
                        return data
                    }
                }
            }
        )


    }
    const postActualizarPassword = async () => {
        try {
            const formData = new FormData(document.querySelector(`form[name="form-password"]`));

            if (formData.get("confirmPassword") != formData.get("password")) throw ("Valide que las contraseñas sean iguales");

            const { status, message, data } = await cribbeeAPI.put(`/change-password`, { password: formData.get('password') });

            document.querySelector(`form[name="form-password"]`).reset();

            if (!status) throw (message);

            return { status: true, info: "Se ha cambiado su contraseña" }
        } catch (error) {
            if (error == "Valide que las contraseñas sean iguales") {
                return { status: false, info: "Valide que las contraseñas sean iguales" }
            } else {
                return { status: false, info: error.response.data.message }
            }
        }
    }

    const handleShowInfo = (type) => {
        // 1 = Contacto ---- 2 = Likes ---- 3 = Views
        if (type === 1) {
            fieldStats.current.contentContacts.classList.remove("d-none");
            fieldStats.current.contentLikes.classList.add("d-none")
            fieldStats.current.contentViews.classList.add("d-none")
        } else if (type === 2) {
            fieldStats.current.contentContacts.classList.add("d-none");
            fieldStats.current.contentLikes.classList.remove("d-none")
            fieldStats.current.contentViews.classList.add("d-none")
        } else if (type === 3) {
            fieldStats.current.contentContacts.classList.add("d-none");
            fieldStats.current.contentLikes.classList.add("d-none")
            fieldStats.current.contentViews.classList.remove("d-none")
        }
    }


    /*=============================================================
    =                   Functions Image Profile                   =
    =============================================================*/

    const encoded = (file) => {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.onload = function (e) {
                resolve({ base64: e.target.result, name: file.name });
            }
            reader.readAsDataURL(file);
        })
    }


    const changeImage = async ({ target }) => {
        try {
            const file = target.files[0];
            if (file) {
                const { base64: image } = await encoded(file);
                target.value = null;
                fieldImage.current.innerHTML = `<img src="${image}" style="height:80px; width:80px" />`;
                setUsuario({ ...usuario, imageProfile: image, flagImageProfile: true });
            }
        } catch (error) {
            console.log(error);
        }
    };


    const handleLikeProperty = async (property) => {
        try {
            await cribbeeAPI.post('/remove-like-property-web', { property: property, user: localStorage.getItem('cribbeeUsuario'), });
            getPropiedadFavorite();
        } catch (error) {
            // console.error(error);
        }
    }


    const fetchPageNumbers = (totalPages, currentPage, pageNeighbours) => {
        // totalPages = Total de Paginas
        // currentPage = Pagina actual
        // pageNeighbours = Cuantas paginas se mostraran
        const totalNumbers = (pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;
        const LEFT_PAGE = 'LEFT';
        const RIGHT_PAGE = 'RIGHT';
        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            let pages = range(startPage, endPage);

            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }

                // handle: (1) {2 3} [4] {5 6} > (10)
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }

                // handle: (1) < {4 5} [6] {7 8} > (10)
                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    }

    const range = (from, to, step = 1) => {
        let i = from;
        const range = [];

        while (i <= to) {
            range.push(i);
            i += step;
        }

        return range;
    }
    const copyToClipboard = (url, showAlert) => {
        navigator.clipboard.writeText(url)
        if (showAlert == 1) {
            toast.success(`Copiado`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: false,
                theme: "light",
            });
        }
    };
    return (
        <>
            <Navbar />
            <PageHeader headertitle="Actualizar mi perfil" subheader="Mi cuenta" />
            <div className="properties-area pd-top-92 pd-bottom-92">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="widget widget-owner-info mt-lg-0 mt-5">
                                <div className="owner-info text-center">
                                    <div className="thumb">
                                        <img src={imageProfile} width="50px" height="50px" />
                                    </div>
                                    <div className="details">
                                        <h6>{nombreCompleto}</h6>
                                        <span className="designation">{correo}</span>
                                    </div>
                                </div>
                                <div className="contact">
                                    <button type="button" className={btnDatos ? "btn btn-orange btn-block" : "btn btn-yellow btn-block"} style={{ height: "25px", lineHeight: "25px" }} onClick={() => { actionWizard(1) }}>Datos Personales</button>
                                    <button type="button" className={btnPassword ? "btn btn-orange btn-block" : "btn btn-yellow btn-block"} style={{ height: "25px", lineHeight: "25px" }} onClick={() => { actionWizard(6) }}>Cambiar Contraseña</button>
                                    <button type="button" className={btnPropiedades ? "btn btn-orange btn-block mt-2" : "btn btn-yellow btn-block mt-2"} style={{ height: "25px", lineHeight: "25px" }} onClick={() => { actionWizard(2) }}>Mis Propiedades</button>
                                    <button type="button" className={btnFavorite ? "btn btn-orange btn-block mt-2" : "btn btn-yellow btn-block mt-2"} style={{ height: "25px", lineHeight: "25px" }} onClick={() => { actionWizard(3) }}>Mis Likes</button>
                                    <button type="button" className={btnVisits ? "btn btn-orange btn-block mt-2" : "btn btn-yellow btn-block mt-2"} style={{ height: "25px", lineHeight: "25px" }} onClick={() => { actionWizard(5) }}>Visitados</button>
                                    <button type="button" className={btnStatistics ? "btn btn-orange btn-block mt-2" : "btn btn-yellow btn-block mt-2"} style={{ height: "25px", lineHeight: "25px" }} onClick={() => { actionWizard(4) }}>Estadísticas</button>
                                    <hr />
                                    <button type="button" className="btn border btn-block mt-3" style={{ height: "25px", lineHeight: "25px" }} onClick={logout} >Cerrar Sesión</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className={btnDatos ? "col-md-8" : "col-md-8 d-none"}>
                            <form className="row" onSubmit={onSubmitEmail} name="form-actualizar">
                                <div className="col-md-12">
                                    <h4>DATOS PERSONALES</h4>
                                </div>
                                <div className="col-md-12">
                                    <label>Nombre Completo</label>
                                    <div className="rld-single-input">
                                        <input type="text" defaultValue={nombreCompleto} name="fullname" required
                                            onChange={
                                                (e) => {
                                                    setUsuario({ ...usuario, nombreCompleto: e.target.value });
                                                }
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <label>Correo Electrónico</label>
                                    <div className="rld-single-input">
                                        <input type="text" defaultValue={correo} disabled />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label>Prefijo</label>
                                    <div className="rld-single-select">
                                        <select class="select single-select pr-1" name="prefijo"
                                            onChange={
                                                (e) => {
                                                    setUsuario({ ...usuario, prefijo: e.target.value });
                                                }
                                            }
                                        >
                                            {
                                                arrayPrefijos.map(({ codigo, pais: paisDB }) => {
                                                    if (pais === paisDB) {
                                                        return <option value={`${codigo}|${paisDB}`} selected={true}>{paisDB}</option>
                                                    } else {
                                                        if (codigo == "+52") {
                                                            return <option value={`${codigo}|${paisDB}`} selected={true}>{paisDB}</option>
                                                        } else {
                                                            return <option value={`${codigo}|${paisDB}`}>{paisDB}</option>
                                                        }
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label>Teléfono</label>
                                    <div className="rld-single-input">
                                        <input type="text" defaultValue={tel} name="phone" required
                                            onChange={
                                                (e) => {
                                                    setUsuario({ ...usuario, tel: e.target.value });
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Empresa</label>
                                    <div className="rld-single-input">
                                        <input type="text" defaultValue={empresa} name="empresa"
                                            onChange={
                                                (e) => {
                                                    setUsuario({ ...usuario, empresa: e.target.value });
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>WebSite</label>
                                    <div className="rld-single-input">
                                        <input type="text" defaultValue={website} name="website"
                                            onChange={
                                                (e) => {
                                                    setUsuario({ ...usuario, website: e.target.value });
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 pt-3">

                                    <div className="inputFile">
                                        <div className="image-upload-wrap">
                                            <input
                                                className="file-upload-input"
                                                type="file"
                                                accept=".png, .jpg, .jpeg"
                                                name="inputImage"
                                                multiple
                                                onChange={(e) => {
                                                    changeImage(e);
                                                }}
                                            />
                                            <p className="my-4 text-center"
                                                ref={
                                                    el => fieldImage.current = el
                                                }
                                            >
                                                Subir imagen de perfil "da click aquí"
                                                <br></br>
                                                (Solo se permiten .jpg, .jpeg y .png)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <br />
                                    <button type="submit" className="btn btn-yellow btn-block">Actualizar Información</button>
                                </div>

                            </form>
                        </div>
                        <div className={btnPassword ? "col-md-8" : "col-md-8 d-none"}>
                            <form className="row" onSubmit={onSubmitPassword} name="form-password">
                                <div className="col-md-12">
                                    <h4>Cambiar Contraseña</h4>
                                </div>
                                <div className="col-md-6">
                                    <label>Nueva Contraseña</label>
                                    <div className="rld-single-input">
                                        <input type="password" name="password" required />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Confirmar Contraseña</label>
                                    <div className="rld-single-input">
                                        <input type="password" name="confirmPassword" required />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <br />
                                    <button type="submit" className="btn btn-yellow btn-block">Actualizar Contraseña</button>
                                </div>
                            </form>
                        </div>
                        <div className={btnPropiedades ? "col-md-8 row" : "col-md-8 row d-none"}>
                            {loading ?

                                <div className="col-12">
                                    <div className="py-2 loader-container text-center" style={{ height: "500px", overflowY: "scroll", overflowX: "hidden" }}>
                                        <div>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className="loader"></div>
                                            </div>
                                            <br />
                                            <b style={{ color: "#fbb138" }}>Cargando Propiedades</b>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="col-12">

                                    <div className="row">
                                        <div className="mb-2 col-12">
                                            <div className="rld-single-input">
                                                <input type="text" placeholder="Buscar..." name="buscador"
                                                    ref={
                                                        el => {
                                                            filedRef.current = {
                                                                ...filedRef.current,
                                                                [`buscador`]: el
                                                            };
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-4 mb-2">
                                            <div className="rld-single-select">
                                                <select className="select single-select m-0" name="tipo"
                                                    ref={
                                                        el => {
                                                            filedRef.current = {
                                                                ...filedRef.current,
                                                                [`tipo`]: el
                                                            };
                                                        }
                                                    }
                                                >
                                                    <option value="">Renta / Venta</option>
                                                    <option value="renta">Renta</option>
                                                    <option value="venta">Venta</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-xl-2 col-lg-4 col-md-4 readeal-top mt-2">
                                            <button className="btn btn-yellow" type="button" onClick={() => handleFilter()}>BUSCAR</button>
                                        </div>

                                    </div>
                                    <br></br>

                                    {data.data}
                                    <div className="widget widget-owner-info">
                                        <div className="d-flex justify-content-center">
                                            <div className="d-flex flex-wrap py-2 mr-3">
                                                {
                                                    data.pagination.map((page, index) => {
                                                        if (page == "LEFT") {
                                                            return <button type="button" className="btn mr-2 my-1" onClick={() => setPage((prevPage) => prevPage - 1)}><i className="fa fa-arrow-left"></i></button>
                                                        }
                                                        if (page == "RIGHT") {
                                                            return <button type="button" className="btn mr-2 my-1" onClick={() => setPage((prevPage) => prevPage + 1)}><i className="fa fa-arrow-right"></i></button>
                                                        }
                                                        return <button type="button" className={`btn  mr-2 my-1 ${page === data.currentPage ? " btn-yellow" : ""}`}
                                                            onClick={() => setPage(page)}
                                                        >{page}</button>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={btnFavorite ? "col-md-8 row" : "col-md-8 row d-none"}>
                            {loadingLikes ?

                                <div className="col-12">
                                    <div className="py-2 loader-container text-center" style={{ height: "500px", overflowY: "scroll", overflowX: "hidden" }}>
                                        <div>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className="loader"></div>
                                            </div>
                                            <br />
                                            <b style={{ color: "#fbb138" }}>Cargando propiedades con Likes</b>
                                        </div>
                                    </div>
                                </div>
                                :
                                dataFavorite
                            }
                        </div>
                        <div className={btnVisits ? "col-md-8 row" : "col-md-8 row d-none"}>
                            {loadingVisitados ?

                                <div className="col-12">
                                    <div className="py-2 loader-container text-center" style={{ height: "500px", overflowY: "scroll", overflowX: "hidden" }}>
                                        <div>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className="loader"></div>
                                            </div>
                                            <br />
                                            <b style={{ color: "#fbb138" }}>Cargando propiedades visitadas</b>
                                        </div>
                                    </div>
                                </div>
                                :
                                strPropiedadesVisit
                            }
                        </div>
                        <div className={btnStatistics ? "col-md-8 row d-flex justify-content-center" : "col-md-8 row d-none"}>
                            <div className="col-md-9">
                                <div className="card" style={{ boxShadow: "10px 10px 15px 0px rgba(166,166,166,0.75)" }}>
                                    <div className="card-body">
                                        <b style={{ color: "black" }}>Contactos (Teléfono, WhatsApp y Correo Electrónico)</b>
                                        <br></br>
                                        <div className="d-flex">
                                            <h3 style={{ color: "#c9792c" }}>{statistics.contacts.length}</h3><button type="button" className="btn btn-link p-0 ml-4" onClick={() => handleShowInfo(1)}>Ver <i className="fa fa-eye"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-4">
                                <div className="card" style={{ boxShadow: "10px 10px 15px 0px rgba(166,166,166,0.75)" }}>
                                    <div className="card-body">
                                        <b style={{ color: "black" }}>Likes</b>
                                        <br></br>
                                        <div className="d-flex">
                                            <h3 style={{ color: "#c9792c" }}>{statistics.likes.length}</h3><button type="button" className="btn btn-link p-0 ml-4" onClick={() => handleShowInfo(2)}>Ver <i className="fa fa-eye"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mt-4">
                                <div className="card" style={{ boxShadow: "10px 10px 15px 0px rgba(166,166,166,0.75)" }}>
                                    <div className="card-body">
                                        <b style={{ color: "black" }}>Vistas</b>
                                        <br></br>
                                        <div className="d-flex">
                                            <h3 style={{ color: "#c9792c" }}>{statistics.views.length}</h3><button type="button" className="btn btn-link p-0 ml-4" onClick={() => handleShowInfo(3)}>Ver <i className="fa fa-eye"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-4" ref={el => fieldStats.current.contentContacts = el}>
                                <hr></hr>
                                <h3>Propiedades con Contactos</h3>
                                <div className="row">
                                    {statistics.contacts}
                                </div>
                            </div>
                            <div className="col-12 mt-4 d-none" ref={el => fieldStats.current.contentLikes = el}>
                                <hr></hr>
                                <h3>Propiedades con Likes</h3>
                                <div className="row">
                                    {statistics.likes}
                                </div>
                            </div>
                            <div className="col-12 mt-4 d-none" ref={el => fieldStats.current.contentViews = el}>
                                <hr></hr>
                                <h3>Propiedades con Visitas</h3>
                                <div className="row">
                                    {statistics.views}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer style={{ width: "auto", marginTop: "180px" }} />
            <Footer />
        </>
    );
}

export default MiCuenta

