import React, { Component, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { cribbeeAPI } from '../../api/cribbeeAPI';

import { StickyContainer, Sticky } from 'react-sticky';

const MyMarker = () => (<><img src={process.env.PUBLIC_URL + "/assets/img/icon.png"} width={20} style={{ marginLeft: "-12px", marginTop: "-10px" }} /></>);


const PropertyDetails = (props) => {
  const { propiedad } = props;
  const { imagesPropiedad, precio, externalTitle, description, direccion: { ocultar, calle, colonia, noExt, cp, ciudad, estado, lat, lng }, vendedor: { image, nombre, tel, email, direccion, empresa, website }, itemsProperty, servicios, espacios, amenidades, planos, recorrido, videos, id } = propiedad;

  const onSubmitEmail = (event) => {
    event.preventDefault();
    const resolveWithSomeData = new Promise((resolve, reject) => setTimeout(async () => {
      let { status, info } = await postEnviarCorreo();
      if (status) {
        resolve(info)
      } else {
        reject(info)
      }
    }, 1000));
    toast.promise(
      resolveWithSomeData,
      {
        pending: {
          render() {
            return "Enviando Correo"
          },
          icon: true,
        },
        success: {
          render({ data }) {
            return data
          },
          icon: true,
        },
        error: {
          render({ data }) {
            return data.message
          }
        }
      }
    )


  }

  const postEnviarCorreo = async () => {
    try {
      const formData = new FormData(document.querySelector(`form[name="form-contacto"]`));
      const { status, message, data } = await cribbeeAPI.get(`/email-contacto?nombre=${formData.get("nombre")}&correo=${formData.get("correo")}&mensaje=${formData.get("mensaje")}&propiedad=${id}&usuario=${localStorage.getItem('cribbeeUsuario')}`);

      if (!status) throw (message);
      document.querySelector(`form[name="form-contacto"]`).reset();
      return { status: true, info: "Se ha enviado el correo" }
    } catch (error) {
      return { status: false, info: error }
    }
  }

  const handleLikeProperty = async (property) => {
    try {
      const data = await cribbeeAPI.post('/contact-property-web', { property: property, user: localStorage.getItem('cribbeeUsuario'), });
      console.log(data);
    } catch (error) {
      // console.error(error);
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href)
    toast.success(`Copiado`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: false,
      theme: "light",
    });
  };


  var ancho = window.innerWidth;
  return <div className="property-details-area">
    {ancho <= 1199 ?
      <div className="container">
        <div className="row pd-top-90">
          <div className="col-lg-9">
            <div>
              {
                imagesPropiedad.length > 1 ?
                  <div className="property-details-slider">
                    {
                      imagesPropiedad.map(
                        (value, key) => <div className="item" key={key}>
                          <div className="thumb">
                            <img src={value} alt={externalTitle} />
                          </div>
                        </div>
                      )
                    }
                  </div>
                  :
                  <div className="thumb"><img src={imagesPropiedad[0]} alt={externalTitle} /></div>
              }
              <div className="property-details-slider-info">
                <h3><span>{`${precio}/MX`}</span></h3>
                <h3>{externalTitle}</h3>
                <h4>{calle} {noExt} {colonia}</h4>
                <br />

                <ul className="social-icon">
                  <li>
                    <a href={`https://www.facebook.com/sharer/sharer.php?p[url]=${encodeURIComponent(`https://www.casyter.com/#/detalle-propiedad/${id}`)}`} target="_new_Facebook">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(`https://www.casyter.com/#/detalle-propiedad/${id}`)}&text=${externalTitle}`} target="_new_Twitter">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a onClick={() => {
                      var win = window.open(`https://api.whatsapp.com/send/?phone&text=${encodeURIComponent(`https://www.casyter.com/#/detalle-propiedad/${id}`)}&app_absent=0`, '_new_Whast');
                      win.focus();

                    }} style={{ cursor: "pointer" }}>
                      <i className="fa fa-whatsapp" />
                    </a>
                  </li>
                  <li>
                    <a onClick={() => copyToClipboard()} style={{ cursor: "pointer" }}>
                      <i className="fa fa-copy" />
                    </a>
                  </li>
                </ul>
                <br></br>
              </div>

            </div>

            <div className="property-info mb-5">
              <div className="row">
                {
                  itemsProperty.length > 0 &&
                  itemsProperty.map(
                    (value, key) => <div className="col-md-3 col-sm-6" key={key}>
                      <div className="single-property-info">
                        <h5>{value.title}</h5>
                        <p><i className={value.icon} />{value.value}</p>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="property-news-single-card style-two border-bottom-yellow">
              <h4>{externalTitle}</h4>
              <p dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
            <div className="property-news-single-card style-two border-bottom-yellow">
              <h4>Dirección de la Propiedad</h4>
              {!ocultar ?
                <div style={{ height: "300px" }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyDjl4Ux0-5hYmLEVMdhB63ldRYCeIqcx7A", language: "es", region: "MX" }}
                    center={{ lat: lat, lng: lng }}
                    zoom={18}
                  >
                    <MyMarker key={1} lat={lat} lng={lng} text={1} tooltip="Propiedad" />
                  </GoogleMapReact>
                </div>
                :
                <div className="property-news-single-card"><iframe src={`https://maps.google.com/maps?width=100%&height=100%&hl=es&q=${encodeURI(`${colonia}, ${ciudad}, ${estado}, México`)}&t=&z=15&ie=UTF8&iwloc=B&output=embed`} style={{ border: 0, height: "500px" }} allowFullScreen /></div>
              }

            </div>
            {servicios.length > 0 &&
              <div className="property-news-single-card border-bottom-yellow">
                <h4>Servicios</h4>
                <div className="row">
                  {
                    servicios.map(({ value }, key) => <div className="col-md-3 mb-3" key={key}><ul className="rld-list-style"><li><i className="fa fa-check"></i>{value}</li></ul></div>)
                  }
                </div>
              </div>
            }
            {espacios.length > 0 &&
              <div className="property-news-single-card border-bottom-yellow">
                <h4>Espacios</h4>
                <div className="row">
                  {
                    espacios.map(({ value }, key) => <div className="col-md-3 mb-3" key={key}><ul className="rld-list-style"><li><i className="fa fa-check"></i>{value}</li></ul></div>)
                  }
                </div>
              </div>
            }
            {amenidades.length > 0 &&
              <div className="property-news-single-card border-bottom-yellow">
                <h4>Amenidades</h4>
                <div className="row">
                  {
                    amenidades.map(({ value }, key) => <div className="col-md-3 mb-3" key={key}><ul className="rld-list-style"><li><i className="fa fa-check"></i>{value}</li></ul></div>)
                  }
                </div>
              </div>
            }
            {videos.length > 0 &&
              <div className="property-news-single-card border-bottom-yellow">
                <h4>Videos de la propiedad</h4>
                {
                  videos.map(
                    (value, key) => <div className="thumb" key={key}> <iframe src={value} allowFullScreen /></div>
                  )
                }
              </div>
            }
            {planos.length > 0 &&
              <div className="property-news-single-card border-bottom-yellow">
                <h4>Planos de la propiedad</h4>
                {
                  planos.map(
                    (value, key) => <div className="thumb" key={key}><img src={value} alt="image" /></div>
                  )
                }
              </div>
            }
            {recorrido.length > 0 &&
              <div className="property-news-single-card border-bottom-yellow mb-0">
                <h4>Recorrido 360</h4>
                {
                  recorrido.map(
                    (value, key) => <div className="thumb" key={key}><iframe style={{ width: "100%", height: "640px", border: "none" }} border="0" allowFullScreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" src={value}></iframe></div>
                  )
                }
              </div>
            }
          </div>
          <div className="col-xl-3 col-lg-4">
            {id === "nuevo" ?
              <div className="widget widget-owner-info mt-lg-0 mt-5">
                <div className="owner-info text-center">
                  <div className="thumb">
                    <img src={image} alt="image" />
                  </div>
                  <div className="details">
                    <h6>{nombre}</h6>
                    {empresa != null ? <a href={website != null ? website : "#"} target="_blank" className="designation">Website</a> : ``}
                  </div>
                </div>
                <div className="contact">
                  <h6>Contáctenos</h6>
                  <form onSubmit={onSubmitEmail} name="form-contacto">
                    <div className="rld-single-input">
                      <input type="text" name="nombre" placeholder="Nombre completo" required />
                    </div>
                    <div className="rld-single-input">
                      <input type="email" name="correo" placeholder="Correo electrónico" required />
                    </div>
                    <div className="rld-single-input">
                      <input type="text" name="mensaje" placeholder="Mensaje" required />
                    </div>
                    <button type="submit" className="btn btn-yellow">Enviar mensajes</button>
                  </form>
                </div>
                <div className="contact-info">
                  <h6 className="mb-3">Información de contacto</h6>
                  <div className="media">
                    {direccion !== '' &&
                      <div className="media-body">
                        <p>Dirección</p>
                        <span>{direccion}</span>
                      </div>
                    }
                  </div>
                  {
                    tel !== '' &&
                    <a className="btn btn-yellow btn-block" href={"tel:+52" + tel} onClick={() => handleLikeProperty(id)}>
                      <i className="fa fa-phone mr-2" />
                      Teléfono
                    </a>
                  }
                  {
                    tel !== '' &&
                    <a className="btn btn-orange btn-block" target="_whatsapp" href={"https://api.whatsapp.com/send?phone=" + tel + "&text=Hola, " + nombre + " encontré esta propiedad en Casyter, ¿Sigue disponible? " + encodeURIComponent(`https://www.casyter.com/#/detalle-propiedad/${id}`)} onClick={() => handleLikeProperty(id)}>
                      <i className="fa fa-whatsapp mr-2" />
                      Whatsapp
                    </a>
                  }
                </div>
              </div>
              :

              <div className="widget widget-owner-info mt-lg-0 mt-5" >
                <div className="owner-info text-center">
                  <div className="thumb">
                    <img src={image} alt="image" />
                  </div>
                  <div className="details">
                    <h6>{nombre}</h6>
                    {empresa != null ? <a href={website != null ? website : "#"} target="_blank" className="designation">Website</a> : ``}
                  </div>
                </div>
                <div className="contact">
                  <h6>Contáctenos</h6>
                  <form onSubmit={onSubmitEmail} name="form-contacto">
                    <div className="rld-single-input">
                      <input type="text" name="nombre" placeholder="Nombre completo" required />
                    </div>
                    <div className="rld-single-input">
                      <input type="email" name="correo" placeholder="Correo electrónico" required />
                    </div>
                    <div className="rld-single-input">
                      <input type="text" name="mensaje" placeholder="Mensaje" required />
                    </div>
                    <button type="submit" className="btn btn-yellow">Enviar mensajes</button>
                  </form>
                </div>
                <div className="contact-info">
                  <h6 className="mb-3">Información de contacto</h6>
                  <div className="media">
                    {direccion !== '' &&
                      <div className="media-body">
                        <p>Dirección</p>
                        <span>{direccion}</span>
                      </div>
                    }
                  </div>
                  {
                    tel !== '' &&
                    <a className="btn btn-yellow btn-block" href={"tel:+52" + tel} onClick={() => handleLikeProperty(id)}>
                      <i className="fa fa-phone mr-2" />
                      Teléfono
                    </a>
                  }
                  {
                    tel !== '' &&
                    <a className="btn btn-orange btn-block" target="_whatsapp" href={"https://api.whatsapp.com/send?phone=" + tel + "&text=Hola, " + nombre + " encontré esta propiedad en Casyter, ¿Sigue disponible? " + encodeURIComponent(`https://www.casyter.com/#/detalle-propiedad/${id}`)} onClick={() => handleLikeProperty(id)}>
                      <i className="fa fa-whatsapp mr-2" />
                      Whatsapp
                    </a>
                  }
                </div>
              </div>
            }


          </div>
        </div>
      </div>
      :
      <StickyContainer>
        <div className="container">
          <div className="row pd-top-90">
            <div className="col-lg-9">
              <div>
                {
                  imagesPropiedad.length > 1 ?
                    <div className="property-details-slider">
                      {
                        imagesPropiedad.map(
                          (value, key) => <div className="item" key={key}>
                            <div className="thumb">
                              <img src={value} alt={externalTitle} />
                            </div>
                          </div>
                        )
                      }
                    </div>
                    :
                    <div className="thumb"><img src={imagesPropiedad[0]} alt={externalTitle} /></div>
                }
                <div className="property-details-slider-info">
                  <h3><span>{`${precio}/MX`}</span></h3>
                  <h3>{externalTitle}</h3>
                  <h4>
                    {!ocultar ? `${calle} ${noExt} ${colonia}` : `${colonia}, ${ciudad}, ${estado}`}
                  </h4>
                  <br />

                  <ul className="social-icon">
                    <li>
                      <a href={`https://www.facebook.com/sharer/sharer.php?p[url]=${encodeURIComponent(`https://www.casyter.com/#/detalle-propiedad/${id}`)}`} target="_new_Facebook">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(`https://www.casyter.com/#/detalle-propiedad/${id}`)}&text=${externalTitle}`} target="_new_Twitter">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a onClick={() => {
                        var win = window.open(`https://api.whatsapp.com/send/?phone&text=${encodeURIComponent(`https://www.casyter.com/#/detalle-propiedad/${id}`)}&app_absent=0`, '_new_Whast');
                        win.focus();
                      }} style={{ cursor: "pointer" }}>
                        <i className="fa fa-whatsapp" />
                      </a>
                    </li>
                    <li>
                      <a onClick={() => copyToClipboard()} style={{ cursor: "pointer" }}>
                        <i className="fa fa-copy" />
                      </a>
                    </li>
                  </ul>
                  <br></br>
                </div>

              </div>

              <div className="property-info mb-5">
                <div className="row">
                  {
                    itemsProperty.length > 0 &&
                    itemsProperty.map(
                      (value, key) => <div className="col-md-3 col-sm-6" key={key}>
                        <div className="single-property-info">
                          <h5>{value.title}</h5>
                          <p><i className={value.icon} />{value.value}</p>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="property-news-single-card style-two border-bottom-yellow">
                <h4>{externalTitle}</h4>
                <p dangerouslySetInnerHTML={{ __html: description }}></p>
              </div>
              <div className="property-news-single-card style-two border-bottom-yellow">
                <h4>Dirección de la Propiedad</h4>
                {!ocultar ?
                  <div style={{ height: "300px" }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: "AIzaSyDjl4Ux0-5hYmLEVMdhB63ldRYCeIqcx7A", language: "es", region: "MX" }}
                      center={{ lat: lat, lng: lng }}
                      zoom={18}
                    >
                      <MyMarker key={1} lat={lat} lng={lng} text={1} tooltip="Propiedad" />
                    </GoogleMapReact>
                  </div>
                  :
                  <div className="property-news-single-card"><iframe src={`https://maps.google.com/maps?width=100%&height=100%&hl=es&q=${encodeURI(`${colonia}, ${ciudad}, ${estado}, México`)}&t=&z=15&ie=UTF8&iwloc=B&output=embed`} style={{ border: 0, height: "500px" }} allowFullScreen /></div>
                }

              </div>
              {servicios.length > 0 &&
                <div className="property-news-single-card border-bottom-yellow">
                  <h4>Servicios</h4>
                  <div className="row">
                    {
                      servicios.map(({ value }, key) => <div className="col-md-3 mb-3" key={key}><ul className="rld-list-style"><li><i className="fa fa-check"></i>{value}</li></ul></div>)
                    }
                  </div>
                </div>
              }
              {espacios.length > 0 &&
                <div className="property-news-single-card border-bottom-yellow">
                  <h4>Espacios</h4>
                  <div className="row">
                    {
                      espacios.map(({ value }, key) => <div className="col-md-3 mb-3" key={key}><ul className="rld-list-style"><li><i className="fa fa-check"></i>{value}</li></ul></div>)
                    }
                  </div>
                </div>
              }
              {amenidades.length > 0 &&
                <div className="property-news-single-card border-bottom-yellow">
                  <h4>Amenidades</h4>
                  <div className="row">
                    {
                      amenidades.map(({ value }, key) => <div className="col-md-3 mb-3" key={key}><ul className="rld-list-style"><li><i className="fa fa-check"></i>{value}</li></ul></div>)
                    }
                  </div>
                </div>
              }
              {videos.length > 0 &&
                <div className="property-news-single-card border-bottom-yellow">
                  <h4>Videos de la propiedad</h4>
                  {
                    videos.map(
                      (value, key) => <div className="thumb" key={key}> <iframe src={value} allowFullScreen /></div>
                    )
                  }
                </div>
              }
              {planos.length > 0 &&
                <div className="property-news-single-card border-bottom-yellow">
                  <h4>Planos de la propiedad</h4>
                  {
                    planos.map(
                      (value, key) => <div className="thumb" key={key}><img src={value} alt="image" /></div>
                    )
                  }
                </div>
              }
              {recorrido.length > 0 &&
                <div className="property-news-single-card border-bottom-yellow mb-0">
                  <h4>Recorrido 360</h4>
                  {
                    recorrido.map(
                      (value, key) => <div className="thumb" key={key}><iframe style={{ width: "100%", height: "640px", border: "none" }} border="0" allowFullScreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" src={value}></iframe></div>
                    )
                  }
                </div>
              }
            </div>
            <div className="col-xl-3 col-lg-4">
              {id === "nuevo" ?
                <div className="widget widget-owner-info mt-lg-0 mt-5">
                  <div className="owner-info text-center">
                    <div className="thumb">
                      <img src={image} alt="image" />
                    </div>
                    <div className="details">
                      <h6>{nombre}</h6>
                      {empresa != null ? <a href={website != null ? website : "#"} target="_blank" className="designation">Website</a> : ``}
                    </div>
                  </div>
                  <div className="contact">
                    <h6>Contáctenos</h6>
                    <form onSubmit={onSubmitEmail} name="form-contacto">
                      <div className="rld-single-input">
                        <input type="text" name="nombre" placeholder="Nombre completo" required />
                      </div>
                      <div className="rld-single-input">
                        <input type="email" name="correo" placeholder="Correo electrónico" required />
                      </div>
                      <div className="rld-single-input">
                        <input type="text" name="mensaje" placeholder="Mensaje" required />
                      </div>
                      <button type="submit" className="btn btn-yellow">Enviar mensajes</button>
                    </form>
                  </div>
                  <div className="contact-info">
                    <h6 className="mb-3">Información de contacto</h6>
                    <div className="media">
                      {direccion !== '' &&
                        <div className="media-body">
                          <p>Dirección</p>
                          <span>{direccion}</span>
                        </div>
                      }
                    </div>
                    {
                      tel !== '' &&
                      <a className="btn btn-yellow btn-block" href={"tel:+52" + tel} onClick={() => handleLikeProperty(id)}>
                        <i className="fa fa-phone mr-2" />
                        Teléfono
                      </a>
                    }
                    {
                      tel !== '' &&
                      <a className="btn btn-orange btn-block" target="_whatsapp" href={"https://api.whatsapp.com/send?phone=" + tel + "&text=Hola, " + nombre + " encontré esta propiedad en Casyter, ¿Sigue disponible? " + encodeURIComponent(`https://www.casyter.com/#/detalle-propiedad/${id}`)} onClick={() => handleLikeProperty(id)}>
                        <i className="fa fa-whatsapp mr-2" />
                        Whatsapp
                      </a>
                    }
                  </div>
                </div>
                :
                <Sticky>
                  {({
                    style,
                    isSticky,
                    wasSticky,
                    distanceFromTop,
                    distanceFromBottom,
                    calculatedHeight
                  }) => (
                    <div className="widget widget-owner-info mt-lg-0 mt-5" style={style} topOffset={80}>
                      <div className="owner-info text-center">
                        <div className="thumb">
                          <img src={image} alt="image" />
                        </div>
                        <div className="details">
                          <h6>{nombre}</h6>
                          {empresa != null ? <a href={website != null ? website : "#"} target="_blank" className="designation">Website</a> : ``}
                        </div>
                      </div>
                      <div className="contact">
                        <h6>Contáctenos</h6>
                        <form onSubmit={onSubmitEmail} name="form-contacto">
                          <div className="rld-single-input">
                            <input type="text" name="nombre" placeholder="Nombre completo" required />
                          </div>
                          <div className="rld-single-input">
                            <input type="email" name="correo" placeholder="Correo electrónico" required />
                          </div>
                          <div className="rld-single-input">
                            <input type="text" name="mensaje" placeholder="Mensaje" required />
                          </div>
                          <button type="submit" className="btn btn-yellow">Enviar mensajes</button>
                        </form>
                      </div>
                      <div className="contact-info">
                        <h6 className="mb-3">Información de contacto</h6>
                        <div className="media">
                          {direccion !== '' &&
                            <div className="media-body">
                              <p>Dirección</p>
                              <span>{direccion}</span>
                            </div>
                          }
                        </div>
                        {
                          tel !== '' &&
                          <a className="btn btn-yellow btn-block" href={"tel:+52" + tel} onClick={() => handleLikeProperty(id)}>
                            <i className="fa fa-phone mr-2" />
                            Teléfono
                          </a>
                        }
                        {
                          tel !== '' &&
                          <a className="btn btn-orange btn-block" target="_whatsapp" href={"https://api.whatsapp.com/send?phone=" + tel + "&text=Hola, " + nombre + " encontré esta propiedad en Casyter, ¿Sigue disponible? " + encodeURIComponent(`https://www.casyter.com/#/detalle-propiedad/${id}`)} onClick={() => handleLikeProperty(id)}>
                            <i className="fa fa-whatsapp mr-2" />
                            Whatsapp
                          </a>
                        }
                      </div>
                    </div>
                  )}
                </Sticky>
              }


            </div>
          </div>
        </div>
      </StickyContainer>
    }

    <ToastContainer style={{ width: "auto", marginTop: "180px" }} />
  </div >

}

export default PropertyDetails
