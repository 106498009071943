import React, { useContext, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import { useForm } from '../../hooks/useForm';

const Registration = () => {
  const init = {
    fullname: '',
    email: '',
    tel: '',
    password: '',
    repeatPassword: ''
  };

  const { signUp } = useContext(AuthContext);

  const { fullname, email, tel, password, repeatPassword, onChange } = useForm(init);

  const [request, setRequest] = useState({ status: false, message: null });

  const onSubmit = async (e) => {
    e.preventDefault();
    const rest = await signUp({ fullname, email, tel, password });
    if (rest.status) {
      window.location = '/'
    } else {
      setRequest({ status: 2, message: rest.message });
    }
  }
  return (
    <>
      <div className="register-page-area pd-bottom-100">
        <div className="container">
          <div className="row justify-content-center">

            <div className="col-xl-5 col-lg-6 col-md-7">
              <form className="contact-form-wrap contact-form-bg" onSubmit={onSubmit}>
                {(() => {
                  switch (request.status) {
                    case 1:
                      return (
                        <div className="alert alert-warning alert-dismissible fade show" role="alert">
                          <strong><i className="fa fa-check"></i></strong> Se ha registrado con éxito
                        </div>
                      );
                    case 2:
                      return (
                        <div className="alert alert-danger alert-dismissible fade show d-flex align-items-center" role="alert">
                          <strong className="pr-2"><i className="fa fa-info-circle"></i></strong> {request.message}
                        </div>
                      );
                    default: return '';
                  }
                })()}
                <h4>Regístrate</h4>
                <div className="rld-single-input">
                  <input type="text" placeholder="Nombre Completo" name="fullname" value={fullname} onChange={(e) => onChange(e.target.name, e.target.value)} />
                </div>
                <div className="rld-single-input">
                  <input type="email" placeholder="Corroe Electronico" name="email" value={email} onChange={(e) => onChange(e.target.name, e.target.value)} />
                </div>
                <div className="rld-single-input">
                  <input type="text" placeholder="Teléfono" name="tel" value={tel} onChange={(e) => onChange(e.target.name, e.target.value)} />
                </div>
                <div className="rld-single-input">
                  <input type="password" placeholder="Contraseña" autoComplete="on" name="password" value={password} onChange={(e) => onChange(e.target.name, e.target.value)} />
                </div>
                <div className="rld-single-input">
                  <input type="password" placeholder="Repita su contraseña" autoComplete="on" name="repeatPassword" value={repeatPassword} onChange={(e) => onChange(e.target.name, e.target.value)} />
                </div>
                <div className="btn-wrap text-center">
                  <button className="btn btn-yellow" type="submit">Iniciar Sesión</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Registration


