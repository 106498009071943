import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import sectiondata from '../../data/sections.json';
import { cribbeeAPI } from '../../api/cribbeeAPI';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
const Banner = () => {
  const [open, setOpen] = useState(false);

  const [propiedades, setPropiedades] = useState([]);

  useEffect(() => {
    const listProperties = async () => {
      try {
        const { data: { data: info } } = await cribbeeAPI.get(`/list-properties`);

        const datos = [];
        for (var index = 0; index < info.length; index++) {
          datos.push(`${info[index].city}, ${info[index].state}`)
        }
        setPropiedades(datos);
      } catch (error) {
        console.error(error)
      }
    }
    listProperties();
  }, []);


  let publicUrl = process.env.PUBLIC_URL + '/'
  let imagealt = 'image'
  let data = sectiondata.banner

  const inlineStyle = {
    backgroundImage: 'url(/assets/img/banner/1.jpg)'
  }


  const filedRef = useRef({});


  const onSubmitFilter = (e) => {
    e.preventDefault();
    let url = `#/propiedades?`;
    const formData = new FormData(document.querySelector(`form[name="form-banner"]`));
    formData.forEach((value, key) => {
      url += `${key}=${value}&`;
    });
    url = url.substr(0, (url.length - 1));
    window.location.href = url;
  }



  return <div className="banner-area jarallax" style={inlineStyle}>
    <div className="container">
      <div className="banner-inner-wrap">
        <div className="row">
          <div className="col-12">
            <br></br>
            <br></br>
            <div className="banner-inner">
              <h5 className="sub-title" style={{ textShadow: "0.5px 0.5px 0.2px #000" }}>{data.subtitle}</h5>
              <h1 className="title" style={{ textShadow: "1px 1px 2px rgb(45, 45, 45, 0.7)" }}>{data.title1} <br /> {data.title2}</h1>
            </div>
          </div>
          <div className="col-12">
            <form onSubmit={onSubmitFilter} name="form-banner">
              <div className="banner-search-wrap">

                <ul className="nav nav-tabs rld-banner-tab">
                  <li className="nav-item"
                    onClick={() => {
                      filedRef.current["tipo_venta"].click();
                    }}
                  >
                    <a className="nav-link active" data-toggle="tab" href="#tabs_1">Venta</a>
                    <input type="radio" className="d-none" value="venta" name="tipo" defaultChecked
                      ref={
                        el => {
                          filedRef.current = {
                            ...filedRef.current,
                            [`tipo_venta`]: el
                          };
                        }
                      }
                    />
                  </li>
                  <li className="nav-item"
                    onClick={() => {
                      filedRef.current["tipo_renta"].click();
                    }}
                  >
                    <a className="nav-link" data-toggle="tab" href="#tabs_2">Renta</a>
                    <input type="radio" className="d-none" value="renta" name="tipo"
                      ref={
                        el => {
                          filedRef.current = {
                            ...filedRef.current,
                            [`tipo_renta`]: el
                          };
                        }
                      }
                    />
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane fade show active">
                    <div className="rld-main-search">
                      {/* <form onSubmit={onSubmit} name="form"> */}

                      <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-4">
                          <div className="rld-single-select" style={{ height: '100%' }}>
                            <select className="select single-select m-0" style={{ minHeight: '100%' }} name="tipo_propiedad">
                              <option value="Casa">Casa</option>
                              <option value="Departamento">Departamento</option>
                              <option value="Terreno">Terreno</option>
                              <option value="Comercial">Comercial</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6">

                          <Autocomplete
                            freeSolo
                            open={open}
                            onClose={() => {
                              setOpen(false);
                            }}
                            disableClearable
                            options={propiedades}
                            onKeyUp={
                              ({ target: { value } }) => {
                                if (value === '') {
                                  setOpen(false);
                                } else {
                                  setOpen(true);
                                }
                              }
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Ingresa tu ubicación"
                                InputProps={{
                                  ...params.InputProps,
                                  type: 'search',
                                  name: "ubicacion"
                                  // ref: {(el) => { fieldRef.current.precio_minimo = el }}
                                }}
                              />
                            )}
                          />
                        </div>
                        {/* <div className="col-xl-2 col-lg-6 col-md-6">
                          <div className="rld-single-input">
                            <input type="text" placeholder="Precio Mínimo" name="precio_minimo" />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-6 col-md-6">
                          <div className="rld-single-input">
                            <input type="text" placeholder="Precio Máximo" name="precio_maximo" />
                          </div>
                        </div> */}
                        <div className="col-xl-3 col-lg-4 col-md-4">
                          <div className="rld-single-select" style={{ height: '100%' }}>
                            <select className="select single-select m-0" style={{ minHeight: '100%' }} name="habitaciones">
                              <option value="todas"># Habitaciones</option>
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5 o más</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-4 readeal-top">
                          {/* <Link className="btn btn-yellow" to="/search-list">BUSCAR</Link> */}
                          <button className="btn btn-yellow" type="submit">BUSCAR</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

}

export default Banner