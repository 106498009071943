import React, { useRef } from 'react'

const Select = (props) => {
    const { options, defaultOption, name, required, message, inputRef, section, onChange, value } = props;

    let { otros } = props;

    const field = useRef(false);

    let items = '';
    if (options.length > 0) {
        items = options.map(({ value: valueOption, title }, index) => { 
            if(value != ''){
                if(value === valueOption){
                    return <option key={index} value={valueOption} selected={true}>{title}</option>
                }else{
                    return <option key={index} value={valueOption}>{title}</option>
                }
            }else{
                return <option key={index} value={valueOption}>{title}</option>
            }
        })
    }


    return (
        <>
            <div className="rld-single-select">
                <select
                    className="select single-select"
                    name={name}
                    onChange={
                        (e) => {
                            onChange(name, Number(e.target.value));
                            field.current && e.target.value !== 0 ? field.current.classList.remove("is-invalid") : field.current.classList.add("is-invalid")
                        }
                    }
                    required={required}
                    {...(otros)}

                    ref={
                        (el) => {
                            inputRef(name, el);
                            field.current = el;
                        }
                    }
                    data-section={section}
                >
                    <option value={0}>{defaultOption}</option>
                    {items}
                </select>
                <div className="invalid-feedback">{message}</div>
            </div>
        </>
    )


}

export default Select;
