import React, { useState, useEffect } from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
import Service from './section-components/service';
import PropiedadesDestacadas from './section-components/propiedades-destacadas';
import Ads from './section-components/ads';
import PropertiesByCities from './section-components/properties-by-cities';
import WhyChooseUs from './section-components/why-choose-us';
import OurPartner from './section-components/our-partner';
import Footer from './global-components/footer';
import Modal from 'react-bootstrap/Modal';

const Home_V1 = () => {
    const [show, setShow] = useState(false);
    const [text, setText] = useState(false);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        checkCookies(2);
    }, []);
    const uuid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    const setCookies = () => {
        let date = new Date();
        date.setDate(date.getDate() + 8);
        document.cookie = `acceptCookies=${uuid()}; expires=${date.toUTCString()}; path=/`;
        // document.cookie = `acceptCookies=${uuid()}; max-age=60; path=/`;
    }
    const getCookies = () => {
        var name = "acceptCookies=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    const checkCookies = (bandera) => {
        const checked = getCookies();
        if (localStorage.getItem('cribbeeToken') != null && checked != '') return;

        switch (bandera) {
            case 2:
                if (checked == '') {
                    setShow(true)
                }
                break;

            case 1:
                setCookies();
                setShow(false)
                break;

            case 0:
                setShow(true)
                break;

            default:
                setShow(true)
                break;
        }

    }


    return (
        <div>
            <Navbar />
            <Banner />
            <Service />

            <PropiedadesDestacadas />
            <Ads />
            <PropertiesByCities />

            <WhyChooseUs />
            <OurPartner />
            <Footer />

            <Modal
                show={show}
                backdrop="static"
                keyboard={true}
                centered
            >
                <div className="card-body" style={{ backgroundColor: "#fbb138" }}>
                    <h4 className="text-center">Aceptación de cookies</h4>
                    <p style={{ color: "black" }}>
                        <b>Cribbee</b> utiliza cookies estrictamente necesarias, así como otras tecnologías similares, para poner en funcionamiento este sitio web y brindarle una mejor experiencia de usuario. Sin embargo, además de las cookies estrictamente necesarias, Cribbee se apoya en las cookies opcionales para diferentes fines, como ofrecerle una navegación personalizada; realizar análisis mediante el conteo de visitas y fuentes de tráfico; mostrar anuncios; e interactuar con terceros.&nbsp;
                        {
                            !text &&
                            <a style={{ fontWeight: "600" }} onClick={() => setText(true)}>
                                Ver más...
                            </a>
                        }
                    </p>

                    <p style={{ color: "black" }} className={text ? "" : "d-none"}>
                        Al dar clic en <b><u>"Aceptar todas las cookies"</u></b> , usted permite la colocación de cookies opcionales en su dispositivo. Usted podrá rechazar las cookies dando clic en el botón "Rechazar cookies"; pero recuerde que, dependiendo de las preferencias de cookies que haya seleccionado, es posible que la funcionalidad completa de este sitio web y la experiencia de usuario personalizada no estén disponibles.
                    </p>
                    <div className="d-flex justify-content-center">
                        <button
                            type="button"
                            onClick={
                                () => {
                                    checkCookies(0);
                                }
                            }
                            className="btn mr-2"
                            style={{ backgroundColor: "gray", color: "white" }}
                        >
                            Rechazar
                        </button>
                        <button
                            type="button"
                            onClick={
                                () => {
                                    checkCookies(1);
                                }
                            }
                            className="btn btn-orange"
                        >
                            Aceptar
                        </button>
                    </div>
                </div>
            </Modal>
            {/* <div className="whatsapp-flotante">
                <a href="" target="_blank">
                    <img src={process.env.PUBLIC_URL + "/assets/img/apps.png"} alt="" width="150px" />
                </a>
            </div> */}
        </div>

    );
}

export default Home_V1


