import React, { useRef, useState } from 'react';

export const useForm = (initState) => {
    const [state, setState] = useState(initState);
    const fieldRef = useRef({});
    const onChange = (field, value) => {
        setState({
            ...state,
            [field]: value
        });
    }

    const onChangeArray = (field, value) => {
        const valor = state[field].findIndex(obj => obj.field === value.field);
        if (valor === -1) {
            state[field].push(value);
            setState({
                ...state,
                [field]: state[field]
            });
        } else {
            const valor = state[field].map(
                (v, i) => {
                    if (v.field === value.field) {
                        return { selected: value.selected, field: value.field, value: field.value };
                    } else {
                        return v;
                    }
                }
            );
            setState({
                ...state,
                [field]: valor
            });
        }

    }

    const assignValue = (field, value) => {
        setState({
            ...state,
            [field]: value
        });
    }

    const onRef = (field, el) => {
        fieldRef.current = {
            ...fieldRef.current,
            [field]: el
        };
    }

    const setFormValue = (form) => {
        setState(form);
    }


    return {
        ...state,
        form: state,
        fieldRef,
        onChange,
        onChangeArray,
        assignValue,
        onRef,
        setFormValue
    }

}