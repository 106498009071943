import React, { useEffect, useState, useRef, useContext } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { CardNumberElement, CardExpiryElement, CardCvcElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';

import GoogleMapReact from 'google-map-react';

import { Link, Redirect } from 'react-router-dom';

import { useForm } from '../../hooks/useForm';

import Select from '../form-components/Select';
import Input from '../form-components/Input';
import Textarea from '../form-components/Textarea';
import Checkbox from '../form-components/Checkbox';
import PropertyDetailsSection from './property-details';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { cribbeeAPI } from '../../api/cribbeeAPI';
import { AuthContext } from "../../auth/AuthContext";

const axios = require('axios').default;

const initStatePropiedad = {
  id: "nuevo",
  imagesPropiedad: [],
  precio: 0,
  externalTitle: "",
  description: "",
  direccion: {
    ocultar: false,
    calle: "",
    colonia: "",
    noExt: "",
    cp: "",
    ciudad: "",
    estado: "",
    lat: 0,
    lng: 0
  },
  vendedor: {
    image: localStorage.getItem('cribbeeImage'),
    nombre: localStorage.getItem('cribbeeFullName'),
    tel: localStorage.getItem('cribbeePhone'),
    email: localStorage.getItem('cribbeeEmail'),
    direccion: "",
  },
  itemsProperty: [],
  servicios: [],
  espacios: [],
  amenidades: [],
  planos: [],
  recorrido: [],
  videos: [],
};

const AddNew = () => {
  useEffect(() => {
    window.scrollTo({
      top: 400,
      behavior: "smooth"
    });
  }, [])


  //Funciones y useState para Wizard
  const initStateWizard = {
    tab1: true,
    item1: true,
    tab2: false,
    item2: false,
    tab3: false,
    item3: false,
    tab4: false,
    item4: false,
    view: false,
    btnPrevious: false,
    btnSubmit: false,
    btnNext: true,
  };

  const [wizard, setWizard] = useState(initStateWizard);
  const { tab1, tab2, tab3, tab4, item1, item2, item3, item4, view, btnPrevious, btnSubmit, btnNext } = wizard;

  const [loading, setLoading] = useState(true)
  // const [loadingHandle, setLoadingHandle] = useState(false)

  const handleLoadingShow = () => {
    const $ = window.$;

    var preLoder = $("#preloader");
    preLoder.removeAttr("style");
    //preLoder.attr("style", "display:none");
    // setLoadingHandle(true);

  }
  const handleLoadingNone = () => {

    const $ = window.$;

    var preLoder = $("#preloader");
    //preLoder.removeAttr("style");
    preLoder.attr("style", "display:none");

  }

  const actionNext = () => {
    const object = fieldRef.current;
    let validationTab = 0;
    if (tab1) {
      for (const property in object) {
        if (object[property] != null) {

          if (object[property].dataset.section == 1) {
            if (object[property].required === true) {

              if (object[property].localName == 'select') {
                if (object[property].value == 0) {
                  object[property].classList.add("is-invalid");
                  if (validationTab == 0) {
                    object[property].focus();
                  }
                  validationTab++;
                } else {
                  object[property].classList.remove("is-invalid");
                }
              } else if (object[property].localName == 'input' || object[property].localName == 'textarea') {
                if (object[property].value == '') {
                  if (validationTab == 0) {
                    object[property].focus();
                  }
                  object[property].classList.add("is-invalid");
                  validationTab++;
                } else {
                  object[property].classList.remove("is-invalid");
                }
              }
            }
          }
        }
      }
      if (validationTab == 0) {
        setWizard({
          ...wizard,
          ["tab1"]: false,
          ["tab2"]: true,
          ["item1"]: false,
          ["item2"]: true,
          ["btnPrevious"]: true,
        });
        window.scrollTo({
          top: 400,
          behavior: "smooth"
        });
      } else {
        toast.warn(`¡Valide que no tenga ningún campo vacío!`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light"
        });
      }
    } else if (tab2) {
      if (files.length == 0) {
        validationTab++;
        setErrorImage(true);

        if (object["inputImage"].value == '') {
          object["inputImage"].focus();
        }
      }
      if (validationTab == 0) {
        setWizard({
          ...wizard,
          ["tab2"]: false,
          ["tab3"]: true,
          ["item2"]: false,
          ["item3"]: true,
        });
        window.scrollTo({
          top: 400,
          behavior: "smooth"
        });
      } else {
        toast.warn(`¡Valide que no tenga ningún campo vacío!`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light"
        });
      }

    } else if (tab3) {
      if (plan < 0 || plan == null && paquete < 0 || paquete == null) {
        toast.warn(`¡No ha seleccionado un Plan o un Paquete!`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light"
        });
      } else {
        let btnSubmitConfirm = false;
        if (showConfirmacion) {
          btnSubmitConfirm = true;
        }

        setWizard({
          ...wizard,
          ["tab3"]: false,
          ["item3"]: false,
          ["tab4"]: true,
          ["item4"]: true,
          ["view"]: true,
          ["btnNext"]: false,
          ["btnSubmit"]: btnSubmitConfirm,
        });
        window.scrollTo({
          top: 600,
          behavior: "smooth"
        });
      }


    }

    setLoading(true);
  }

  const actionPrevious = () => {
    setShowConfirmacion(false);
    if (tab4) {
      setWizard({
        ...initStateWizard,
        ["tab1"]: false,
        ["item1"]: false,
        ["tab3"]: true,
        ["item3"]: true,
        ["btnPrevious"]: true,
      });
    } else if (tab3) {
      setWizard({
        ...wizard,
        ["tab2"]: true,
        ["item2"]: true,
        ["tab3"]: false,
        ["item3"]: false,
      });
    } else if (tab2) {
      setWizard({
        ...wizard,
        ["tab1"]: true,
        ["item1"]: true,
        ["tab2"]: false,
        ["item2"]: false,
        ["btnPrevious"]: false,
      });
    }
    window.scrollTo({
      top: 400,
      behavior: "smooth"
    });
  }

  const actionItem = (item) => {
    setShowConfirmacion(false);
    setLoading(true);
    const object = fieldRef.current;
    let validationTab1 = 0;
    let validationTab2 = 0;
    let validationTab3 = 0;
    for (const property in object) {
      if (object[property] != null) {
        if (object[property].dataset.section == 1) {
          if (object[property].required === true) {

            if (object[property].localName == 'select') {
              if (object[property].value == 0) {
                object[property].classList.add("is-invalid");
                if (validationTab1 == 0) {
                  object[property].focus();
                }
                validationTab1 = 1;
              } else {
                object[property].classList.remove("is-invalid");
              }
            } else if (object[property].localName == 'input' || object[property].localName == 'textarea') {
              if (object[property].value == '') {
                if (validationTab1 == 0) {
                  object[property].focus();
                }
                object[property].classList.add("is-invalid");
                validationTab1 = 1;
              } else {
                object[property].classList.remove("is-invalid");
              }
            }
          }
        }
      }
    }
    if (validationTab1 == 0) {

      if (files.length == 0) {
        validationTab2 = 1;
        setErrorImage(true);

        if (object["inputImage"].value == '') {
          object["inputImage"].focus();
        }
      }
      if (validationTab2 == 0) {
        if (plan < 0 || plan == null && paquete < 0 || paquete == null) {
          validationTab3 = 3;
        }
      }
    }
    if (validationTab1 !== 0) {
      toast.warn("¡Valide que no tenga ningún campo vacío!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light"
      });
      setWizard({
        ...initStateWizard,
      });
    } else if (validationTab2 !== 0) {

      if (item == 1) {
        setWizard({
          ...initStateWizard,
        });
      } else {
        if (item !== 2) {

          toast.warn("¡Valide que no tenga ningún campo vacío!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light"
          });
        }
        setWizard({
          ...initStateWizard,
          ["tab1"]: false,
          ["tab2"]: true,
          ["item1"]: false,
          ["item2"]: true,
          ["btnPrevious"]: true,
        });
        window.scrollTo({
          top: 400,
          behavior: "smooth"
        });
      }

    } else if (validationTab3 !== 0) {
      if (item == 1) {
        setWizard({
          ...initStateWizard,
        });
      } else if (item == 2) {
        setWizard({
          ...initStateWizard,
          ["tab1"]: false,
          ["tab2"]: true,
          ["item1"]: false,
          ["item2"]: true,
          ["btnPrevious"]: true,
        });
        window.scrollTo({
          top: 400,
          behavior: "smooth"
        });
      } else {
        if (item !== 3) {
          toast.warn("¡No ha seleccionado un Plan o un Paquete!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light"
          });
        }
        setWizard({
          ...initStateWizard,
          ["tab1"]: false,
          ["tab3"]: true,
          ["item1"]: false,
          ["item3"]: true,
          ["btnPrevious"]: true,
        });
        window.scrollTo({
          top: 400,
          behavior: "smooth"
        });
      }
    }

    if (validationTab1 == 0 && validationTab2 == 0 && validationTab3 == 0) {
      if (item == 1) {
        setWizard({
          ...initStateWizard,
        });
        window.scrollTo({
          top: 400,
          behavior: "smooth"
        });
      } else if (item == 2) {
        setWizard({
          ...initStateWizard,
          ["tab1"]: false,
          ["tab2"]: true,
          ["item1"]: false,
          ["item2"]: true,
          ["btnPrevious"]: true,
        });
        window.scrollTo({
          top: 400,
          behavior: "smooth"
        });
      } else if (item == 3) {
        setWizard({
          ...initStateWizard,
          ["tab1"]: false,
          ["tab3"]: true,
          ["item1"]: false,
          ["item3"]: true,
          ["btnPrevious"]: true,
        });
        window.scrollTo({
          top: 400,
          behavior: "smooth"
        });
      } else if (item == 4) {
        let btnSubmitConfirm = false;
        if (showConfirmacion) {
          btnSubmitConfirm = true;
        }
        setWizard({
          ...initStateWizard,
          ["tab1"]: false,
          ["tab4"]: true,
          ["item1"]: false,
          ["item4"]: true,
          ["view"]: true,
          ["btnPrevious"]: true,
          ["btnSubmit"]: btnSubmitConfirm,
          ["btnNext"]: false,
        });
        window.scrollTo({
          top: 600,
          behavior: "smooth"
        });

      }
    }

  }

  const [previewProperty, setPreviewProperty] = useState(initStatePropiedad)
  const handlePreviewProperty = () => {
    const filesPreview = files.sort((a, b) => {
      if (a.principal < b.principal) return 1;
      if (a.principal > b.principal) return -1;

      return 0;
    });
    const arrayPreview = [];
    filesPreview.forEach(({ imagen, file, name, principal }) => {
      arrayPreview.push(imagen);
    });

    const itemsProperty = [];

    bedRoom !== '' && itemsProperty.push({ icon: "fa fa-check", value: bedRoom, title: "Recámaras" });
    bathRoom !== '' && itemsProperty.push({ icon: "fa fa-check", value: bathRoom, title: "Baños" });
    halfBath !== '' && itemsProperty.push({ icon: "fa fa-check", value: halfBath, title: "1/2 Baños" });
    squareMetersBuild !== '' && itemsProperty.push({ icon: "fa fa-check", value: squareMetersBuild, title: "M2 Construcción" });
    squareMetersLand !== '' && itemsProperty.push({ icon: "fa fa-check", value: squareMetersLand, title: "M2 Terreno" });
    m2Deep !== '' && itemsProperty.push({ icon: "fa fa-check", value: m2Deep, title: "M2 Fondo" });
    m2Front !== '' && itemsProperty.push({ icon: "fa fa-check", value: m2Front, title: "M2 Frente" });
    parking !== '' && itemsProperty.push({ icon: "fa fa-check", value: parking, title: "Estacionamiento" });
    floor !== '' && itemsProperty.push({ icon: "fa fa-check", value: floor, title: "No. de plantas" });
    maintenance !== '' && itemsProperty.push({ icon: "fa fa-check", value: maintenance, title: "Mantenimiento" });

    if (regularIrregular !== 0) {
      const valueRegularIrregular = regularIrregular == 1 ? "Regular" : "Irregular";
      itemsProperty.push({ icon: "fa fa-check", value: valueRegularIrregular, title: "Regular o Irregular" });
    }
    if (antiquity !== 0) {
      const valueAntiquity = antiquity == 1 ? "A estrenar" : numYear !== '' ? `numYear Años` : "0 Años";
      itemsProperty.push({ icon: "fa fa-check", value: valueAntiquity, title: "Antigüedad" });
    }
    const servicesProperty = [];
    if (services.length > 0) {
      services.forEach(({ selected, value }) => {
        if (selected) {
          servicesProperty.push({ value: value });
        }
      });
    }
    const spacesProperty = [];
    if (spaces.length > 0) {
      spaces.forEach(({ selected, value }) => {
        if (selected) {
          spacesProperty.push({ value: value });
        }
      });
    }
    const amenitiesProperty = [];
    if (amenities.length > 0) {
      amenities.forEach(({ selected, value }) => {
        if (selected) {
          amenitiesProperty.push({ value: value });
        }
      });
    }
    const arrayPlansPreview = [];
    plans.forEach(({ imagen, file, name, principal }) => {
      arrayPlansPreview.push(imagen);
    });
    assignValue("coords", {
      lat: coordsMaps.lat,
      lng: coordsMaps.lng
    })
    setPreviewProperty({
      id: "nuevo",
      imagesPropiedad: arrayPreview,
      externalTitle: externalTitle,
      precio: price,
      description: description,
      direccion: {
        ocultar: hideAddress,
        calle: street,
        colonia: colony,
        noExt: externalNumber,
        cp: cp,
        ciudad: city,
        estado: state,
        lat: coordsMaps.lat,
        lng: coordsMaps.lng
      },
      vendedor: {
        image: localStorage.getItem('cribbeeImage'),
        nombre: localStorage.getItem('cribbeeFullName'),
        tel: localStorage.getItem('cribbeePhone'),
        email: localStorage.getItem('cribbeeEmail'),
        direccion: "",
      },
      itemsProperty: itemsProperty,
      servicios: servicesProperty,
      espacios: spacesProperty,
      amenidades: amenitiesProperty,
      planos: arrayPlansPreview,
      recorrido: travels,
      videos: urls,
    });
  }



  //Validacion de informaciones
  const initialState = {
    type_of_sale: "sell", //type_of_sale  Tipo Renta o Venta
    externalTitle: "", //title  Titulo Exterior
    internalTitle: "", //subtitle Titulo Exterior
    price: "", //price Precio
    propertyType: 0, //property_type Tipo Propiedad
    subTypeProperty: 0, //idSubtypeProperty Tipo Propiedad
    bedRoom: "", //rooms
    bathRoom: "", // bathRoom
    halfBath: "", // halfBath
    squareMetersBuild: "", // square_meters_build
    squareMetersLand: "", // square_meters_land
    m2Deep: "", // m2Deep
    m2Front: "", // m2Front
    parking: "", // parking_lots
    floor: "", //floor
    maintenance: "", // maintenance
    regularIrregular: 0, //regularIrregular
    antiquity: 0, //antiquity
    numYear: "", //numYear
    description: "", //description
    services: [], // services
    spaces: [], // spaces
    amenities: [], // amenities
    street: "",  // street
    colony: "",  // colony
    externalNumber: "",  // externalNumber
    cp: "",  // cp
    city: "",  // city
    state: "",  // state
    hideAddress: false, // hideAddress
    coords: {
      lat: 0, // latitude
      lng: 0 // longitude
    },
    files: [], //property_resources
    urls: [], //Videos
    plans: [], //plans
    travels: [], //travels
    planes: {
      plan: null, //plan
      paquete: null //plan_days
    },
  };

  const {
    type_of_sale, externalTitle, internalTitle, price, propertyType, subTypeProperty, bedRoom, bathRoom, halfBath, squareMetersBuild, squareMetersLand, m2Deep, m2Front, parking, floor, maintenance, regularIrregular, antiquity, numYear, description,
    services,
    spaces,
    amenities,
    street, colony, externalNumber, cp, city, state, hideAddress,
    coords: { lat: coordsLat, lng: coordsLng },
    files, urls, plans, travels,
    planes: { plan, paquete },
    fieldRef, onChange, onChangeArray, assignValue, onRef, form } = useForm(initialState);


  //Funciones y useState para Componente Informacion de Propiedad
  const initStatePI = {
    btnSale: true,
    btnIncome: false,
  };
  const [propertyInformation, setPropertyInformation] = useState(initStatePI);
  const { btnSale, btnIncome } = propertyInformation;

  const actionSale = () => {
    console.log("renta");
    assignValue("type_of_sale", "rent");
    setPropertyInformation({
      ...propertyInformation,
      ["btnSale"]: false,
      ["btnIncome"]: true,
    });
  }

  const actionIncome = () => {
    assignValue("type_of_sale", "sell");
    setPropertyInformation({
      ...propertyInformation,
      ["btnSale"]: true,
      ["btnIncome"]: false,
    });
  }

  //Funcion para mostrar los Substitutos de la propiedad
  const initStatePropertyType = [
    { value: 1, title: "Casa" },
    { value: 2, title: "Departamento" },
    { value: 3, title: "Terreno" },
    { value: 4, title: "Comercial" },
  ];

  const [subtypeProperty, setSubtypeProperty] = useState([]);
  const initStateProperty = {
    house: true,
    department: true,
    terrain: true,
    commercial: true
  };
  const [{ house, department, terrain, commercial }, setHideProperty] = useState(initStateProperty);
  useEffect(() => {
    if (propertyType != 0) {
      assignValue("bedRoom", "")
      assignValue("bathRoom", "")
      assignValue("halfBath", "")
      assignValue("squareMetersBuild", "")
      assignValue("squareMetersLand", "")
      assignValue("m2Deep", "")
      assignValue("m2Front", "")
      assignValue("parking", "")
      assignValue("floor", "")
      assignValue("maintenance", "")
      assignValue("regularIrregular", 0)
      assignValue("antiquity", 0)
      assignValue("numYear", "")
    }
    switch (propertyType) {
      case 1:
        setSubtypeProperty([
          { value: 1, title: "Casa" },
          { value: 2, title: "Casa de playa" },
          { value: 3, title: "Rancho" },
          { value: 4, title: "Quinta" },
          { value: 5, title: "Casa compartida" },
          { value: 6, title: "Desarrollo horizontal" },
          { value: 7, title: "Casa dentro de fraccionamiento" }
        ]);
        setHideProperty({
          ...initStateProperty,
          ["house"]: false,
        })
        setHideAntiquity(true);


        break;
      case 2:
        setSubtypeProperty([
          { value: 1, title: "Departamento" },
          { value: 2, title: "Condominio" },
          { value: 3, title: "Studio" },
          { value: 4, title: "Loft" },
          { value: 5, title: "Departamento compartido" },
          { value: 6, title: "Desarrollo vertical" },
        ]);
        setHideProperty({
          ...initStateProperty,
          ["department"]: false,
        })
        setHideAntiquity(true);
        break;
      case 3:
        setSubtypeProperty([
          { value: 1, title: "Terreno" },
          { value: 2, title: "Terreno industrial" },
          { value: 3, title: "Terreno comercial" },
        ]);
        setHideProperty({
          ...initStateProperty,
          ["terrain"]: false,
        })
        setHideAntiquity(true);
        break;
      case 4:
        setSubtypeProperty([
          { value: 1, title: "Comercial" },
          { value: 2, title: "Bodega industrial" },
          { value: 3, title: "Local comercial" },
          { value: 4, title: "Nave industrial" },
          { value: 5, title: "Oficina" },
          { value: 6, title: "Local en plaza comercial" },
        ]);
        setHideProperty({
          ...initStateProperty,
          ["commercial"]: false,
        })
        setHideAntiquity(true);
        break;

      default:
        setSubtypeProperty([]);
        setHideProperty({
          ...initStateProperty,
        })
        setHideAntiquity(true);
        break;
    }


  }, [propertyType])

  //Mostrar Antigüedad
  const [hideAntiquity, setHideAntiquity] = useState(true);

  useEffect(() => {
    if (antiquity == 2) {
      setHideAntiquity(false)
    } else {
      setHideAntiquity(true)
    }
  }, [antiquity])

  //Botones de ver mas 
  const [buttonText, setButtonText] = useState(true);
  const [buttonText2, setButtonText2] = useState(true);
  const [buttonText3, setButtonText3] = useState(true);


  //Google Maps 

  const initStateMapsCoords = { lat: 0, lng: 0, center: { lat: 24.2828918, lng: -100.8766044, zoom: 5 } };
  const [coordsMaps, setCoordsMaps] = useState(initStateMapsCoords);
  // Dirección desestructurada
  const [mapsAddress, setMapsAddress] = useState({});

  const getCoordsAddress = async (street, colony, externalNumber, cp, city, state) => {
    try {
      if (colony == '' || cp == '' || city == '' || state == '') {
        return;
      }
      const req = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(`${street} ${externalNumber},${colony},${cp} ${city} ${state},Mexico`)}&key=AIzaSyDjl4Ux0-5hYmLEVMdhB63ldRYCeIqcx7A`);

      const { status, results } = req.data;
      if (status == "OK") {

        setMapsAddress({
          calleGoogle: results[0].address_components[1].long_name,
          numExteriorGoogle: results[0].address_components[0].long_name,
          coloniaGoogle: results[0].address_components[2].long_name,
          cogidoPostalGoogle: results[0].address_components[6].long_name,
          ciudadGoogle: results[0].address_components[3].long_name,
          estadoGoogle: results[0].address_components[4].long_name,
          paisGoogle: results[0].address_components[5].long_name,
          addressCompleteGoogle: results[0].formatted_address,
        });
        setCoordsMaps({
          lat: results[0].geometry.location.lat,
          lng: results[0].geometry.location.lng,
          center: {
            lat: results[0].geometry.location.lat,
            lng: results[0].geometry.location.lng,
            zoom: 18
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getCoordsAddress(street, colony, externalNumber, cp, city, state);
  }, [street, colony, externalNumber, cp, city, state]);


  const MyMarker = () => (<><img src={process.env.PUBLIC_URL + "/assets/img/icon.png"} width={20} style={{ marginLeft: "-12px", marginTop: "-10px" }} /></>);

  //Multimedia
  const [ImageSelectedPrevious, setImageSelectedPrevious] = useState([]);
  const [arrayFiles, setArrayFiles] = useState([]);
  const [errorImage, setErrorImage] = useState(false);
  const fieldButtonDeleteImage = useRef({});

  const encoded = (file) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        resolve({ base64: e.target.result, name: file.name });
      }
      reader.readAsDataURL(file);
    })
  }

  const postHiecToBase64 = async (file) => {
    try {
      const form_data = new FormData();
      form_data.append("file", file);
      const { data: { data } } = await cribbeeAPI.post('/upload-heic-base64', form_data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  const changeImage = async ({ target }) => {
    try {

      let array = [];
      const files = target.files;
      for (var i = 0; i < files.length; i++) {
        const file = files[i];
        const principal = i == 0 ? true : false;
        let imgBase64 = '';
        if (file.name.indexOf(".heic") != -1) {
          const datos = await postHiecToBase64(file);

          imgBase64 = datos;
        } else {
          const { base64: image } = await encoded(file);
          imgBase64 = image;
        }

        array.push({ imagen: imgBase64, name: file.name, principal: principal });
      }
      setArrayFiles(array);
      setErrorImage(false);

      target.value = null;
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteImage = (index) => {

    if (Object.keys(fieldButtonDeleteImage.current).length !== 0) {
      for (const property in fieldButtonDeleteImage.current) {
        if (fieldButtonDeleteImage.current[property] !== null) {
          fieldButtonDeleteImage.current[property].checked = false;
        }
      }
    }

    // console.log(fieldButtonDeleteImage);
    ImageSelectedPrevious.splice(index, 1);
    ImageSelectedPrevious.filter(function () { return true; });
    setImageSelectedPrevious([...ImageSelectedPrevious]);
    assignValue("files", [...ImageSelectedPrevious]);
    setArrayFiles([]);
    const valIndexIS = ImageSelectedPrevious.indexOf(x => x.principal === true);
    if (valIndexIS === -1) {
      if (ImageSelectedPrevious.length > 0) {
        ImageSelectedPrevious[0].principal = true;
      }
    }
  }

  const handlePrincipalImage = (e, index) => {
    ImageSelectedPrevious.forEach((v, i) => {
      if (i === index) {
        ImageSelectedPrevious[i] = { imagen: ImageSelectedPrevious[i].imagen, file: ImageSelectedPrevious[i].file, name: ImageSelectedPrevious[i].name, principal: e.target.checked };
      } else {
        ImageSelectedPrevious[i] = { imagen: ImageSelectedPrevious[i].imagen, file: ImageSelectedPrevious[i].file, name: ImageSelectedPrevious[i].name, principal: false };
      }

    });
    setImageSelectedPrevious(ImageSelectedPrevious);
    assignValue("files", ImageSelectedPrevious);
    setArrayFiles([]);
  }

  const srcImg = ImageSelectedPrevious.map(
    ({ imagen, file, name, principal }, index) => <div className="col-md-4 mb-2" key={index}>
      <div className="p-2" style={{ border: `1px dashed #${principal ? "FBB138" : "CD7920"}` }}>
        <div className="d-flex align-items-center justify-content-center">
          <img src={imagen} className="img-fluid" style={{ height: "200px", width: "200px", objectFit: "cover" }} />
        </div>
        <p className="text-center">
          {name.slice(0, 20) + (name.length > 20 ? "[...]" : "")}
        </p>
        <hr style={{ borderTop: `1px dashed #${principal ? "FBB138" : "CD7920"}` }} />
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-md-8">
            <label className="content-input" >
              <input type="radio" name="mainImage" id={`mainImage${index}`} onClick={e => handlePrincipalImage(e, index)}
                ref={
                  el => {
                    fieldButtonDeleteImage.current = {
                      ...fieldButtonDeleteImage.current,
                      [`mainImage${index}`]: el
                    };
                    if (principal) {
                      if (el !== null) {
                        el.checked = true;
                      }
                    }
                  }
                }
              />
              <span className="checkmark-radio" style={{ height: "20px", width: "20px", top: "3px" }}></span>
              <span className="ml-4">Foto principal</span>
            </label>

          </div>
          <div className="col-md-3">
            <button type="button" className="btn btn-sm text-danger p-1" style={{ height: "30px", lineHeight: "100%", width: "30px", background: "#dc354526", borderRadius: "5px" }} onClick={e => handleDeleteImage(index)}><i className="fa fa-times"></i></button>
          </div>
        </div>
      </div>
    </div>
  )

  //Planos

  const [plansSelectedPrevious, setPlansSelectedPrevious] = useState([])
  const [arrayFilesPlanos, setArrayFilesPlanos] = useState([])

  const changePlanos = async ({ target }) => {
    try {
      let array = [];
      const files = target.files;
      for (var i = 0; i < files.length; i++) {
        const file = files[i];
        const principal = i == 0 ? true : false;
        const { base64: image } = await encoded(file);

        array.push({ imagen: image, name: file.name, principal: principal });
      }
      setArrayFilesPlanos(array);
      target.value = null;
    } catch (error) {
      console.error(error)
    }
  }

  const handleAddPlan = () => {
    (arrayFilesPlanos.length > 0) && setPlansSelectedPrevious([...plansSelectedPrevious, ...arrayFilesPlanos]);
    assignValue("plans", [...plansSelectedPrevious, ...arrayFilesPlanos]);
    setArrayFilesPlanos([])
  }

  const handleDeletePlanos = (index) => {
    plansSelectedPrevious.splice(index, 1);
    setPlansSelectedPrevious([...plansSelectedPrevious]);
    assignValue("plans", [...plansSelectedPrevious]);
    setArrayFilesPlanos([]);
  }

  const handlePrincipalPlanos = (e, index) => {
    plansSelectedPrevious.forEach((v, i) => {
      if (i === index) {
        plansSelectedPrevious[i] = { imagen: plansSelectedPrevious[i].imagen, file: plansSelectedPrevious[i].file, name: plansSelectedPrevious[i].name, principal: e.target.checked };
      } else {
        plansSelectedPrevious[i] = { imagen: plansSelectedPrevious[i].imagen, file: plansSelectedPrevious[i].file, name: plansSelectedPrevious[i].name, principal: false };
      }

    });
    setPlansSelectedPrevious(plansSelectedPrevious);
    assignValue("plans", plansSelectedPrevious);
    setArrayFilesPlanos([]);
  }

  const plansDiv = plansSelectedPrevious.map(
    ({ imagen, file, name, principal }, index) => <div className="col-md-4 mb-2" key={index}>
      <div className="p-2" style={{ border: `1px dashed #${principal ? "CD7920" : "FBB138"}` }}>
        <div className="d-flex align-items-center justify-content-center">
          <img src={imagen} className="img-fluid" style={{ height: "200px", width: "200px", objectFit: "cover" }} />
        </div>
        <p className="text-center">
          {name.slice(0, 20) + (name.length > 20 ? "[...]" : "")}
        </p>
        <hr style={{ borderTop: `1px dashed #${principal ? "CD7920" : "FBB138"}` }} />
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-md-8">
            <div className="form-check ">
              <input className="form-check-input" type="radio" name="mainPlans" id={`mainPlans${index}`} defaultChecked={principal} onClick={e => handlePrincipalPlanos(e, index)} />
              <label className="form-check-label" htmlFor={`mainPlans${index}`}>
                Foto principal
              </label>
            </div>
          </div>
          <div className="col-md-3">
            <button type="button" className="btn btn-sm text-danger p-1" style={{ height: "30px", lineHeight: "100%", width: "30px", background: "#dc354526", borderRadius: "5px" }} onClick={e => handleDeletePlanos(index)}><i className="fa fa-times"></i></button>
          </div>
        </div>
      </div>
    </div>
  )

  //Videos
  const [inputURL, setInputURL] = useState("");
  const [arrayInputURL, setArrayInputURL] = useState([]);

  const ValidationURL = () => {
    var pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    if (pattern.test(inputURL)) {
      if (inputURL.includes('https://www.youtube.com/watch?v=')) {
        setArrayInputURL([...arrayInputURL, `https://www.youtube.com/embed/${inputURL.substr(32)}`]);
        assignValue("urls", [...arrayInputURL, `https://www.youtube.com/embed/${inputURL.substr(32)}`]);
      } else {
        setArrayInputURL([...arrayInputURL, inputURL]);
        assignValue("urls", [...arrayInputURL, inputURL]);
      }
      setInputURL("");
    }
  }

  const handleDeleteURL = (index) => {
    arrayInputURL.splice(index, 1);
    setArrayInputURL([...arrayInputURL]);
    assignValue("urls", [...arrayInputURL]);
  }

  //Videos
  const [inputRecorrido, setInputRecorrido] = useState("");
  const [arrayInputRecorrido, setArrayInputRecorrido] = useState([]);
  const [errorURLRecorrido, setErrorURLRecorrido] = useState(false);

  const ValidationRecorrido = () => {
    var pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    if (pattern.test(inputRecorrido)) {
      if (inputRecorrido.includes('https://kuula.co/share/collection/')) {
        setArrayInputRecorrido([...arrayInputRecorrido, inputRecorrido]);
        assignValue("travels", [...arrayInputRecorrido, inputRecorrido]);
        setInputRecorrido("");
        setErrorURLRecorrido(false);
      } else {
        setErrorURLRecorrido(true);
      }
    } else {
      setErrorURLRecorrido(true);
    }
  }

  const handleDeleteRecorrido = (index) => {
    arrayInputRecorrido.splice(index, 1);
    setArrayInputRecorrido([...arrayInputRecorrido]);
    assignValue("travels", [...arrayInputRecorrido]);
  }

  //Planes
  const arrayPriceTable = [
    {
      id: 1,
      plan: "ORO",
      color: "#FFB71A",
      precio: 94.82,
      beneficios: ["· Posicionamiento Alto", "· Propiedad recomendada en búsquedas", "· Links para website", "· Rotación de propiedades en pagina de inicio"],
      url: "assets/img/oro.png",
      paquetes: [
        {
          id: 1,
          color: "#F3F6F9",
          precio: 189.65,
          tiempo: "2 MESES",
        },
        {
          id: 2,
          color: "#FFFFFF",
          precio: 379.31,
          tiempo: "4 MESES",
        },
        {
          id: 3,
          color: "#F3F6F9",
          precio: 568.96,
          tiempo: "6 MESES",
        },
      ]
    },
    {
      id: 2,
      plan: "PLATA",
      color: "#b7b7b7",
      precio: 64.65,
      beneficios: ["· Posicionamiento Medio", "· Propiedad recomendada en búsquedas", "· Links para website"],
      url: "assets/img/plata.png",
      paquetes: [
        {
          id: 4,
          color: "#F3F6F9",
          precio: 129.31,
          // precio: 12,
          tiempo: "2 MESES",
        },
        {
          id: 5,
          color: "#FFFFFF",
          precio: 258.62,
          tiempo: "4 MESES",
        },
        {
          id: 6,
          color: "#F3F6F9",
          precio: 387.93,
          tiempo: "6 MESES",
        },
      ]
    },
    {
      id: 3,
      plan: "MIEL",
      color: "#CD7920",
      precio: "Gratis",
      beneficios: ["· Posicionamiento Mínimo"],
      url: "assets/img/miel.png",
      paquetes: [
        {
          id: 7,
          color: "#F3F6F9",
          precio: "Gratis",
          tiempo: "2 MESES",
        },
        {
          id: 8,
          color: "#FFFFFF",
          precio: "Gratis",
          tiempo: "4 MESES",
        },
        {
          id: 9,
          color: "#F3F6F9",
          precio: "Gratis",
          tiempo: "6 MESES",
        },
      ]
    }
  ];

  const [planes, setPlanes] = useState("");
  const [infoPaquetes, setInfoPaquetes] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");

  const fieldPaquetes = useRef({});
  const buttonRegistro = useRef(false);

  const handlePlanes = (plan, index) => {
    document.getElementById("contentPaquetes").scrollIntoView({ block: "center", behavior: "smooth" });
    if (Object.keys(fieldPaquetes.current).length !== 0) {
      for (const property in fieldPaquetes.current) {
        fieldPaquetes.current[property].checked = false;
      }
    }
    assignValue("planes", { plan: plan, paquete: '' })
    setPlanes(plan)
    setInfoPaquetes(arrayPriceTable[index]);
    assignValue("planes", {
      plan: plan,
      paquete: paquete
    })

  }


  const handleSelectedPackage = (id, index) => {
    document.getElementById(`radio${index}`).click();
    setSelectedPackage(arrayPriceTable.map((p) => { if (p.id == infoPaquetes.id) return p.paquetes[index]; }).filter((valor) => { if (valor !== undefined) return valor })[0])
    assignValue("planes", {
      plan: plan,
      paquete: id
    })
  }

  //Vista Previa del la publicacion de la Propiedad y Realizar tu pago
  const [showConfirmacion, setShowConfirmacion] = useState(false)

  if (view && loading) {
    setTimeout(() => {
      setLoading(false);
      handlePreviewProperty()
    }, 3000);
  }

  //Registrar información

  const [factura, setFactura] = useState(false);
  const [dataRegimen, setDataRegimen] = useState([]);
  useEffect(() => {
    const listProperties = async () => {
      try {
        const { data: { data: info } } = await cribbeeAPI.get(`/list-regimen`);
        setDataRegimen(info.map(({ clave, regimen }) => { return { value: clave, title: regimen } }));
      } catch (error) {
        console.error(error)
      }
    }
    listProperties();
  }, []);

  const initStateRegistro = {
    companyName: '',
    rfc: '',
    regimen: '',

    taxStatus: '',
    areaCode: '',
    email: '',
    tel: '',
    cardholder: '',
    CardNumber: '',
    expiration: '',
    securityCode: '',
    termsAndConditions: true,
    stripeID: '',
  };

  const [errorTermsAndConditions, setErrorTermsAndConditions] = useState(false);

  const {
    companyName, taxStatus, rfc, areaCode, regimen, email, tel, cardholder, CardNumber, expiration, securityCode, termsAndConditions, stripeID,
    fieldRef: fieldRefLR, onChange: onchangeLR, onChangeArray: onChangeArrayLR, assignValue: assignValueLR, onRef: onRefLR, form: formLR, setFormValue: setFormValueLR } = useForm(initStateRegistro);

  const handleSubmit = async () => {

    try {


      let validationTab = 0;
      if (!termsAndConditions) {
        setErrorTermsAndConditions(true);
        validationTab++;
      }
      if (typeof selectedPackage.precio !== 'string') {
        const object = fieldRefLR.current;
        for (const property in object) {
          if (object[property] != null) {

            if (object[property].dataset.section == 4) {
              if (object[property].required === true) {
                if (object[property].localName == 'input') {
                  if (object[property].value == '') {
                    if (validationTab == 0) {
                      object[property].focus();
                    }
                    object[property].classList.add("is-invalid");
                    validationTab++;
                  } else {
                    object[property].classList.remove("is-invalid");
                  }
                }
              }
            }
          }
        }
      }
      if (validationTab != 0) return { status: false, info: "¡Valide que no tenga ningún campo vacío!" };

      let endSubscription = "";

      if (selectedPackage.tiempo === "2 MESES") {
        endSubscription = currentDateBD(2);
      } else if (selectedPackage.tiempo === "4 MESES") {
        endSubscription = currentDateBD(4);
      } else if (selectedPackage.tiempo === "6 MESES") {
        endSubscription = currentDateBD(6);
      }
      handleLoadingShow();
      // console.log(formLR)
      // check this to work with cribbee
      const { data: { data } } = await cribbeeAPI.post('/add-properties',
        {
          currentDate: currentDateBD(),
          endSubscription: endSubscription,
          dataProperty: form,
          dataAddressProperty: mapsAddress,
          factura: factura,
          paymentMethod: null
        }
      );
      handleLoadingNone();
      setTimeout(() => window.location.href = "#/mi-cuenta?s=property", 4000);
      toast.success(`¡Se esta procesando tu publicación sera revisada en un máximo de 24Hrs.!`, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light"
      });

    } catch (error) {
      handleLoadingNone();
      // console.log(error);
      // buttonRegistro.current.disabled = false;
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light"
      });
      // reject(error.response.data.message);
    }
  }


  const handleLoading = () => {

    const resolveWithSomeData = new Promise((resolve, reject) => setTimeout(async () => {
      let { status, message } = await handleSubmit();
      if (status) {
        resolve(message)
      } else {
        reject(message)
      }
    }, 1000));
    toast.promise(
      resolveWithSomeData,
      {
        pending: {
          render() {

            return "Registrando tu propiedad...";
          },
          icon: true,
        },
        success: {
          render({ data }) {

            setTimeout(() => window.location.href = "#/mi-cuenta", 3000)
            return "Se esta procesando tu publicación sera revisada en un máximo de 24Hrs."
          },
          icon: true,
        },
        error: {
          render({ data }) {
            // buttonRegistro.current.disabled = false;
            return data
          }
        }
      }
    )


  }

  const CheckoutForm = (props) => {
    const { inputRef } = props;
    const stripe = useStripe();
    const elements = useElements();

    const [cardComplete, setCardComplete] = useState({
      cardNumber: false, cardExpiry: false, cardCvc: false
    })

    function handleCardElementOnChange(e) {
      setCardComplete({ ...cardComplete, [e.elementType]: e.complete })
    }
    const handleSubmitStripe = async (event) => {
      try {

        let validationTab = 0;
        if (!termsAndConditions) {
          setErrorTermsAndConditions(true);
          validationTab++;
        }
        if (typeof selectedPackage.precio !== 'string') {
          const object = fieldRefLR.current;
          for (const property in object) {
            if (object[property] != null) {

              if (object[property].dataset.section == 4) {
                if (object[property].required === true) {
                  if (object[property].localName == 'input') {
                    if (object[property].value == '') {
                      if (validationTab == 0) {
                        object[property].focus();
                      }
                      object[property].classList.add("is-invalid");
                      validationTab++;
                    } else {
                      object[property].classList.remove("is-invalid");
                    }
                  }
                }
              }
            }
          }
        }
        if (validationTab != 0) return;

        const { cardNumber, cardExpiry, cardCvc } = cardComplete;
        if (!cardNumber || !cardExpiry || !cardCvc) {
          throw new Error("Valide que la información de pago este completa")
        }

        if (elements == null) {
          throw new Error("Valide que la información de pago este completa")
        }
        const totalPrecio = (parseFloat(parseFloat(selectedPackage.precio) * 1.16).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace(/,/g, "") * 100).toFixed(0);
        handleLoadingShow();
        const cards = elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);
        //obtener el Cliente Secret
        const { data: { data: clientSecret } } = await cribbeeAPI.post('/payments/payment-intents',
          {
            precio: totalPrecio,
          }
        );

        const paymentMethodReq = await stripe.createPaymentMethod({
          type: 'card',
          card: cards,
        });


        const confirmedCardPayment = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethodReq.paymentMethod.id
        });

        const { paymentIntent: { id: idStripe } } = confirmedCardPayment;


        let endSubscription = "";

        if (selectedPackage.tiempo === "2 MESES") {
          endSubscription = currentDateBD(2);
        } else if (selectedPackage.tiempo === "4 MESES") {
          endSubscription = currentDateBD(4);
        } else if (selectedPackage.tiempo === "6 MESES") {
          endSubscription = currentDateBD(6);
        }
        // return;
        await cribbeeAPI.post('/add-properties',
          {
            currentDate: currentDateBD(),
            endSubscription: endSubscription,
            dataProperty: form,
            dataAddressProperty: mapsAddress,
            paymentMethod: { otros: formLR, idStripe: idStripe },
            factura: false,
          }
        );
        handleLoadingNone();
        setTimeout(() => window.location.href = "#/mi-cuenta?s=property", 4000);
        toast.success(`¡Tu publicación ha sido registrada exitosamente!`, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light"
        });

      } catch (error) {
        handleLoadingNone();

        // buttonRegistro.current.disabled = false;
        toast.error(error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light"
        });
        console.log(error);
      }
    };


    const handleLoadingStripe = () => {

      const resolveWithSomeData = new Promise((resolve, reject) => setTimeout(async () => {

        let { status, message } = await handleSubmitStripe();
        if (status) {
          resolve(message)
        } else {
          reject(message)
        }
      }, 1000));
      toast.promise(
        resolveWithSomeData,
        {
          pending: {
            render() {

              return "Registrando tu propiedad, puede tardar algunos minutos...";
            },
            icon: true,
          },
          success: {
            render({ data }) {

              setTimeout(() => window.location.href = "#/mi-cuenta", 3000)
              return "Se ha registrado correctamente tu publicación"
            },
            icon: true,
          },
          error: {
            render({ data }) {
              // buttonRegistro.current.disabled = false;
              return data
            }
          }
        }
      )


    }


    return (
      <>
        {/* <CardNumberElement options={cardNumberElementOptions} /> */}

        <div className="col-lg-6 mb-3">
          <label>Número de la tarjeta</label>
          <CardNumberElement className="form-control" options={{ showIcon: true }} onChange={handleCardElementOnChange} />
          <div className="text-danger">{!cardComplete.cardNumber ? `Se requiere el Número de la tarjeta` : ``}</div>
        </div>
        <div className="col-lg-6 mb-3">
          <label>Vencimiento</label>
          <CardExpiryElement className="form-control" onChange={handleCardElementOnChange} />
          <div class="text-danger">{!cardComplete.cardExpiry ? `Se requiere el Número de la tarjeta` : ``}</div>
        </div>
        <div className="col-lg-6 mb-3">
          <label>Cód. de seguridad</label>
          <CardCvcElement className="form-control" onChange={handleCardElementOnChange} />
          <div class="text-danger">{!cardComplete.cardCvc ? `Se requiere el Número de la tarjeta` : ``}</div>
        </div>

        <div className="d-none">
          {/* <button type="button" className="btn btn-yellow" disabled={!stripe || !elements} onClick={() => { handleSubmitStripe() }} */}
          <button type="button" className="btn btn-yellow" onClick={() => { handleSubmitStripe() }}
            ref={
              (el) => {
                inputRef("btnStripe", el);
              }
            }
          >Registrar Propiedad</button>
        </div>
      </>
    );
  };
  const stripePromise = loadStripe('pk_live_51I3PagJG89Pv4lNaixArhmkk9nAMDCF6ANr0obhU12g06k6C3dFWcUBOetngppEv0wQUZJaxFF5VJtoEd0eqnnLp00GTn1hOJv');

  const currentDateBD = (sumDay = null) => {
    const date = new Date().toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }).replace(/ /g, "")
    const arrayDate = date.split(",");
    const fecha = arrayDate[0].split("/");
    const time = arrayDate[1];
    if (sumDay != null) {
      const dateFormat = new Date(`${fecha[2]}-${fecha[1].length == 1 ? `0${fecha[1]}` : fecha[1]}-${fecha[0].length == 1 ? `0${fecha[0]}` : fecha[0]}T${time}`)
      dateFormat.setMonth(dateFormat.getMonth() + sumDay);
      const newDate = new Date(dateFormat).toLocaleString('es-MX', { timeZone: 'America/Mexico_City' }).replace(/ /g, "")
      const arrayDate2 = newDate.split(",");
      const fecha2 = arrayDate2[0].split("/");
      const time2 = arrayDate2[1];
      return `${fecha2[2]}-${fecha2[1].length == 1 ? `0${fecha2[1]}` : fecha2[1]}-${fecha2[0].length == 1 ? `0${fecha2[0]}` : fecha2[0]}T${time2}`;
    } else {
      return `${fecha[2]}-${fecha[1].length == 1 ? `0${fecha[1]}` : fecha[1]}-${fecha[0].length == 1 ? `0${fecha[0]}` : fecha[0]}T${time}`;
    }
  }


  return (
    <div className="add-new-property-area pd-top-90 mg-bottom-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-11 col-lg-12">
            <div className="section-title text-center">
              <h3>Publicar propiedad</h3>
            </div>
            <div className="border-bottom mb-4">
              <div className="row">
                <div className="col-md-3">
                  <div className="single-intro style-two text-center" style={{ cursor: 'pointer' }} onClick={() => actionItem(1)}>
                    <div className="thumb" style={item1 ? { background: "#C9792C" } : { background: '#FFB71A' }}>
                      1
                    </div>
                    <div className="details">
                      <h5 className="title">Información de propiedad</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="single-intro style-two text-center" style={{ cursor: 'pointer' }} onClick={() => actionItem(2)}>
                    <div className="thumb" style={item2 ? { background: "#C9792C" } : { background: '#FFB71A' }}>
                      2
                    </div>
                    <div className="details">
                      <h5 className="title">Multimedia</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="single-intro style-two text-center" style={{ cursor: 'pointer' }} onClick={() => actionItem(3)}>
                    <div className="thumb" style={item3 ? { background: "#C9792C" } : { background: '#FFB71A' }}>
                      3
                    </div>
                    <div className="details">
                      <h5 className="title">Planes</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="single-intro style-two text-center" style={{ cursor: 'pointer' }} onClick={() => actionItem(4)}>
                    <div className="thumb" style={item4 ? { background: "#C9792C" } : { background: '#FFB71A' }}>
                      4
                    </div>
                    <div className="details">
                      <h5 className="title">Realiza tu pago</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-content" >

              <div className={tab1 ? "tab-pane fade show active" : "tab-pane fade"}>

                {/*------------------------ Información de la propiedad ------------------------*/}

                <h4 className="text-center">Información de la propiedad</h4>
                <div className="text-center add-property-btn-wrap pt-4">
                  <button type="button" className="btn btn-yellow rounded-0 px-5" style={btnIncome ? { background: "#FFFFFF", color: "#000000", border: "1px solid #dee2e6" } : { background: "#C9792C" }} onClick={actionIncome}>Venta</button>
                  <button type="button" className="btn btn-yellow rounded-0 px-5 mr-md-3" style={btnSale ? { background: "#FFFFFF", color: "#000000", border: "1px solid #dee2e6" } : { background: "#C9792C" }} onClick={actionSale}>Renta</button>
                </div>
                <div className="row pt-4">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <Input type="text" placeholder="Titulo Externo" name="externalTitle" value={externalTitle} onChange={onChange} required={true} message="Se requiere el Titulo de Externo" inputRef={onRef} section={1} />
                      </div>

                      <div className="col-lg-6 mb-3">
                        <Input type="text" placeholder="Titulo Interno" name="internalTitle" value={internalTitle} onChange={onChange} required={true} message="Se requiere el Titulo de Interno" otros={{ "data-toggle": "tooltip", "data-placement": "right", "title": "Este no aparecerá de manera pública, \npuede ser un nombre corto para que lo identifiques mas rápido" }} inputRef={onRef} section={1} />
                      </div>

                      <div className="col-lg-6 mb-3">
                        <Select options={initStatePropertyType} defaultOption={"Tipo de propiedad"} name="propertyType" required={true} message="Se requiere el tipo de propiedad" onChange={onChange} inputRef={onRef} section={1} />
                      </div>

                      <div className="col-lg-6 mb-3">
                        <Input type="text" placeholder="Precio de la propiedad" name="price" value={price} onChange={onChange} required={true} message="Se requiere el Precio de la propiedad" inputRef={onRef} section={1} moneda={true} pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" />
                      </div>

                      <div className="col-lg-12 mb-3">
                        <Select options={subtypeProperty} defaultOption={"Subtipo de propiedad"} name="subTypeProperty" required={true} message="Se requiere el Subtipo de la propiedad" onChange={onChange} inputRef={onRef} section={1} />
                      </div>
                      {
                        (house && department) ?
                          <></>
                          :
                          <div className="col-lg-3 mb-3">
                            <Input type="number" placeholder="Recámaras" name="bedRoom" value={bedRoom} onChange={onChange} required={true} message="Se requiere las recámaras" inputRef={onRef} section={1} />
                          </div>
                      }

                      {
                        (house && department && commercial) ?
                          <></>
                          :
                          <div className="col-lg-3 mb-3">
                            <Input type="number" placeholder="Baños" name="bathRoom" value={bathRoom} onChange={onChange} message="Se requiere los Baños" inputRef={onRef} section={1} />
                          </div>
                      }

                      {
                        (house && department && commercial) ?
                          <></>
                          :
                          <div className="col-lg-3 mb-3">
                            <Input type="number" placeholder="1/2 Baños" name="halfBath" value={halfBath} onChange={onChange} message="Se requiere los 1/2 Baños" inputRef={onRef} section={1} />
                          </div>
                      }

                      {
                        (house && department && commercial) ?
                          <></>
                          :
                          <div className="col-lg-3 mb-3">
                            <Input type="number" placeholder="M2 Construcción" name="squareMetersBuild" value={squareMetersBuild} onChange={onChange} required={true} message="Se requiere los M2 Construcción" inputRef={onRef} section={1} />
                          </div>
                      }

                      {
                        (house && terrain && commercial) ?
                          <></>
                          :
                          <div className="col-lg-3 mb-3">
                            <Input type="number" placeholder="M2 Terreno" name="squareMetersLand" value={squareMetersLand} onChange={onChange} required={true} message="Se requiere los M2 Terreno" inputRef={onRef} section={1} />
                          </div>
                      }

                      {
                        (house && terrain && commercial) ?
                          <></>
                          :
                          <div className="col-lg-3 mb-3">
                            <Input type="number" placeholder="M2 Fondo" name="m2Deep" value={m2Deep} onChange={onChange} required={false} message="Se requiere los M2 Fondo" inputRef={onRef} section={1} />
                          </div>
                      }
                      {
                        (house && department && terrain && commercial) ?
                          <></>
                          :
                          <div className="col-lg-3 mb-3">
                            <Input type="number" placeholder="M2 Frente" name="m2Front" value={m2Front} onChange={onChange} required={false} message="Se requiere los M2 Frente" inputRef={onRef} section={1} />
                          </div>
                      }
                      {
                        (house && department && commercial) ?
                          <></>
                          :
                          <div className="col-lg-3 mb-3">
                            <Input type="number" placeholder="Estacionamiento" name="parking" value={parking} onChange={onChange} required={true} message="Se requiere el Estacionamiento" inputRef={onRef} section={1} />
                          </div>
                      }

                      {
                        (house && department && commercial) ?
                          <></>
                          :
                          <div className="col-lg-3 mb-3" >
                            <Input type="number" placeholder="No. de plantas" name="floor" value={floor} onChange={onChange} required={false} message="Se requiere el No. de plantas" inputRef={onRef} section={1} />
                          </div>
                      }

                      {
                        (house && department && terrain) ?
                          <></>
                          :
                          <div className="col-lg-3 mb-3" >
                            <Input type="number" placeholder="Mantenimiento" name="maintenance" value={maintenance} onChange={onChange} required={false} message="Se requiere el Mantenimiento" inputRef={onRef} section={1} />
                          </div>
                      }
                      {
                        (terrain) ?
                          <></>
                          :
                          <div className="col-lg-3 mb-3" >
                            <Select options={[{ value: 1, title: "Regular" }, { value: 2, title: "Irregular" }]} defaultOption={"Regular o Irregular"} name="regularIrregular" message="Se requiere si es regular o Irregular" onChange={onChange} inputRef={onRef} section={1} />
                          </div>
                      }
                      {
                        (house && department && commercial) ?
                          <></>
                          :
                          <div className="col-lg-3 mb-3" >
                            <div className="rld-single-select">
                              <Select options={[{ value: 1, title: "A estrenar" }, { value: 2, title: "Años" }]} defaultOption={"Antigüedad"} name="antiquity" message="Se requiere la Antigüedad" onChange={onChange} inputRef={onRef} section={1} />
                            </div>
                          </div>
                      }
                      {
                        hideAntiquity ?
                          <></>
                          :
                          <div className="col-lg-3 mb-3" >
                            <Input type="number" placeholder="No. de años" name="numYear" value={numYear} onChange={onChange} required={false} message="Se requiere el No. de años" inputRef={onRef} section={1} />
                          </div>
                      }

                      <div className="col-lg-12 mb-3">
                        <Textarea placeholder="Descripción del la propiedad" name="description" value={description} required={true} maxLength={1500} message="Se requiere la description de la propiedad" onChange={onChange} inputRef={onRef} section={1} />
                      </div>
                    </div>
                  </div>
                </div>


                {/*------------------------ Servicios ------------------------*/}
                <div className="row pd-top-80">
                  <div className="col-md-12">
                    <div className="section-title mb-0">
                      <h4>Servicios</h4>
                    </div>
                  </div>
                  <div className="col-md-12 mt-2 mb-1">

                    <div className="row mb-2">
                      <div className="col-sm-3">
                        <Checkbox text="Gas Natural" name="services" field="naturalGas" onChangeArray={onChangeArray} />
                      </div>
                      <div className="col-sm-3">
                        <Checkbox text="Gas Estacionario" name="services" field="stationaryGas" onChangeArray={onChangeArray} />
                      </div>
                      <div className="col-sm-3">
                        <Checkbox text="Energía Solar" name="services" field="solarEnergy" onChangeArray={onChangeArray} />
                      </div>
                      <div className="col-sm-3">
                        <Checkbox text="Servicio de Agua" name="services" field="water" onChangeArray={onChangeArray} />
                      </div>
                    </div>
                    <div className="collapse multi-collapse" id="content_servicios">
                      <div className="row mb-2">
                        <div className="col-sm-3">
                          <Checkbox text="Calefacción" name="services" field="calefaction" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-3">
                          <Checkbox text="Cable" name="services" field="cable" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-3">
                          <Checkbox text="Electricidad" name="services" field="electricity" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-3">
                          <Checkbox text="Pavimento" name="services" field="paviment" onChangeArray={onChangeArray} />
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-sm-3">
                          <Checkbox text="Aire acondicionado" name="services" field="airConditioning" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-3">
                          <Checkbox text="Internet" name="services" field="internet" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-3">
                          <Checkbox text="Instalación Eléctrica subterránea" name="services" field="undergroundElectricity" onChangeArray={onChangeArray} />
                        </div>

                      </div>
                    </div>

                  </div>
                  <div className="col-12 text-center d-flex justify-content-center">
                    {buttonText ?
                      <button className="btn btn-yellow btn-block " style={{ height: "30px", lineHeight: "30px", width: "30%" }} type="button" data-toggle="collapse" data-target="#content_servicios" aria-expanded="false" aria-controls="content_servicios" onClick={() => { setButtonText(false) }}><i className="fa fa-plus"></i> Ver más</button>
                      :
                      <button className="btn btn-yellow btn-block" style={{ height: "30px", lineHeight: "30px", width: "30%" }} type="button" data-toggle="collapse" data-target="#content_servicios" aria-expanded="false" aria-controls="content_servicios" onClick={() => { setButtonText(true) }}><i className="fa fa-minus"></i> Ver menos</button>
                    }
                  </div>
                </div>

                {/*------------------------ Espacios ------------------------*/}
                <div className="row pd-top-60">
                  <div className="col-md-12">
                    <div className="section-title">
                      <h4>Espacios</h4>
                    </div>
                  </div>
                  <div className="col-md-12 mt-2 mb-1">
                    <div className="row mb-2">
                      <div className="col-sm-2">
                        <Checkbox text="Jardín" name="spaces" field="privateGarden" onChangeArray={onChangeArray} />
                      </div>
                      <div className="col-sm-2">
                        <Checkbox text="Balcón" name="spaces" field="balcony" onChangeArray={onChangeArray} />
                      </div>
                      <div className="col-sm-3">
                        <Checkbox text="Hidromasaje" name="spaces" field="hydromassage" onChangeArray={onChangeArray} />
                      </div>
                      <div className="col-sm-2">
                        <Checkbox text="Despensa" name="spaces" field="pantry" onChangeArray={onChangeArray} />
                      </div>
                      <div className="col-sm-3">
                        <Checkbox text="Cuarto de servicio" name="spaces" field="serviceRoom" onChangeArray={onChangeArray} />
                      </div>
                    </div>
                    <div className="collapse multi-collapse" id="content_espacios">
                      <div className="row mb-2">
                        <div className="col-sm-2">
                          <Checkbox text="Estudio" name="spaces" field="study" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-2">
                          <Checkbox text="Parrilla" name="spaces" field="grill" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-3">
                          <Checkbox text="Ático" name="spaces" field="attic" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-2">
                          <Checkbox text="Oficina" name="spaces" field="office" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-3">
                          <Checkbox text="Baño de servicio" name="spaces" field="bathService" onChangeArray={onChangeArray} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-2">
                          <Checkbox text="Sala de cine" name="spaces" field="homeTheather" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-2">
                          <Checkbox text="Terraza" name="spaces" field="terrace" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-3">
                          <Checkbox text="Cuarto de Almacenamiento" name="spaces" field="cellar" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-2">
                          <Checkbox text="Vestíbulo" name="spaces" field="lobby" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-3">
                          <Checkbox text="Galería" name="spaces" field="galery" onChangeArray={onChangeArray} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-center d-flex justify-content-center">
                    {buttonText2 ?
                      <button className="btn btn-yellow btn-block" style={{ height: "30px", lineHeight: "30px", width: "30%" }} type="button" data-toggle="collapse" data-target="#content_espacios" aria-expanded="false" aria-controls="content_espacios" onClick={() => { setButtonText2(false) }}><i className="fa fa-plus"></i> Ver más</button>
                      :
                      <button className="btn btn-yellow btn-block" style={{ height: "30px", lineHeight: "30px", width: "30%" }} type="button" data-toggle="collapse" data-target="#content_espacios" aria-expanded="false" aria-controls="content_espacios" onClick={() => { setButtonText2(true) }}><i className="fa fa-minus"></i> Ver menos</button>
                    }
                  </div>
                </div>

                {/*------------------------ Amenidades ------------------------*/}
                <div className="row pd-top-60">
                  <div className="col-md-12">
                    <div className="section-title">
                      <h4>Amenidades</h4>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row mb-2">
                      <div className="col-sm-3">
                        <Checkbox text="Gimnasio" name="amenities" field="gym" onChangeArray={onChangeArray} />
                      </div>
                      <div className="col-sm-3">
                        <Checkbox text="Cancha de tennis" name="amenities" field="tennisCourt" onChangeArray={onChangeArray} />
                      </div>
                      <div className="col-sm-3">
                        <Checkbox text="Jacuzzi" name="amenities" field="jacuzzi" onChangeArray={onChangeArray} />
                      </div>
                      <div className="col-sm-3">
                        <Checkbox text="Sauna" name="amenities" field="sauna" onChangeArray={onChangeArray} />
                      </div>
                    </div>
                    <div className="collapse multi-collapse" id="content_amenidades">
                      <div className="row mb-2">
                        <div className="col-sm-3">
                          <Checkbox text="Piscina" name="amenities" field="pool" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-3">
                          <Checkbox text="Cancha de fútbol" name="amenities" field="soccerField" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-3">
                          <Checkbox text="Parrilla" name="amenities" field="grill" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-3">
                          <Checkbox text="Lavandería" name="amenities" field="laundry" onChangeArray={onChangeArray} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-3">
                          <Checkbox text="Área Infantil" name="amenities" field="childrenArea" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-3">
                          <Checkbox text="Área de fiestas" name="amenities" field="partyArea" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-3">
                          <Checkbox text="Sala de juegos" name="amenities" field="gameRoom" onChangeArray={onChangeArray} />
                        </div>
                        <div className="col-sm-3">
                          <Checkbox text="Co-working" name="amenities" field="coWorking" onChangeArray={onChangeArray} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-center d-flex justify-content-center">
                    {buttonText3 ?
                      <button className="btn btn-yellow btn-block" style={{ height: "30px", lineHeight: "30px", width: "30%" }} type="button" data-toggle="collapse" data-target="#content_amenidades" aria-expanded="false" aria-controls="content_amenidades" onClick={() => { setButtonText3(false) }}><i className="fa fa-plus"></i> Ver más</button>
                      :
                      <button className="btn btn-yellow btn-block" style={{ height: "30px", lineHeight: "30px", width: "30%" }} type="button" data-toggle="collapse" data-target="#content_amenidades" aria-expanded="false" aria-controls="content_amenidades" onClick={() => { setButtonText3(true) }}><i className="fa fa-minus"></i> Ver menos</button>
                    }
                  </div>
                </div>

                {/*------------------------ Direccion ------------------------*/}
                <div className="row pd-top-80">
                  <div className="col-md-12">
                    <div className="section-title">
                      <h4>Dirección</h4>
                      <h5 style={{ backgroundColor:"#C9792C", color:"white", textAlign:"center", padding:"2"}}>En caso de no visualizar correctamente la dirección en el mapa, te invitamos ingresar a <strong><a href="https://maps.google.com" target="_blank">Google Maps </a></strong>para verificar la ubicación exacta</h5>
                    </div>
                    <div className="row">
                      <div className="col-lg-7 mb-3">
                      <label><strong>Agregar calle sin número exterior</strong></label>
                        <Input type="text" placeholder="Calle sin n. ext" name="street" value={street} onChange={onChange} required={false} message="Se requiere la calle" inputRef={onRef} section={1} />
                      </div>

                      <div className="col-lg-2 mb-3">
                      <label><strong>Número Exterior</strong></label>
                        <Input type="text" placeholder="No Ext." name="externalNumber" value={externalNumber} onChange={onChange} required={false} message="Se requiere el No Ext." inputRef={onRef} section={1} />
                      </div>
                      <div className="col-lg-3 mb-3">
                      <label><strong>Código postal</strong></label>
                        <Input type="text" placeholder="Código postal" name="cp" value={cp} onChange={onChange} required={true} message="Se requiere el Código postal" inputRef={onRef} section={1} />
                      </div>
                      <div className="col-lg-6 mb-3">
                      <label><strong>Colonia</strong></label>
                        <Input type="text" placeholder="Colonia" name="colony" value={colony} onChange={onChange} required={false} message="Se requiere la Colonia" inputRef={onRef} section={1} />
                      </div>
                      <div className="col-lg-3 mb-3">
                      <label><strong>Ciudad</strong></label>
                        <Input type="text" placeholder="Ciudad" name="city" value={city} onChange={onChange} required={true} message="Se requiere la Ciudad" inputRef={onRef} section={1} />
                      </div>
                      <div className="col-lg-3 mb-3">
                      <label><strong>Estado</strong></label>
                        <Input type="text" placeholder="Estado" name="state" value={state} onChange={onChange} required={true} message="Se requiere el Estado" inputRef={onRef} section={1} />
                      </div>
                      <div className="col-sm-4 mb-3">
                        <button type="button" className="btn btn-yellow btn-block" style={{ height: "30px", lineHeight: "30px" }} onClick={() => { onChange("hideAddress", hideAddress ? false : true) }}>
                          <i className={!hideAddress ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                          {
                            !hideAddress ?
                              ` Ocultar dirección exacta`
                              :
                              ` Mostrar dirección exacta`
                          }
                        </button>
                        {/* <h5>
                          <label className="inputCheck">
                            Ocultar dirección exacta
                            <input
                              type="checkbox"
                              name="hideAddress"
                              onChange={
                                e => {
                                  onChange(e.target.name, e.target.checked)
                                }
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </h5> */}
                      </div>
                      <div className="col-sm-12" style={{ height: "500px" }}>
                        {!hideAddress ?
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              // remove the key if you want to fork
                              key: "AIzaSyDjl4Ux0-5hYmLEVMdhB63ldRYCeIqcx7A",
                              language: "es",
                              region: "MX"
                            }}
                            center={!hideAddress ? { lat: coordsMaps.center.lat, lng: coordsMaps.center.lng } : { lat: initStateMapsCoords.center.lat, lng: initStateMapsCoords.center.lng }}
                            zoom={!hideAddress ? coordsMaps.center.zoom : initStateMapsCoords.center.zoom}
                            // GeocoderLocationType
                            GeocoderLocationType={() => console.log}
                          >
                            <MyMarker key={1} lat={coordsMaps.lat} lng={coordsMaps.lng} text={1} tooltip="Propiedad" />
                          </GoogleMapReact>
                          :
                          <div className="property-news-single-card"><iframe src={`https://maps.google.com/maps?width=100%&height=100%&hl=es&q=${encodeURI(`${colony}, ${city}, ${state}, México`)}&t=&z=15&ie=UTF8&iwloc=B&output=embed`} style={{ border: 0, height: "500px" }} allowFullScreen /></div>
                        }
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className={tab2 ? "tab-pane fade show active" : "tab-pane fade"}>
                <div className="row pt-3">
                  <div className="col-sm-12">
                    <div className="section-title">
                      <h4 className="text-center">Multimedia</h4>
                      <h5 style={{color:"#C9792C", textAlign:"center", padding:"2"}}>Te recomendamos subir imagenes con un <strong>tamaño inferior a 3 MB y en formato .jpg, jpeg o .png</strong>, para una mejor experiencia a los usuarios que visitan tu propiedad.</h5>
                    </div>
                  </div>
                  <div className="col-sm-2 d-flex align-items-center justify-content-center">
                    <h5 className="text-center">Fotos</h5>
                   
                  </div>
                  <div className="col-sm-8 ">
                    <div className="inputFile">
                      <div className="image-upload-wrap">
                        <input
                          className="file-upload-input"
                          type="file"
                          accept="image/*"
                          name="inputImage"
                          multiple
                          onChange={(e) => {
                            changeImage(e);
                          }}
                          ref={
                            (el) => {
                              onRef("inputImage", el);
                            }
                          }
                        />
                        <div className="my-4 text-center">
                          {(arrayFiles.length > 0) ?
                            <p>Tiene "{arrayFiles.length}" imágenes para agregar</p>
                            :
                            <p>Arrastra o agrega las imágenes "aquí" y dar click en "Cargar"</p>
                          }
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="col-sm-2 d-flex align-items-center">
                    <button className="btn btn-yellow" type="button" onClick={() => { (arrayFiles.length > 0) && setImageSelectedPrevious([...ImageSelectedPrevious, ...arrayFiles]); assignValue("files", [...ImageSelectedPrevious, ...arrayFiles]); setArrayFiles([]) }}>Cargar</button>
                  </div>
                  {
                    errorImage &&
                    <div className="col-sm-12 text-danger text-center">
                      Se requieren fotos de la propiedad
                    </div>
                  }
                  <div className="my-3 col-sm-12 row">{srcImg}</div>

                  <div className="my-3 col-sm-12"></div>
                  <div className="col-sm-2 d-flex align-items-center justify-content-center">
                    <h5 className="text-center">
                      Videos <br /> (Opcional)
                    </h5>
                  </div>
                  <div className="col-sm-8" >
                    <input style={{ border: "1px dashed #FBB138", width: "100%", height: "100%", textAlign: "center" }} placeholder="URL del video de tú propiedad aquí" value={inputURL} onChange={(e) => setInputURL(e.target.value)} />
                  </div>
                  <div className="col-sm-2 d-flex align-items-center">
                    <button className="btn btn-yellow" type="button" onClick={ValidationURL}>Agregar</button>
                  </div>
                  <div className="my-3 col-sm-12 row">
                    {
                      arrayInputURL.map(
                        (value, index) => <div className="col-md-4 mb-2" key={index}>
                          <div className="p-2" style={{ border: `1px dashed #FBB138` }}>
                            <div className="d-flex align-items-center justify-content-center">
                              <iframe src={value} allowFullScreen />
                            </div>
                            <hr style={{ borderTop: `1px dashed #FBB138` }} />
                            <div className="row d-flex align-items-center justify-content-end">
                              <div className="col-md-3">
                                <button type="button" className="btn btn-sm text-danger p-1" style={{ height: "30px", lineHeight: "100%", width: "30px", background: "#dc354526", borderRadius: "5px" }} onClick={e => handleDeleteURL(index)}><i className="fa fa-times"></i></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                  <div className="my-3 col-sm-12"></div>
                  <div className="col-sm-2 d-flex align-items-center justify-content-center">
                    <h5 className="text-center">
                      Planos <br /> (Opcional)
                    </h5>
                  </div>
                  <div className="col-sm-8 ">
                    <div className="inputFile">
                      <div className="image-upload-wrap">
                        <input
                          className="file-upload-input"
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={(e) => {
                            changePlanos(e);
                          }}
                        />
                        <div className="my-4 text-center">
                          {(arrayFilesPlanos.length > 0) ?
                            <p>Tiene "{arrayFilesPlanos.length}" imagenes para agregar</p>
                            :
                            <p>Arrastra o agrega las imagenes de tu propiedad y da click en "Cargar"</p>
                          }
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="col-sm-2 d-flex align-items-center">
                    <button className="btn btn-yellow" type="button" onClick={() => { handleAddPlan() }}>Cargar</button>
                  </div>
                  <div className="my-3 col-sm-12 row">{plansDiv}</div>
                  <div className="my-3 col-sm-12"></div>
                  <div className="col-sm-2 d-flex align-items-center justify-content-center">
                    <h5 className="text-center">
                      Recorrido 360 <br /> (Opcional)
                    </h5>
                  </div>
                  <div className="col-sm-8" >
                    <input style={{ border: "1px dashed #FBB138", width: "100%", height: "100%", textAlign: "center" }} placeholder="Embedded code del video de tu propiedad aquí" value={inputRecorrido} onChange={(e) => setInputRecorrido(e.target.value)} />
                  </div>
                  <div className="col-sm-2 d-flex align-items-center">
                    <button className="btn btn-yellow" type="button" onClick={ValidationRecorrido}>Agregar</button>
                  </div>

                  {
                    errorURLRecorrido &&
                    <div className="col-sm-12 text-danger text-center">
                      La url ingresada no es correcta.
                    </div>
                  }
                  <div className="my-3 col-sm-12 row">
                    {
                      arrayInputRecorrido.map(
                        (value, index) => <div className="col-md-12 mb-2" key={index}>
                          <div className="p-4" style={{ border: `1px dashed #FBB138` }}>
                            <div className="float-right" style={{ marginTop: '-25px', marginRight: '-20px' }}>
                              <button type="button" className="btn btn-sm text-danger p-1" style={{ height: "20px", lineHeight: "100%", width: "20px", background: "#dc354526", borderRadius: "50%" }} onClick={e => handleDeleteRecorrido(index)}><i className="fa fa-times" style={{ paddingTop: '-10px', marginRight: '-2px' }}></i></button>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                              <iframe style={{ width: "100%", height: "500px", border: "none" }} border="0" allowFullScreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" src={value} />
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

              <div className={tab3 ? "tab-pane fade show active" : "tab-pane fade"}>
                <div className="row pt-3">
                  <div className="col-sm-12">
                    <div className="section-title">
                      <h4 className="text-center">Planes</h4>
                    </div>
                  </div>
                </div>

                <div className="row pt-3">
                  {arrayPriceTable.map(({ id, plan, color, precio, beneficios, url }, index) =>
                    <div key={index} className="col-lg-4 col-sm-6" style={{ paddingRight: "30px", paddingLeft: "30px" }}>
                      <div className={`single-pricing text-center border-${plan}`}>
                        <div className="thumb double-img">
                          <img width={30} src={`${process.env.PUBLIC_URL}/${url}`} />
                        </div>
                        <div className="details">
                          <h4 className="title" style={{ color: color }}>{plan}</h4>
                          <h3 className="price" style={{ color: color }}> {(typeof precio == 'string') ? precio : `$ ${parseFloat(precio).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} + IVA`}</h3>
                          <h6>Beneficios</h6>
                          <ul>

                            {beneficios.map((beneficios, i) =>
                              <li key={i}>{beneficios}</li>
                            )}

                          </ul>
                          <button className={(planes == id) ? `btn btn-${plan}` : `btn hover-${plan}`} type="button" onClick={() => { handlePlanes(id, index); }}>Seleccionar</button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-12" id="contentPaquetes">
                  {infoPaquetes && <h4 className="text-center">Paquetes</h4>}
                  {infoPaquetes &&
                    infoPaquetes.paquetes.map(({ id, color, tiempo, precio }, i) =>
                      <div key={i} className="d-flex justify-content-center  font-weight-bold text-center" style={{ cursor: "pointer" }} onClick={e => { handleSelectedPackage(id, i); }}>

                        <div className="col-sm-1 py-3" style={{ background: color }}></div>
                        <div className="col-sm-3 py-3" style={{ background: color }}>{tiempo}</div>
                        <div className="col-sm-2 py-3" style={{ background: color }}>
                          {(typeof precio == 'string') ? precio : "$ " + parseFloat(precio).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + " + IVA"}
                        </div>
                        <div className="col-sm-2 py-3 d-flex justify-content-end" style={{ background: color }}>
                          <label className="content-input" >
                            <input type="radio" name="radio" id={`radio${i}`} defaultChecked={false}
                              ref={
                                el => {
                                  fieldPaquetes.current = {
                                    ...fieldPaquetes.current,
                                    [`radio${i}`]: el
                                  };
                                }
                              }
                            />
                            <span className="checkmark-radio" ></span>
                          </label>
                        </div>
                        <div className="col-sm-2 py-3" style={{ background: color }}></div>
                      </div>
                    )
                  }

                </div>

              </div>

              <div className={tab4 ? "tab-pane fade show active" : "tab-pane fade"}>
                {
                  !showConfirmacion ?
                    <>
                      <h4 className="text-center">Vista Previa</h4>
                      {
                        view && !loading ?
                          <div className="py-2" style={{ border: "2px dashed #FBB138", height: "500px", overflowY: "scroll", overflowX: "hidden" }}>
                            <PropertyDetailsSection propiedad={previewProperty} />
                          </div>
                          :
                          <div className="py-2 loader-container text-center" style={{ border: "2px dashed #FBB138", height: "500px", overflowY: "scroll", overflowX: "hidden" }}>
                            <div>
                              <div className="d-flex align-items-center justify-content-center">
                                <div className="loader"></div>
                              </div>
                              <br />
                              <b style={{ color: "#fbb138" }}>Cargando información para la vista previa</b>
                            </div>
                          </div>
                      }
                    </>
                    :
                    <></>
                }

                {
                  showConfirmacion ?
                    <div className="row d-flex justify-content-center">
                      {
                        (typeof selectedPackage.precio !== 'string') &&
                        <div className="col-sm-8 row">


                          <div className="col-sm-12">
                            <h4 className="text-center">Datos de pago</h4>
                          </div>


                          <div className="col-lg-6 mb-3">
                            <label>Email</label>
                            <Input type="email" placeholder="" name="email" value={email} onChange={onchangeLR} required={true} message="Se requiere el Email" inputRef={onRefLR} section={4} />
                          </div>
                          <div className="col-lg-6 mb-3">
                            <label>Número de teléfono</label>
                            <Input type="text" placeholder="" name="tel" value={tel} onChange={onchangeLR} required={true} message="Se requiere el Teléfono" inputRef={onRefLR} section={4} />
                          </div>
                          <div className="col-lg-6 mb-3">
                            <label>Titular de la tarjeta</label>
                            <Input type="text" placeholder="" name="cardholder" value={cardholder} onChange={onchangeLR} required={true} message="Se requiere el Titular de la tarjeta" inputRef={onRefLR} section={4} />
                          </div>
                          <Elements stripe={stripePromise}>
                            <CheckoutForm inputRef={onRefLR} />
                          </Elements>

                          <div className={factura ? "col-sm-12" : "d-none"}>
                            <h4 className="text-center">Datos de facturación</h4>
                          </div>
                          <div className={factura ? "col-lg-6 mb-3" : "d-none"}>
                            <label>Razón social</label>
                            <Input type="text" placeholder="" name="companyName" value={companyName} onChange={onchangeLR} required={false} message="Se requiere el Nombre / Razón social" inputRef={onRefLR} section={4} />
                          </div>
                          <div className={factura ? "col-lg-6 mb-3" : "d-none"}>
                            <label>RFC Persona Fiscal</label>
                            <Input type="text" placeholder="" name="rfc" value={rfc} onChange={onchangeLR} required={false} message="Se requiere el RFC" inputRef={onRefLR} section={4} />
                          </div>
                          <div className={factura ? "col-lg-6 mb-3" : "d-none"}>
                            <label>Regimen</label>
                            <Select options={dataRegimen} defaultOption={"Regimen"} name="regimen" message="Se requiere el regimen" onChange={onchangeLR} inputRef={onRefLR} section={4} />
                          </div>
                          <div className="col-lg-12">
                            <label className="inputCheck">
                              Requiere Factura
                              <input
                                type="checkbox"
                                name="termsAndConditions"
                                checked={factura}
                                onChange={({ target }) => {
                                  setFactura(target.checked);
                                }}
                              />
                              <span className="checkmark" style={{ border: "1px solid #FBB138", background: "#FFF", width: "27px", height: "27px" }}></span>
                            </label>
                          </div>

                        </div>
                      }
                      <div className="col-sm-4 mb-5">
                        <div className="pt-3" style={{ border: "1px dashed #FBB138" }}>
                          <h5 className="text-center"><b><u>Detalles de tu compra</u></b></h5>
                          <br />
                          {infoPaquetes && <h6 className="pl-4"> Plan {infoPaquetes.plan} : {selectedPackage && (typeof selectedPackage.precio == 'string') ? selectedPackage.precio : "$ " + parseFloat(selectedPackage.precio).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h6>}

                          {
                            (selectedPackage && (typeof selectedPackage.precio !== 'string')) && <><br /><h6 className="pl-4"> IVA : {(typeof selectedPackage.precio !== 'string') && "$ " + parseFloat(parseFloat(selectedPackage.precio) * .16).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} </h6><br /><br /></>
                          }

                          <div className="px-4"><hr /></div>

                          {
                            selectedPackage &&
                            <h6 className="pl-4">
                              Total :
                              {
                                (typeof selectedPackage.precio !== 'string') ?
                                  " $ " + parseFloat(parseFloat(selectedPackage.precio) * 1.16).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                                  :
                                  ` ${selectedPackage.precio}`
                              }
                            </h6>
                          }
                        </div>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <hr></hr>
                        <label className="inputCheck">
                          Acepto los <b style={{ color: "#FBB138" }}><Link to={"/terminos-condiciones"} target="_blank"> términos y condiciones</Link></b> de contratación del servicio.
                          <input
                            type="checkbox"
                            name="termsAndConditions"
                            checked={termsAndConditions}
                            ref={
                              (el) => {
                                onRefLR("termsAndConditions", el);
                              }
                            }
                            onChange={({ target }) => {
                              target.checked ? setErrorTermsAndConditions(false) : setErrorTermsAndConditions(true);
                              assignValueLR("termsAndConditions", target.checked);
                            }}
                          />
                          <span className="checkmark" style={{ border: "1px solid #FBB138", background: "#FFF", width: "27px", height: "27px" }}></span>
                        </label>
                        {
                          errorTermsAndConditions &&
                          <div className="text-danger">
                            Se requiere que acepte los terminos y condiciones
                          </div>
                        }
                        <p>El precio de los paquetes incluyen IVA.</p>
                      </div>
                    </div>
                    :
                    <></>
                }

              </div>
            </div>
            <br /><hr />
            <div className="d-flex justify-content-between pt-3">
              <div className="mr-2">
                <button type="button" className="btn" onClick={actionPrevious} style={!btnPrevious ? { "display": "none" } : {}}>Anterior</button>
              </div>
              <div>
                <button type="button" className="btn btn-yellow" style={!btnSubmit ? { "display": "none" } : {}}
                  onClick={

                    () => {
                      // buttonRegistro.current.disabled = true;
                      (typeof selectedPackage.precio !== 'string') ? fieldRefLR.current.btnStripe.click() : handleSubmit();
                    }

                  }
                  ref={
                    (el) => {
                      buttonRegistro.current = el;
                    }
                  }
                >Registrar Propiedad</button>
                <button type="button" className="btn btn-orange" style={!btnNext ? { "display": "none" } : {}} onClick={() => actionNext()}>Siguiente</button>
                <button type="button" className="btn btn-yellow rounded-0 px-5 mr-md-3" style={showConfirmacion || !tab4 ? { "display": "none" } : {}}
                  onClick={
                    () => {
                      setShowConfirmacion(true);
                      setWizard({
                        ...wizard,
                        ["btnSubmit"]: true,
                      });
                      setFormValueLR(initStateRegistro);
                      setErrorTermsAndConditions(false);
                      window.scrollTo({
                        top: 600,
                        behavior: "smooth"
                      });
                    }
                  }

                >Confirmar</button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <ToastContainer style={{ width: "auto", marginTop: "180px" }} />


    </div>

  );
}


export default AddNew