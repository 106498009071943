import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import { useForm } from '../../hooks/useForm';
import useSignInFirebase from '../../hooks/useSignInFirebase'

const init = {
	email: '',
	password: ''
};

const Login = () => {
	const { signIn, signInProvider: signInProviderCribbee } = useContext(AuthContext);
	const { email, password, onChange } = useForm(init);

	const [request, setRequest] = useState({ status: false, message: null });
	const { signInProvider } = useSignInFirebase();
	const [facebookNotVerifiedEmail, setFacebookNotVerifiedEmail] = useState(false);
	const handleProviderLogin = async(provider) => {
		try {
			setFacebookNotVerifiedEmail(false);
			const user = await signInProvider(provider);
			if (user.email === null && user.providerData[0].email === null) {
				setFacebookNotVerifiedEmail(true);
				return;
			}
			await signInProviderCribbee({
				fullname: user.displayName,
				email: user.email || user.providerData?.[0]?.email,
				photo: user.photoURL,
				unique_session_token: user.uid,
				type_register: provider
			});
			window.location = '#/mi-cuenta'
		} catch (error) {
			console.log("sign in error", error);
			if (error.code !== "auth/popup-closed-by-user") {
				setRequest({ status: false, message: error.message });
			}
		}
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setFacebookNotVerifiedEmail(false);
		const rest = await signIn({ email, password });
		if (rest.status) {
			window.location = '/'
		} else {
			setRequest({ status: 2, message: rest.message });
		}
	}

	return (
		<>
			<div className="contact-form-wrap contact-form-bg">
				<form onSubmit={onSubmit} >
					{(() => {
						switch (request.status) {
							case 2:
								return (
									<div className="alert alert-danger alert-dismissible fade show d-flex align-items-center" role="alert">
										<strong className="pr-2"><i className="fa fa-info-circle"></i></strong> {request.message}
									</div>
								);
							default: return '';
						}
					})()}
					<h3>Iniciar Sesión</h3>
					<div className="rld-single-input">
						<input type="text" name="email" placeholder="Correo Electrónico" value={email} onChange={(e) => onChange(e.target.name, e.target.value)} />
					</div>
					<div className="rld-single-input">
						<input type="password" name="password" placeholder="Contraseña" autoComplete="on" value={password} onChange={(e) => onChange(e.target.name, e.target.value)} />
					</div>
					<div className="d-flex justify-content-start">
						<Link to="/recover-password">¿Olvidaste tu contraseña?</Link>
					</div>
					<div className="btn-wrap text-center">
						<button className="btn btn-yellow" type="submit">Iniciar Sesión</button>
					</div>
					<div style={{marginTop: 20}}>
						{
							facebookNotVerifiedEmail &&
							<h5 style={{color: 'red'}}>  No es posible proseguir con su registro a través de su cuenta de Facebook, intente con otro método o verifique el su correo electrónico en Facebook</h5>
						}
					</div>
				</form>
				<br />
				<h5 className="lg-h5-line"><span>ó</span></h5>
				<button type='button' className="btn btn-block d-flex align-items-center justify-content-center" onClick={() => handleProviderLogin('google')}>
					<svg aria-hidden="true" className="svg-icon mr-2" width="18" height="18" viewBox="0 0 18 18">
						<path d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 002.38-5.88c0-.57-.05-.66-.15-1.18Z" fill="#4285F4"></path>
						<path d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 01-7.18-2.54H1.83v2.07A8 8 0 008.98 17Z" fill="#34A853"></path>
						<path d="M4.5 10.52a4.8 4.8 0 010-3.04V5.41H1.83a8 8 0 000 7.18l2.67-2.07Z" fill="#FBBC05"></path>
						<path d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 001.83 5.4L4.5 7.49a4.77 4.77 0 014.48-3.3Z" fill="#EA4335"></path>
					</svg> Iniciar Sesión con Google
				</button>
				<button type='button' className="btn btn-facebook btn-block" onClick={() => handleProviderLogin('facebook')}><i className="fa fa-facebook mr-2"></i> Iniciar Sesión con Facebook</button>
				<br />
				<p>¿No tienes una cuenta? <Link className="text-primary" to="/registration">Regístrate</Link></p>
			</div>
		</>
	)
}

export default Login

