import React, { useState, useRef } from 'react';
import { cribbeeAPI } from '../../api/cribbeeAPI';
import Modal from 'react-bootstrap/Modal';
import Login from './login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const PropiedadesRecomendadas = ({ data, design = 1 }) => {
  const [show, setShow] = useState(false);
  const contentLiked = useRef({});
  const handleLikeProperty = async (property) => {
    try {

      if (contentLiked.current[`liked${property}`].classList.value.indexOf("like-active") !== -1) {
        contentLiked.current[`liked${property}`].innerHTML = `<i class="fa fa-heart-o"></i>`;
        contentLiked.current[`liked${property}`].classList.remove('like-active');
        await cribbeeAPI.post('/remove-like-property-web', { property: property, user: localStorage.getItem('cribbeeUsuario'), });
      } else {
        contentLiked.current[`liked${property}`].innerHTML = `<i class="fa fa-heart" style="color:#eb4d61"></i>`;
        contentLiked.current[`liked${property}`].classList.add('like-active');
        await cribbeeAPI.post('/like-property-web', { property: property, user: localStorage.getItem('cribbeeUsuario'), });
      }
    } catch (error) {
      // console.error(error);
    }
  }
  const handleViewProperty = async (property, url) => {
    try {
      await cribbeeAPI.post('/view-property-web', { property: property, user: localStorage.getItem('cribbeeUsuario'), });
      // <Redirect to={url}></Redirect>
      window.location.href = `#${url}`;
    } catch (error) {
      // console.error(error);
      window.location.href = `#${url}`;
      // <Redirect to={url}></Redirect>
    }
  }

  let strPropiedades = '';
  if (Array.isArray(data) && design == 1) {

    strPropiedades = data.map((item, i) => {
      let flagPlan = `thumb `;
      if (item.plan === 1) {
        flagPlan += `iconOro`;
      } else if (item.plan === 2) {
        flagPlan += `iconPlata`;
      }
      return <div key={i} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
        <div className="single-feature">
          <a style={{ cursor: 'pointer' }} onClick={() => {
            handleViewProperty(item.id, item.url);
          }}>
            <div className={flagPlan}>
              <img src={`${item.image.secundaria !== undefined ? item.image.secundaria : item.image.principal}`} alt={item.externalTitle} />
            </div>
          </a>
          <div className="details">
            <p className={item.likeProperty == 1 ? "feature-logo like-active" : "feature-logo"} style={{ fontSize: "20px", paddingTop: "3px", cursor: "pointer" }}
              onClick={() => {
                if (localStorage.getItem('cribbeeUsuario') == null) {
                  setShow(true);
                } else {
                  handleLikeProperty(item.id);
                }
              }}
              ref={
                el => {
                  contentLiked.current = {
                    ...contentLiked.current,
                    [`liked${item.id}`]: el
                  };
                }
              }
            >
              {item.likeProperty == 1 ?
                <i className="fa fa-heart" style={{ color: "#eb4d61" }}></i>
                :
                <i className="fa fa-heart-o"></i>
              }
            </p>
            <p className="author"><i className="fa fa-map-marker" /> {item.vendedor}</p>
            <h6 className="title" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <a style={{ cursor: 'pointer' }} onClick={() => {
                handleViewProperty(item.id, item.url);
              }}>
                {item.externalTitle}
              </a>
            </h6>
            <h6 className="price">{`${item.precio}`}</h6>
            <p>
              {item.description.replace(/<br\s*[\/]?>/gi, "").substr(0, 40)}...
              {/* {wordWrap(item.description, 30).replace(/<br\s*[\/]?>/gi, "\n").substr(0, 65)}... */}
            </p>
            <ul className="info-list">
              {item.items.map((items, i) =>
                <li key={i} ><i className={items.icon} /> {items.icon.indexOf("fa-check") >= 0 ? "" : items.value} {items.title}</li>
              )}
            </ul>
            <ul className="contact-list text-center">
              <li>
                <a className="btn btn-yellow" style={{ cursor: 'pointer', color: 'white' }} onClick={() => {
                  handleViewProperty(item.id, item.url);
                }}>
                  Ver detalles
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    });
  } else if (Array.isArray(data) && design == 2) {
    strPropiedades = data.map((item, i) => {
      let flagPlan = `thumb `;
      if (item.plan === 1) {
        flagPlan += `iconOro`;
      } else if (item.plan === 2) {
        flagPlan += `iconPlata`;
      }
      return <div key={i} className="col-12 design-property-item">
        <div className="single-feature row">
          <div className="col-5">
            <a style={{ cursor: 'pointer' }} onClick={() => {
              handleViewProperty(item.id, item.url);

            }}>
              <div className={flagPlan}>
                <img src={`${item.image.principal}`} alt={item.externalTitle} />
              </div>
            </a>
          </div>
          <div className="details col-7">
            <div style={{ display: 'flex' }} >
              <p className={item.likeProperty == 1 ? "feature-logo-design social-icon like-active" : "feature-logo-design social-icon"} style={{ fontSize: "25px", paddingTop: "3px", cursor: "pointer" }}
                onClick={() => {
                  if (localStorage.getItem('cribbeeUsuario') == null) {
                    setShow(true);
                  } else {

                    handleLikeProperty(item.id);
                  }

                }}
                ref={
                  el => {
                    contentLiked.current = {
                      ...contentLiked.current,
                      [`liked${item.id}`]: el
                    };
                  }
                }
              >
                {item.likeProperty == 1 ?
                  <i className="fa fa-heart" style={{ color: "#eb4d61" }}></i>
                  :
                  <i className="fa fa-heart-o"></i>
                }


              </p>
              <p style={{ fontSize: "25px", paddingTop: "3px" }}>
                &nbsp;
                &nbsp;
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`https://www.casyter.com/#/detalle-propiedad/${item.id}`)}`} target="_new_Facebook">
                  <i className="fa fa-facebook" />
                </a>
                &nbsp;
                &nbsp;
                <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(`https://www.casyter.com/#/detalle-propiedad/${item.id}`)}&text=${item.externalTitle}`} target="_new_Twitter">
                  <i className="fa fa-twitter" />
                </a>
                &nbsp;
                &nbsp;
                <a onClick={() => {
                  var win = window.open(`https://api.whatsapp.com/send/?phone&text=${encodeURIComponent(`https://www.casyter.com/#/detalle-propiedad/${item.id}`)}&app_absent=0`, '_new_Whast');
                  win.focus();
                }} style={{ cursor: "pointer" }}>
                  <i className="fa fa-whatsapp" />
                </a>
                &nbsp;
                &nbsp;
                <a onClick={() => copyToClipboard(`https://www.casyter.com/#/detalle-propiedad/${item.id}`, 1)} style={{ cursor: "pointer" }}>
                  <i className="fa fa-copy" />
                </a>

              </p>
            </div>

            <p className="author"><i className="fa fa-map-marker" /> {item.vendedor}</p>
            <h6 className="title" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              <a style={{ cursor: 'pointer' }} onClick={() => {
                handleViewProperty(item.id, item.url);
              }}>
                {item.externalTitle}
              </a>
            </h6>
            <h6 className="price">{`${item.precio}`}</h6>
            <p>
              {item.description.replace(/<br\s*[\/]?>/gi, "").substr(0, 120)}...
              {/* {wordWrap(item.description, 30).replace(/<br\s*[\/]?>/gi, "\n").substr(0, 65)}... */}
            </p>
            <ul className="info-list">
              {item.items.map((items, i) =>
                <li key={i} ><i className={items.icon} /> {items.value} {items.title}</li>
              )}
            </ul>
            <ul className="contact-list text-center">
              <li>
                <a className="btn btn-yellow" style={{ cursor: 'pointer', color: 'white' }} onClick={() => {
                  handleViewProperty(item.id, item.url);
                }}>
                  Ver detalles
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    });
  }
  return (<>
    {strPropiedades}

    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="text-right pt-4 pr-4">
        <button type="button" className="close" onClick={() => { setShow(false); }}><span aria-hidden="true">&times;</span></button>
      </div>
      <Login />
    </Modal>
  </>
  );

}

const copyToClipboard = (url, showAlert) => {
  navigator.clipboard.writeText(url)
  if (showAlert == 1) {
    toast.success(`Copiado`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: false,
      theme: "light",
    });
  }
};

export default PropiedadesRecomendadas