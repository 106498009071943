import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import app from "../firebase/firebase";

const signInFirebase = () => {

    const signInProvider = async (providerName) => {
        const provider = providerName === 'google' ? new GoogleAuthProvider() : new FacebookAuthProvider();
        const auth = getAuth(app);
        const result = await signInWithPopup(auth, provider)
        // const credential = providerName === 'google' ? GoogleAuthProvider.credentialFromResult(result) : FacebookAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        return result.user
    }
    return {
        signInProvider
    }
};

export default signInFirebase;
