import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropertyDetailsSection from './section-components/property-details';
import PropiedadesRecomendadas from './section-components/propiedades-recomendadas';
import Footer from './global-components/footer';
import { cribbeeAPI } from '../api/cribbeeAPI';
import { reauthenticateWithRedirect } from 'firebase/auth';

const DetallePropiedad = (props) => {
    const { match: { params: { id } } } = props;

    const [propiedad, setPropiedad] = useState({
        id: 0,
        imagesPropiedad: [],
        precio: 0.00,
        externalTitle: "",
        description: "",
        direccion: {
            ocultar: false,
            calle: "",
            colonia: "",
            noExt: "",
            cp: "",
            ciudad: "",
            estado: "",
            lat: 0,
            lng: 0
        },
        vendedor: {
            image: "",
            nombre: "",
            tel: "",
            email: "",
            direccion: "",
            empresa: "",
            website: "",
        },
        itemsProperty: [],
        servicios: [],
        espacios: [],
        amenidades: [],
        planos: [],
        recorrido: [],
        videos: [],
    });

    useEffect(() => {
        const getPropiedad = async () => {
            try {
                const { data: { data: info } } = await cribbeeAPI.get(`/list-properties?propiedad=${id}&users=${localStorage.getItem('cribbeeUsuario')}`);
                if (info.length === 0) window.location.href = '/';

                const arrayServices = [];
                if (info[0].servicios.length > 0) {
                    for (var indexS = 0; indexS < info[0].servicios.length; indexS++) {
                        if (typeof info[0].servicios[indexS] !== 'undefined') {
                            arrayServices.push({ icon: 'fa fa-check', value: info[0].servicios[indexS].service, title: info[0].servicios[indexS].service });
                        }
                    }
                }

                const arrayAmenidades = [];
                if (info[0].amenidades.length > 0) {
                    for (var indexA = 0; indexA < info[0].amenidades.length; indexA++) {
                        if (typeof info[0].amenidades[indexA] !== 'undefined') {
                            arrayAmenidades.push({ icon: 'fa fa-check', value: info[0].amenidades[indexA].amenitie, title: info[0].amenidades[indexA].amenitie });
                        }
                    }
                }

                const arrayEspacios = [];
                if (info[0].espacios.length > 0) {
                    for (var indexS = 0; indexS < info[0].espacios.length; indexS++) {
                        if (typeof info[0].espacios[indexS] !== 'undefined') {
                            arrayEspacios.push({ icon: 'fa fa-check', value: info[0].espacios[indexS].space, title: info[0].espacios[indexS].space });
                        }
                    }
                }

                const arrayImagenes = [];
                if (info[0].image.length > 0) {
                    for (var indexS = 0; indexS < info[0].image.length; indexS++) {
                        if (typeof info[0].image[indexS] !== 'undefined') {
                            arrayImagenes.push(info[0].image[indexS].large_source_path);
                        }
                    }
                    
                }
                if (arrayImagenes.length === 0) {
                    arrayImagenes.push("https://other.casyter.com/large-image.png");
                }

                const arrayPlanos = [];
                if (info[0].planos.length > 0) {
                    for (var indexS = 0; indexS < info[0].planos.length; indexS++) {
                        if (typeof info[0].planos[indexS] !== 'undefined') {
                            arrayPlanos.push(info[0].planos[indexS].plans);
                        }
                    }
                }

                const arrayVideos = [];
                if (info[0].videos.length > 0) {
                    for (var indexS = 0; indexS < info[0].videos.length; indexS++) {
                        if (typeof info[0].videos[indexS] !== 'undefined') {
                            arrayVideos.push(info[0].videos[indexS].url);
                        }
                    }
                }

                const arrayRecorrido = [];
                if (info[0].recorrido.length > 0) {
                    for (var indexS = 0; indexS < info[0].recorrido.length; indexS++) {
                        if (typeof info[0].recorrido[indexS] !== 'undefined') {
                            arrayRecorrido.push(info[0].recorrido[indexS].travel);
                        }
                    }
                }


                const itemsProperty = [];

                info[0].rooms !== null && itemsProperty.push({ icon: "fa fa-check", value: info[0].rooms, title: "Recámaras" });
                info[0].bathrooms !== null && itemsProperty.push({ icon: "fa fa-check", value: info[0].bathrooms, title: "Baños" });
                info[0].halfBath !== null && itemsProperty.push({ icon: "fa fa-check", value: info[0].halfBath, title: "1/2 Baños" });
                info[0].square_meters_build !== null && itemsProperty.push({ icon: "fa fa-check", value: info[0].square_meters_build, title: "M2 Construcción" });
                info[0].square_meters_land !== null && itemsProperty.push({ icon: "fa fa-check", value: info[0].square_meters_land, title: "M2 Terreno" });
                info[0].m2Deep !== null && itemsProperty.push({ icon: "fa fa-check", value: info[0].m2Deep, title: "M2 Fondo" });
                info[0].m2Front !== null && itemsProperty.push({ icon: "fa fa-check", value: info[0].m2Front, title: "M2 Frente" });
                info[0].parking_lots !== null && itemsProperty.push({ icon: "fa fa-check", value: info[0].parking_lots, title: "Estacionamiento" });
                info[0].floor !== null && itemsProperty.push({ icon: "fa fa-check", value: info[0].floor, title: "No. de plantas" });
                info[0].maintenance !== null && itemsProperty.push({ icon: "fa fa-check", value: info[0].maintenance, title: "Mantenimiento" });

                if (info[0].regularIrregular !== null) {
                    const valueRegularIrregular = info[0].regularIrregular == 1 ? "Regular" : "Irregular";
                    itemsProperty.push({ icon: "fa fa-check", value: valueRegularIrregular, title: "Regular o Irregular" });
                }
                if (info[0].antiquity !== null) {
                    const valueAntiquity = info[0].antiquity === 1 ? "A estrenar" : info[0].numYear !== '' ? `${info[0].numYear} Años` : "0 Años";
                    itemsProperty.push({ icon: "fa fa-check", value: valueAntiquity, title: "Antigüedad" });
                }

                setPropiedad({
                    id: id,
                    imagesPropiedad: arrayImagenes,
                    precio: info[0].price,
                    externalTitle: info[0].title,
                    description: info[0].description,
                    direccion: {
                        ocultar: info[0].hideAddress == 0 ? false : true,
                        calle: info[0].street,
                        colonia: info[0].colony,
                        noExt: info[0].externalNumber,
                        cp: info[0].cp,
                        ciudad: info[0].city,
                        estado: info[0].state,
                        lat: info[0].latitude,
                        lng: info[0].longitude
                    },
                    vendedor: {
                        // image: process.env.PUBLIC_URL + "/assets/img/cribbee-icon.png",
                        image: info[0].propietario_image,
                        nombre: info[0].propietario,
                        tel: info[0].propietario_tel,
                        empresa: info[0].propietario_company,
                        website: info[0].propietario_website,
                        email: "",
                        direccion: "",
                    },
                    itemsProperty: itemsProperty,
                    servicios: arrayServices,
                    espacios: arrayEspacios,
                    amenidades: arrayAmenidades,
                    planos: arrayPlanos,
                    recorrido: arrayRecorrido,
                    videos: arrayVideos,
                    plan: info[0].plan_id,
                    likeProperty: info[0].likeProperty,
                });
            } catch (error) {
                console.error(error)
            }
        }
        getPropiedad();

        window.scrollTo({
            top: 400,
            behavior: "smooth"
        });
    }, [id]);

    const [data, setData] = useState([]);

    useEffect(() => {
        const listProperties = async () => {
            try {
                const { data: { data: info } } = await cribbeeAPI.get(`/list-properties?limit=${5}`);
                const otras = [];

                for (var index = 1; index < info.length; index++) {
                    const arrayServices = [];
                    if (info[index].rooms != null) {
                        arrayServices.push({ icon: 'fa fa-bed', value: info[index].rooms, title: "" });
                    }
                    if (info[index].bathrooms != null) {
                        arrayServices.push({ icon: 'fa fa-bath', value: info[index].bathrooms, title: "" });
                    }
                    if (info[index].square_meters_build != null) {
                        arrayServices.push({ icon: 'fa fa-object-ungroup', value: info[index].square_meters_build, title: "mts2" });
                    }
                    if (info[index].square_meters_build != null) {
                        arrayServices.push({ icon: 'fa fa-car', value: info[index].parking_lots, title: "" });
                    }


                    if (arrayServices.length == 0) {
                        for (var indexS = 0; indexS < 2; indexS++) {
                            if (typeof info[index].servicios[indexS] !== 'undefined') {
                                arrayServices.push({ icon: 'fa fa-check', value: info[index].servicios[indexS].id, title: info[index].servicios[indexS].service });
                            }
                        }
                    }
                    if (arrayServices.length == 0) {
                        if (info[index].amenidades.length > 0) {
                            for (var indexA = 0; indexA < 2; indexA++) {
                                if (typeof info[index].amenidades[indexA] !== 'undefined') {
                                    arrayServices.push({ icon: 'fa fa-check', value: info[index].amenidades[indexA].id, title: info[index].amenidades[indexA].amenitie });
                                }
                            }
                        }
                    }

                    if (arrayServices.length == 0) {
                        if (info[index].espacios.length > 0) {
                            for (var indexS = 0; indexS < 2; indexS++) {
                                if (typeof info[index].espacios[indexS] !== 'undefined') {
                                    arrayServices.push({ icon: 'fa fa-check', value: info[index].espacios[indexS].id, title: info[index].espacios[indexS].space });
                                }
                            }
                        }
                    }

                    otras.push({
                        image:
                        {
                            principal: info[index].image[0] != undefined ? info[index].image[0].small_source_path : "https://other.casyter.com/small-image.png",
                            secundarias: []
                        },
                        vendedor: `${info[index].city}, ${info[index].state}`,
                        externalTitle: info[index].title,
                        precio: info[index].price,
                        items: arrayServices,
                        url: `/detalle-propiedad/${info[index].id}`,
                        description: info[index].description,
                        plan: info[index].plan_id,
                        id: info[index].id,
                        likeProperty: info[index].likeProperty,
                    })
                }

                setData(otras);
            } catch (error) {
                console.error(error)
            }
        }
        listProperties();
    }, [id]);

    // console.log(screen.width);
    // 1199

    return <div>

        <Navbar />
        <PageHeader headertitle="Detalles de la Propiedad" />
        <PropertyDetailsSection propiedad={propiedad} />
        <div className="recommended-area pd-top-90 pd-bottom-70">
            <div className="container">
                <div className="section-title">
                    <h2 className="title">Propiedades Recomendadas</h2>
                    <Link className="btn-view-all" to="/propiedades">Ver Todas</Link>
                </div>
                <div className="row">
                    <PropiedadesRecomendadas data={data} />
                </div>
            </div>
        </div>
        <Footer />
    </div>
}

export default DetallePropiedad

