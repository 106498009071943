
import React from 'react'
import { Route } from 'react-router'

import inicio from './inicio';
import propiedades from './propiedades';
import paquetes from './paquetes';
import Registraion from './registration';
import Blog from './blog';
import AddNew from './publicar-propiedad';
import DetallePropiedad from './detalle-propiedad';
import SignIn from './iniciar-sesion';
import RecoverPassword from './recover-password';
import NewPassword from './new-password';
import terminos from './terminos-condiciones';
import aviso from './aviso-privacidad';
import cribbee from './cribbee';
import faq from './faq';
import contacto from './contacto';
import { Redirect } from 'react-router-dom';

export const PublicRoutes = () => {
    return (
        <>
            {/* Ruta de inicio */}
            <Route path="/" exact component={inicio} />

            {/* Ruta de Propiedades */}
            <Route path="/propiedades" component={propiedades} />

            {/* Ruta de Paquetes */}
            <Route path="/paquetes" component={paquetes} />

            {/* Ruta de Blog */}
            <Route path="/blog" component={Blog} />

            {/* Ruta de Iniciar Sesión */}
            <Route path="/iniciar-sesion" component={SignIn} />

            {/* Ruta de Recuperar Contraseña */}
            <Route path="/recover-password" component={RecoverPassword} />

            {/* Ruta de Nueva Contraseña */}
            <Route path="/new-password/:token" component={NewPassword} />

            {/* Ruta de Detalle Propiedad */}
            <Route path="/publicar-propiedad" component={AddNew} />

            {/* Ruta de Publicar Propiedad */}
            <Route path="/detalle-propiedad/:id" component={DetallePropiedad} />

            {/* Ruta de Registrar */}
            <Route path="/registration" component={Registraion} />

            {/* Ruta de terminos */}
            <Route path="/terminos-condiciones" component={terminos} />

            {/* Ruta de aviso de privacidad */}
            <Route path="/aviso-privacidad" component={aviso} />

            {/* Ruta de aviso de cribbee */}
            <Route path="/cribbee" component={cribbee} />

            {/* Ruta de Contacto */}
            <Route path="/contacto" component={contacto} />

            {/* Ruta de faq */}
            <Route path="/faq" component={faq} />
        </>
    )
}
