import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { cribbeeAPI } from './../api/cribbeeAPI';
const RecoverPassword = (props) => {
	
	const handleLoadingEmail = (e) => {
		e.preventDefault();
		const resolveWithSomeData = new Promise((resolve, reject) => setTimeout(async () => {
			let { status, info } = await postSendEmail();
			if (status) {
				resolve(info)
			} else {
				reject(info)
			}
		}, 1000));
		toast.promise(
			resolveWithSomeData,
			{
				pending: {
					render() {
						return "Enviando Correo"
					},
					icon: true,
				},
				success: {
					render({ data }) {
						return data
					},
					icon: true,
				},
				error: {
					render({ data }) {
						return data
					}
				}
			}
		)
	}
	const postSendEmail = async () => {
		try {
			const formData = new FormData(document.querySelector(`form[name="form-send-email"]`));
			const { status, message, data } = await cribbeeAPI.post(`/recover-password-email`, { email: formData.get("email") });
			return { status: true, info: "Se ha enviado el correo" }
		} catch (error) {
			return { status: false, info: error.response.data.message }
		}
	}
	return (
		<>
			<Navbar />
			<PageHeader headertitle="Recuperar Contraseña" />
			<div className="contact-area pd-top-100 pd-bottom-65">
				<div className="container">
					<div className="row d-flex justify-content-center">
						<div className="col-lg-5 text-center">

							<div className="contact-form-wrap contact-form-bg">
								<form onSubmit={handleLoadingEmail} name="form-send-email">
									<h3>Recuperar Contraseña</h3>
									<br />
									<div className="rld-single-input">
										<input type="email" name="email" placeholder="Ingrese su Correo Electrónico" required />
									</div>
									<br />
									<div className="btn-wrap text-center">
										<button className="btn btn-yellow mt-0 mr-2" type="submit">Enviar</button>
										<Link className="btn btn-orange" to="/iniciar-sesion">Cancelar</Link>
									</div>

								</form>

							</div>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer style={{ width: "auto", marginTop: "180px" }} />
			<Footer />
		</>
	);
}

export default RecoverPassword

