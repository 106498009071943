import React, { useState, useEffect } from 'react';
import { cribbeeAPI } from '../../api/cribbeeAPI';
import { Link } from 'react-router-dom';

const Service = () => {
  const [{ contenido1, imagen1, url1, contenido2, imagen2, url2, contenido3, imagen3, url3 }, setServios] = useState({ contenido1: '', imagen1: '', url1: '', contenido2: '', imagen2: '', url2: '', contenido3: '', imagen3: '', url3: '' });

  useEffect(() => {
    const listProperties = async () => {
      try {
        const { data: { data: info } } = await cribbeeAPI.get(`/list-servicios`);



        setServios({
          contenido1: info[0].contenido1,
          imagen1: info[0].imagen1,
          url1: info[0].url1,
          contenido2: info[0].contenido2,
          imagen2: info[0].imagen2,
          url2: info[0].url2,
          contenido3: info[0].contenido3,
          imagen3: info[0].imagen3,
          url3: info[0].url3
        });
      } catch (error) {
        console.error(error)
      }
    }
    listProperties();
  }, []);
  let publicUrl = process.env.PUBLIC_URL + '/';
  return <>
    < div className="service-area h1-service-slider-area" >
      <div className="container mt-5">
        <div className="row d-flex justify-content-center">
          <div className="col-md-3 col-lg-3 mb-2 text-center">
            <div className="item">
              <div className="single-service text-center" >
                <div className="thumb double-img">
                  <img src="assets/img/cribbee-icon.png" />
                </div>
                <div className="details readeal-top">
                  <h4><Link style={{ fontWeight: "600", textShadow: "2px 2px 3px #9f9f9f" }} to="/cribbee">¿Qué es Casyter?</Link></h4>
                  <Link className="btn btn-yellow" style={{ padding: "0px 5px 0px 5px", height: "25px", lineHeight: "25px" }} to="/cribbee"> Conoce mas...<i className="la la-arrow-right" /></Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-lg-3 mb-2 text-center">

            <div className="item">
              <div className="single-service text-center" style={{ backgroundImage: `url('${imagen1}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <br />
                <div className="details readeal-top">
                  <h4><a style={{ fontWeight: "600", textShadow: "2px 2px 3px #9f9f9f" }} href={url1}>{contenido1.substr(0, 46)}...</a></h4>
                  <a className="btn btn-yellow" style={{ padding: "0px 5px 0px 5px", height: "25px", lineHeight: "25px" }} href={url1}> Conoce mas...<i className="la la-arrow-right" /></a>
                </div>
                <br />
              </div>
            </div>
          </div>
          <div className="col-md-3 col-lg-3 mb-2 text-center">

            <div className="item">
              <div className="single-service text-center" style={{ backgroundImage: `url('${imagen2}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <br />
                <div className="details readeal-top">
                  <h4><a style={{ fontWeight: "600", textShadow: "2px 2px 3px #9f9f9f" }} href={url2}>{contenido2.substr(0, 46)}...</a></h4>
                  <a className="btn btn-yellow" style={{ padding: "0px 5px 0px 5px", height: "25px", lineHeight: "25px" }} href={url2}> Conoce mas...<i className="la la-arrow-right" /></a>
                </div>
                <br />
              </div>
            </div>
          </div>
          <div className="col-md-3 col-lg-3 mb-2 text-center">

            <div className="item">
              <div className="single-service text-center" style={{ backgroundImage: `url('${imagen3}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <br />
                <div className="details readeal-top">
                  <h4><a style={{ fontWeight: "600", textShadow: "2px 2px 3px #9f9f9f" }} href={url3}>{contenido3.substr(0, 40)}...</a></h4>
                  <a className="btn btn-yellow" style={{ padding: "0px 5px 0px 5px", height: "25px", lineHeight: "25px" }} href={url3}> Conoce mas...<i className="la la-arrow-right" /></a>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>


}

export default Service
