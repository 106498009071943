import React, { useEffect, useState, useRef, useContext } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import { Link } from 'react-router-dom';

const Home_V1 = () => {
    const [show, setShow] = useState(false);
    //Planes
    const arrayPriceTable = [
        {
            id: 1,
            plan: "ORO",
            color: "#FFB71A",
            precio: 94.82,
            beneficios: ["· Posicionamiento Alto", "· Propiedad recomendada en búsquedas", "· Links para website", "· Rotación de propiedades en pagina de inicio"],
            url: "assets/img/oro.png",
            paquetes: [
                {
                    id: 1,
                    color: "#F3F6F9",
                    precio: 189.65,
                    tiempo: "2 MESES",
                },
                {
                    id: 2,
                    color: "#FFFFFF",
                    precio: 379.31,
                    tiempo: "4 MESES",
                },
                {
                    id: 3,
                    color: "#F3F6F9",
                    precio: 568.96,
                    tiempo: "6 MESES",
                },
            ]
        },
        {
            id: 2,
            plan: "PLATA",
            color: "#b7b7b7",
            precio: 64.65,
            beneficios: ["· Posicionamiento Medio", "· Propiedad recomendada en búsquedas", "· Links para website"],
            url: "assets/img/plata.png",
            paquetes: [
                {
                    id: 4,
                    color: "#F3F6F9",
                    precio: 129.31,
                    // precio: 12,
                    tiempo: "2 MESES",
                },
                {
                    id: 5,
                    color: "#FFFFFF",
                    precio: 258.62,
                    tiempo: "4 MESES",
                },
                {
                    id: 6,
                    color: "#F3F6F9",
                    precio: 387.93,
                    tiempo: "6 MESES",
                },
            ]
        },
        {
            id: 3,
            plan: "MIEL",
            color: "#CD7920",
            precio: "Gratis",
            beneficios: ["· Posicionamiento Mínimo"],
            url: "assets/img/miel.png",
            paquetes: [
                {
                    id: 7,
                    color: "#F3F6F9",
                    precio: "Gratis",
                    tiempo: "2 MESES",
                },
                {
                    id: 8,
                    color: "#FFFFFF",
                    precio: "Gratis",
                    tiempo: "4 MESES",
                },
                {
                    id: 9,
                    color: "#F3F6F9",
                    precio: "Gratis",
                    tiempo: "6 MESES",
                },
            ]
        }
    ];

    const [planes, setPlanes] = useState("");
    const [infoPaquetes, setInfoPaquetes] = useState("");
    const [selectedPackage, setSelectedPackage] = useState("");

    const fieldPaquetes = useRef({});
    const buttonRegistro = useRef(false);

    const handlePlanes = (plan, index) => {
        document.getElementById("contentPaquetes").scrollIntoView({ block: "center", behavior: "smooth" });
        setPlanes(plan)
        setInfoPaquetes(arrayPriceTable[index]);

    }


    const handleSelectedPackage = (id, index) => {
        document.getElementById(`radio${index}`).click();
        setSelectedPackage(arrayPriceTable.map((p) => { if (p.id == infoPaquetes.id) return p.paquetes[index]; }).filter((valor) => { if (valor !== undefined) return valor })[0])
    }

    return (
        <div>
            <Navbar />
            <PageHeader headertitle="Paquetes Casyter" subheader="Paquetes" />
            <div className="container py-5">
                {/* 
                <div className="tab-pane fade show active">

                    <div className="row pt-3">
                        <div className="col-sm-12">
                            <div className="section-title">
                                <h3 className="text-center">Planes Casyter</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-3">
                        {arrayPriceTable.map(({ id, plan, color, precio, beneficios, url }, index) =>
                            <div key={index} className="col-lg-4 col-sm-6" style={{ paddingRight: "30px", paddingLeft: "30px" }}>
                                <div className={`single-pricing text-center border-${plan}`}>
                                    <div className="thumb double-img">
                                        <img width={30} src={`${process.env.PUBLIC_URL}/${url}`} />
                                    </div>
                                    <div className="details">
                                        <h4 className="title" style={{ color: color }}>{plan}</h4>
                                        <h3 className="price" style={{ color: color }}> {(typeof precio == 'string') ? precio : `$ ${parseFloat(precio).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} + IVA`}</h3>
                                        <h6>Beneficios</h6>
                                        <ul>

                                            {beneficios.map((beneficios, i) =>
                                                <li key={i}>{beneficios}</li>
                                            )}

                                        </ul>
                                        <button className={(planes == id) ? `btn btn-${plan}` : `btn hover-${plan}`} type="button" onClick={() => { handlePlanes(id, index); }}>Seleccionar</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-12" id="contentPaquetes">
                        {infoPaquetes && <h4 className="text-center">Paquetes</h4>}
                        {infoPaquetes &&
                            infoPaquetes.paquetes.map(({ id, color, tiempo, precio }, i) =>
                                <div key={i} className="d-flex justify-content-center  font-weight-bold text-center" style={{ cursor: "pointer" }} onClick={e => { handleSelectedPackage(id, i); }}>

                                    <div className="col-sm-3 py-3" style={{ background: color }}>{tiempo}</div>
                                    <div className="col-sm-2 py-3" style={{ background: color }}>
                                        {(typeof precio == 'string') ? precio : "$ " + parseFloat(precio).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + " + IVA"}
                                    </div>
                                </div>
                            )
                        }

                    </div> 
                </div>
                */}
                <Link to="/publicar-propiedad">
                    <img src="https://other.casyter.com/publicar-propiedad.png"/>
                </Link>
                

            </div>
            <Footer />

        </div>

    );
}

export default Home_V1


