import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import { cribbeeAPI } from '../api/cribbeeAPI';
const aviso = () => {
    return (
        <>
            <Navbar />
            <PageHeader headertitle="Aviso de privacidad" subheader="Aviso de privacidad" />
            <div className="properties-area pd-top-92 pd-bottom-92">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 row">
                            <div className="col-md-12 text-center">
                                <h3>AVISO DE PRIVACIDAD</h3>
                            </div>
                            &nbsp;
                            <div className="col-md-12 text-justify">
                                <h4>IDENTIDAD Y DOMICILIO DEL RESPONSABLE. </h4>
                                <p></p>
                                <p>En Casyter con domicilio en  los datos personales y personales sensibles, de nuestros clientes y posibles clientes (en adelante “datos personales”), son tratados de forma estrictamente privada y confidencial, por lo que la obtención, tratamiento, transferencia y ejercicio de los derechos derivados de dichos datos personales, se hace mediante un uso adecuado, legítimo y lícito, salvaguardando permanentemente los principios de licitud, consentimiento, calidad, información, proporcionalidad, responsabilidad, lealtad y finalidad, de conformidad con lo dispuesto por la Ley Federal de Protección de Datos
Personales en Posesión de los Particulares, su Reglamento y disposiciones secundarias.</p>
                                <p>En CASYTER estamos comprometidos con salvaguardar la privacidad de sus datos personales y mantener una relación estrecha y activa con nuestros clientes y posibles clientes por eso además de éste Aviso, aplicamos nuestra Política de Privacidad, te invitamos a conocerla.</p>
                                <p>A continuación, señalamos expresa y limitativamente los datos que podremos recabar de usted como cliente y posible cliente:</p>
                                <p>
                                Datos Personales de identificación y contacto:
                                </p>
                                <ul>
                                    <li>Nombre, apellido paterno y materno, g nero, edad, fecha de nacimiento, domicilio, c digo postal, n mero telef nico, n mero celular y correo electr nico.</li>
                                </ul>
                                <p>
                                Con respecto de los datos personales antes mencionados, se garantiza un tratamiento, ya sea directa o indirectamente a través de CASYTER, sus subsidiarias, afiliadas o empresas relacionadas, así como, en su caso, por las autoridades competentes, para las finalidades que dieron origen y son necesarias para la existencia, mantenimiento y cumplimiento de la relación jurídica con sus clientes, que son las siguientes:
                                </p>
                                <ul>
                                <li>Para dar cumplimiento a la relación jurídica establecida con usted derivado de la
adquisición de alguno de los servicios brindados por CASYTER.
                                </li>
                                <li>
                                Para atender quejas o recomendaciones respecto de la aplicación de los servicios brindados por CASYTER.
                                </li>
                                <li>
                                Mercadotecnia, publicidad, prospección comercial, evaluación de nuestros servicios.
                                </li>
                                <li>
                                Para fines comerciales y de prospección comercial.
                                </li>
                                </ul>
                                <p>
                                La negativa otorgada para el tratamiento de sus datos personales para las finalidades señaladas previamente, no podrá ser un motivo para que le neguemos los servicios que adquiere o contrata con nosotros.
                                </p>
                                <p>
                                Derivado de lo anterior, CASYTER, le informa que, en caso de que usted no desee que sus datos personales sean tratados para las finalidades que se precisan en el presente documento, le solicitamos nos envíe un correo electrónico a la dirección hola@casyter.com, a efecto de que se documente dicha situación.
                                </p>
                                <p>
                                Los datos personales que usted proporcione a CASYTER serán tratados exclusivamente para las finalidades descritas, se les tratará de forma confidencial y exclusivamente en la medida en que éstos sean estrictamente necesarios para cumplir la finalidad para la cual
hubieren sido recabados.
                                </p>
                                <p>
                                Para prevenir el acceso no autorizado a sus datos personales y con el fin de asegurar que la información sea utilizada para los fines establecidos en este aviso de privacidad, hemos implementado procedimientos físicos, electrónicos y administrativos, utilizando tecnologías avanzadas que limitan el uso o divulgación de sus datos, permitiéndonos tratarlos debidamente y prohibiendo extraer de las oficinas de CASYTER, de forma no autorizada, cualquier tipo de información que contenga datos personales.
                                </p>
                                <h4>
                                TRANSFERENCIAS DE DATOS PERSONALES A TERCEROS.
                                </h4>
                                <p>
                                Por medio de este aviso de privacidad, CASYTER le informa que podrá transferir sus datos personales de identificación y contacto (no sensibles) a terceros nacionales o extranjeros; tales como a sus subsidiarias, afiliadas o empresas relacionadas y proveedores de servicios con los que mantenga una relación jurídica, para las siguientes finalidades:
                                </p>
                                <ul>
                                    <li>
                                    Mercadotecnia, publicidad y prospección comercial.
                                    </li>
                                </ul>
                                <p>
                                Derivado de lo anterior CASYTER le informa que, en caso de que usted no desee que sus datos personales sean transferidos a los terceros que se mencionan en el presente apartado y para las finalidades aquí descritas, le solicitamos atentamente nos envíe un correo electrónico a la dirección hola@casyter.com, a efecto de que se documente esta situación y se tomen las medidas necesarias a fin de que sus datos no sean utilizados para los fines antes descritos, de lo contrario entenderemos que ha manifestado su pleno consentimiento para su transferencia en los términos precisados en el aviso de privacidad
que nos ocupa.
                                </p>
                                <p>
                                Asimismo, le informamos que en caso de que CASYTER, pretenda transferir sus datos personales sensibles (en caso de haberlos proporcionado) a los terceros aquí precisados para las finalidades anteriormente descritas en el presente apartado, previo a su transferencia solicitará su consentimiento expreso y por escrito, de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás disposiciones secundarias obligatorias, de lo contrario no hará uso de los mismos para estos fines.
                                </p>
                                <p>
                                En este sentido, CASYTER le informa que, de conformidad con lo dispuesto por el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares,
 podrá transferir sus datos personales, sin su consentimiento, en los casos que a continuación se especifican:
                                </p>
                                <ul>
                                    <li>
                                    Cuando la transferencia esté prevista en una Ley o Tratado en los que México sea parte.
                                    </li>
                                    <li>
                                    Cuando la transferencia sea necesaria para la prevención o el diagnóstico médico, la prestación de asistencia sanitaria, tratamiento médico o la gestión de servicios sanitarios.
                                    </li>
                                    <li>
                                    Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el control común del responsable, o a una sociedad matriz o a cualquier sociedad del mismo grupo de CASYTER que opere bajo los mismos
procesos y políticas internas.
                                    </li>
                                    <li>
                                    Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés del titular de datos, por CASYTER y un tercero.
                                    </li>
                                    <li>
                                    Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, o para la procuración o administración de justicia.
                                    </li>
                                    <li>
                                    Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial.
                                    </li>
                                    <li>
                                    Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre CASYTER y el titular de datos.
                                    </li>
                                </ul>
                                <h4>MECANISMOS Y PROCEDIMIENTOS PARA LA REVOCACIÓN DEL CONSENTIMIENTO AL TRATAMIENTO DE DATOS PERSONALES Y/O EJERCICIO DE DERECHOS ARCO.</h4>
                                <p>
                                En todo momento usted podrá revocar el consentimiento que nos ha otorgado para el tratamiento de sus datos personales, a fin de que dejemos de hacer uso de los mismos. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir su uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales.
                                </p>
                                <p>
                                Para revocar su consentimiento deberá solicitar el <strong>“FORMATO DE SOLICITUD DE DERECHOS ARCO Y REVOCACIÓN DE AUTORIZACIÓN PARA TRATAMIENTO Y TRANSFERENCIA DE DATOS PERSONALES.”</strong> el cual usted podrá solicitar, a través del correo electrónico hola@casyter.com.
                                </p>
                                <p>
                                Asimismo, usted tiene derecho a acceder, rectificar, cancelar u oponerse al tratamiento de sus datos personales (derechos ARCO). En este sentido, como titular de sus datos personales tiene el derecho de conocer qué datos personales mantiene CASYTER, para qué los utilizamos y las condiciones del uso que les damos (Acceso), así como a rectificarlos en caso de ser inexactos, desactualizados o incompletos (Rectificación),
instruirnos a cancelarlos cuando estos resulten excesivos o innecesarios para las finalidades descritas que justificaron su obtención, considere que los mismos no están siendo utilizados conforme a los principios, deberes y obligaciones previstas en la normativa aplicable (Cancelación), o bien, oponerse al uso de sus datos personales para fines específicos (Oposición), en los casos en que la Ley Federal de Protección de Datos Personales en Posesión de los Particulares así lo permita.
                                </p>
                                <p>
                                Para ejercer los derechos ARCO conforme lo mencionado en el párrafo anterior, podrá solicitar el “Formato de solicitud de derechos ARCO”, el cual podrá solicitar a través del correo electrónico hola@casyter.com
                                </p>
                                <p>
                                CASYTER atenderá su petición en los tiempos estipulados en las leyes aplicables y le informará de las consecuencias que deban producirse con motivo del ejercicio de su
derecho.
                                </p>
                                <p>
                                Si considera que no ha recibido una respuesta de CASYTER o bien la respuesta que se le dio no es satisfactoria, usted tiene el derecho de acudir al Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) a presentar una solicitud de protección de datos dentro de los quince días siguientes a la fecha en que venza el plazo para que CASYTER de respuesta a su solicitud de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento, así como del procedimiento que hemos instaurado para ello, o bien, de la respuesta que hubiésemos dado a la misma.
                                </p>
                                <h4>MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS PERSONALES</h4>
                                <p>
                                CASYTER le informa que, con el objeto de que usted pueda limitar el uso o divulgación de sus datos personales, ponemos a su disposición la dirección de correo electrónico hola@casyter.com, a efecto de ser inscrito en nuestro listado de exclusión denominado “Contactos que no desean envío de publicidad”, a fin de que sus datos personales no sean tratados para fines mercadotécnicos, publicitarios o de prospección comercial, así como para evaluación de nuestros servicios.
                                </p>
                                <h4>MODIFICACIONES Y CAMBIOS AL AVISO DE PRIVACIDAD</h4>

                                <p>
                                Este aviso de privacidad podrá ser modificado o actualizado de tiempo en tiempo por CASYTER. Dichas modificaciones serán informadas a través de una publicación en nuestro portal de internet posteriormente a su modificación.
                                </p>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default aviso

