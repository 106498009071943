import { useState } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import { cribbeeAPI } from '../api/cribbeeAPI';
import { types } from '../types/types';
import { authReducer } from './authReducer';

export const AuthContext = createContext({});

const authInicialState = {
    user: null,
    logged: false
}

export const AuthProvider = ({ children }) => {

    const [state, dispatch] = useReducer(authReducer, authInicialState);
    const [checking, setChecking] = useState(true);
    const [checkingLoading, setCheckingLoading] = useState("");

    useEffect(() => {
        checkToken();
    }, []);

    const checkToken = async () => {
        const token = localStorage.getItem('cribbeeToken');
        try {
            // No token, no autenticado
            const { data } = await cribbeeAPI.get('/verificar');
            setChecking(false);
            dispatch({
                type: types.login,
                payload: {
                    user: data.payload,
                    token: token
                }
            });
        } catch (error) {
            // console.log(error);
            setChecking(false);
            return dispatch({ type: types.logout });
        }

    }


    const signIn = async ({ email, password }) => {
        try {
            const { data: { data } } = await cribbeeAPI.post('/login', { email, password });
            dispatch({
                type: types.login,
                payload: {
                    user: data.user,
                    token: data.token
                }
            });
            localStorage.setItem('cribbeeToken', data.token);
            localStorage.setItem('cribbeeUsuario', data.user.id);
            localStorage.setItem('cribbeeImage', data.user.photo === null ? "https://other.casyter.com/cribbee-icon.png" : data.user.photo);
            localStorage.setItem('cribbeeFullName', data.user.fullname);
            localStorage.setItem('cribbeeEmail', data.user.email);
            localStorage.setItem('cribbeePhone', data.user.prefijo + data.user.phone);
            return { status: true, message: null };

        } catch (error) {
            if (error.response.data.message === 'Usuario no existe') {
                error.response.data.message = 'Nombre de usuario o contraseña no válidos.';
            }
            return error.response.data;
        }
    };

    const signInProvider = async (body) => {

        try {
            const { fullname, email, photo, unique_session_token, type_register } = body;
            const { data: { data } } = await cribbeeAPI.post('/login-social-network', { fullname, email, photo, unique_session_token, type_register, fcm_token: null });
            dispatch({
                type: types.login,
                payload: {
                    user: data.user,
                    token: data.token
                }
            });
            localStorage.setItem('cribbeeToken', data.token);
            localStorage.setItem('cribbeeUsuario', data.user.id);
            return { status: true, message: null };

        } catch (error) {
            if (error.response.data.message === 'Usuario no existe') {
                error.response.data.message = 'Nombre de usuario o contraseña no válidos.';
            }

            return error.response.data;
        }
    };

    const signUp = async ({ fullname, email, tel, password }) => {

        try {
            //check this to work with cribbee
            const { data: { data } } = await cribbeeAPI.post('/user',
                {
                    fullname: fullname,
                    email: email,
                    password: password,
                    phone: tel,
                    type_register: "manual"
                }
            );
            dispatch({
                type: 'signUp',
                payload: {
                    token: data.token,
                    user: data.user
                }
            });
            return { status: true, message: null };


        } catch (error) {
            return error.response.data;
        }

    };

    const logOut = async () => {
        localStorage.removeItem('cribbeeToken');
        localStorage.removeItem('cribbeeUsuario');
        localStorage.removeItem('cribbeeImage');
        localStorage.removeItem('cribbeeFullName');
        localStorage.removeItem('cribbeeEmail');
        localStorage.removeItem('cribbeePhone');
        dispatch({ type: 'logout' });
    };

    const checkedLoading = ({ text }) => {
        setCheckingLoading(text);
    }

    const Loading = ({ text }) => {
        return (
            <div className="py-2 loader-container text-center" style={{ height: "100vh", overflowY: "scroll", overflowX: "hidden", zIndex: "9999" }}>
                <div>
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="loader"></div>
                    </div>
                    <br />
                    <h4><b style={{ color: "#fbb138" }}>{text}</b></h4>
                </div>
            </div>
        )
    }

    // if (checkingLoading !== "") {
    //     return (
    //         <Loading text={checkingLoading}></Loading>
    //     );
    // }

    return (
        <AuthContext.Provider value={{
            ...state,
            signUp,
            signIn,
            logOut,
            signInProvider,
            checkToken,
            checkedLoading,
            checking,
            checkingLoading
        }}>
            {children}
        </AuthContext.Provider>
    )

}