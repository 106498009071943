import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import { cribbeeAPI } from '../api/cribbeeAPI';

import { Link } from 'react-router-dom';

const cribbee = () => {
    return (
        <>
            <Navbar />
            <PageHeader headertitle="¿Qué es Casyter?" subheader="¿Qué es Casyter?" />
            <div className="properties-area pd-top-92 pd-bottom-92">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 bg-cribbee">
                        
                        </div>
                        <div className="col-md-10">
                        <div className="col-md-9 row">
                            <div className="col-md-12 text-justify">
                                <h3>CASYTER: Una plataforma digital con todo lo necesario para promover tu propiedad</h3>
                                <p>.</p>

                                <p>.</p>

                                <h4>¿Quieres saber como funciona?</h4>
                                <button type="button" className="btn btn-yellow btn-block mt-2">¡Haz click aquí!</button>

                            </div>
                        </div>
                        </div>
                    
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default cribbee

