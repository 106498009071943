import React, { useState, useEffect, useRef } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropiedadesRecomendadas from './section-components/propiedades-recomendadas';
import Footer from './global-components/footer';
import { cribbeeAPI } from '../api/cribbeeAPI';
import InfiniteScroll from 'react-infinite-scroll-component';

const AvailableProperty = (props) => {

    let params = new URLSearchParams(props.location.search);
    const tipoQuery = params.get('tipo');
    const habitacionesQuery = params.get('habitaciones');
    const ubicacionQuery = params.get('ubicacion');
    const tipo_propiedadQuery = params.get('tipo_propiedad');
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [oneFetch, setOneFetch] = useState(true);


    const [dataFilter, setDataFilter] = useState({
        tipo: tipoQuery === null ? "" : tipoQuery,
        habitaciones: habitacionesQuery === null ? "" : habitacionesQuery,
        ubicacion: ubicacionQuery === null ? "" : ubicacionQuery,
        tipo_propiedad: tipo_propiedadQuery === null ? "" : tipo_propiedadQuery,
        precioMin: 0,
        precioMax: 0,
        buscador: "",
        construccion: "",
        terreno: "",
        bathrooms: "",
        estacionamientos: "",
    });

    const [data, setData] = useState([]);
    const [design, setDesign] = useState(2);
    const filedRef = useRef({});

    const getProperties = async ({ tipo, habitaciones, ubicacion, tipo_propiedad, precioMin, precioMax, buscador, construccion, terreno, bathrooms, estacionamientos }) => {
        try {
            const { data: { status, message, data } } = await cribbeeAPI.get(`properties-web?page=${page}&size=24&users=${localStorage.getItem('cribbeeUsuario')}&tipo=${tipo}&habitaciones=${habitaciones}&ubicacion=${ubicacion}&tipo_propiedad=${tipo_propiedad}&precioMin=${precioMin}&precioMax=${precioMax}&search=${buscador}&construccion=${construccion}&terreno=${terreno}&bathrooms=${bathrooms}&estacionamientos=${estacionamientos}`);

            if (!status) throw (message)
            const { currentPage, rows, totalItems, totalPages } = data;
            const otras = [];
            for (var index = 0; index < rows.length; index++) {
                const arrayServices = [];
                if (rows[index].rooms != null) {
                    arrayServices.push({ icon: 'fa fa-bed', value: rows[index].rooms, title: "" });
                }
                if (rows[index].bathrooms != null) {
                    arrayServices.push({ icon: 'fa fa-bath', value: rows[index].bathrooms, title: "" });
                }
                if (rows[index].square_meters_build != null) {
                    arrayServices.push({ icon: 'fa fa-object-ungroup', value: rows[index].square_meters_build, title: "mts2" });
                }
                if (rows[index].square_meters_build != null) {
                    arrayServices.push({ icon: 'fa fa-car', value: rows[index].parking_lots, title: "" });
                }
                if (arrayServices.length == 0) {
                    for (var indexS = 0; indexS < 2; indexS++) {
                        if (typeof rows[index].servicios[indexS] !== 'undefined') {
                            arrayServices.push({ icon: 'fa fa-check', value: rows[index].servicios[indexS].id, title: rows[index].servicios[indexS].service });
                        }
                    }
                }
                if (arrayServices.length == 0) {
                    if (rows[index].amenidades.length > 0) {
                        for (var indexA = 0; indexA < 2; indexA++) {
                            if (typeof rows[index].amenidades[indexA] !== 'undefined') {
                                arrayServices.push({ icon: 'fa fa-check', value: rows[index].amenidades[indexA].id, title: rows[index].amenidades[indexA].amenitie });
                            }
                        }
                    }
                }

                if (arrayServices.length == 0) {
                    if (rows[index].espacios.length > 0) {
                        for (var indexS = 0; indexS < 2; indexS++) {
                            if (typeof rows[index].espacios[indexS] !== 'undefined') {
                                arrayServices.push({ icon: 'fa fa-check', value: rows[index].espacios[indexS].id, title: rows[index].espacios[indexS].space });
                            }
                        }
                    }
                }
                otras.push({
                    image:
                    {
                        principal: rows[index].image[0] != undefined ? rows[index].image[0].source_path : "https://other.casyter.com/medium-image.png",
                        secundaria: rows[index].image[0] != undefined ? rows[index].image[0].small_source_path : "https://other.casyter.com/small-image.png",
                        secundarias: []
                    },
                    vendedor: `${rows[index].city}, ${rows[index].state}`,
                    externalTitle: rows[index].title,
                    precio: rows[index].price,
                    items: arrayServices,
                    url: `/detalle-propiedad/${rows[index].id}`,
                    description: rows[index].description,
                    plan: rows[index].plan_id,
                    id: rows[index].id,
                    likeProperty: rows[index].likeProperty,
                })
            }
            setHasMore(page < totalPages);
            setData((prevData) => prevData.concat(otras));
            setOneFetch(false);
        } catch (error) {

        }
    }



    useEffect(() => {
        getProperties(dataFilter);
    }, [dataFilter, page]);

    useEffect(() => {
        window.scrollTo({
            top: 400,
            behavior: "smooth"
        });
        if (tipoQuery != null) {
            filedRef.current.tipo.value = tipoQuery;
        }
        if (tipo_propiedadQuery != null) {
            filedRef.current.tipo_propiedad.value = tipo_propiedadQuery;
        }
        if (habitacionesQuery != null) {
            filedRef.current.habitaciones.value = habitacionesQuery;
        }
    }, []);
    const handleDesign = (tipo) => {
        setDesign(tipo);
    }

    const handleFilter = () => {
        const tipoValue = filedRef.current.tipo.options[filedRef.current.tipo.selectedIndex].value;
        const habitacionValue = filedRef.current.habitaciones.options[filedRef.current.habitaciones.selectedIndex].value;
        const tipoPropiedadValue = filedRef.current.tipo_propiedad.options[filedRef.current.tipo_propiedad.selectedIndex].value;
        const precioMinValue = filedRef.current.precio_min.value;
        const precioMaxValue = filedRef.current.precio_max.value;
        const buscadorValue = filedRef.current.buscador.value;
        const construccionValue = filedRef.current.construccion.value;
        const terrenoValue = filedRef.current.terreno.value;
        const bathroomsValue = filedRef.current.bathrooms.value;
        const estacionamientosValue = filedRef.current.estacionamientos.value;
        setPage(1);
        setHasMore(true);
        setData([]);
        setDataFilter({
            ...dataFilter,
            tipo: tipoValue,
            habitaciones: habitacionValue,
            tipo_propiedad: tipoPropiedadValue,
            precioMin: precioMinValue.replace("$", "").replace(/,/g, ""),
            precioMax: precioMaxValue.replace("$", "").replace(/,/g, ""),
            buscador: buscadorValue,
            terreno: terrenoValue,
            construccion: construccionValue,
            bathrooms: bathroomsValue,
            estacionamientos: estacionamientosValue,
        })
    }

    const [showFiltro, setShowFiltro] = useState(false);
    function formatNumber(n) {
        // format number 1000000 to 1,234,567
        return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    function formatCurrency(input, blur) {
        // appends $ to value, validates decimal side
        // and puts cursor back in right position.

        // get input value
        var input_val = input.value;


        // don't validate empty input
        if (input_val === "") { return; }

        // original length
        var original_len = input_val.length;

        // initial caret position 
        var caret_pos = input.selectionStart;

        // check for decimal
        if (input_val.indexOf(".") >= 0) {

            // get position of first decimal
            // this prevents multiple decimals from
            // being entered
            var decimal_pos = input_val.indexOf(".");

            // split number by decimal point
            var left_side = input_val.substring(0, decimal_pos);
            var right_side = input_val.substring(decimal_pos);

            // add commas to left side of number
            left_side = formatNumber(left_side);

            // validate right side
            right_side = formatNumber(right_side);

            // On blur make sure 2 numbers after decimal
            if (blur === "blur") {
                right_side += "00";
            }

            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);

            // join number by .
            input_val = "$" + left_side + "." + right_side;

        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = formatNumber(input_val);
            input_val = "$" + input_val;

            // final formatting
            if (blur === "blur") {
                input_val += ".00";
            }
        }
        // send updated string to input
        // input.value = "$" +input_val;
        input.value = input_val;

        // put caret back in the right position
        var updated_len = input_val.length;
        caret_pos = updated_len - original_len + caret_pos;
        input.setSelectionRange(caret_pos, caret_pos);
        const valueCurrent = parseFloat(input_val.replace(/,/g, "").replace("$", ""));

        if (valueCurrent >= 0) {
            return valueCurrent;
        } else {
            return 0;
        }
    }

    const [inputPrecio, setInputPrecio] = useState({ precioMin: "", precioMax: "" });
    const { precioMin, precioMax } = inputPrecio;
    return <div>
        <Navbar />
        <PageHeader headertitle="Listado de Propiedades" subheader="Propiedades" />
        <div className="properties-area pd-top-92 " >
            <div id="content-property" style={{ background: "#FFF" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 row">
                            <div className="mb-2 col-9">
                                <div className="rld-single-input">
                                    <input type="text" placeholder="Buscar..." name="buscador"
                                        ref={
                                            el => {
                                                filedRef.current = {
                                                    ...filedRef.current,
                                                    [`buscador`]: el
                                                };
                                            }
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-4 col-xl-3 col-lg-4 col-md-4 mb-2">
                                <div className="rld-single-select">
                                    <select className="select single-select m-0" name="tipo"
                                        ref={
                                            el => {
                                                filedRef.current = {
                                                    ...filedRef.current,
                                                    [`tipo`]: el
                                                };
                                            }
                                        }
                                    >
                                        <option value="">Renta / Venta</option>
                                        <option value="renta">Renta</option>
                                        <option value="venta">Venta</option>
                                    </select>
                                </div>
                            </div>

                            <div className={showFiltro ? "col-4 col-xl-3 col-lg-4 col-md-4 mb-2" : "col-4 col-xl-3 col-lg-4 col-md-4 mb-2 d-none"}>
                                <div className="rld-single-select">
                                    <select className="select single-select m-0" name="tipo_propiedad"
                                        ref={
                                            el => {
                                                filedRef.current = {
                                                    ...filedRef.current,
                                                    [`tipo_propiedad`]: el
                                                };
                                            }
                                        }
                                    >
                                        <option value="">Tipo de propiedad</option>
                                        <option value="Casa">Casa</option>
                                        <option value="Departamento">Departamento</option>
                                        <option value="Terreno">Terreno</option>
                                        <option value="Comercial">Comercial</option>
                                    </select>
                                </div>
                            </div>
                            <div className={showFiltro ? "col-4 col-xl-3 col-lg-4 col-md-4 mb-2" : "col-4 col-xl-3 col-lg-4 col-md-4 mb-2 d-none"}>
                                <div className="rld-single-select">
                                    <select className="select single-select m-0" name="habitaciones"
                                        ref={
                                            el => {
                                                filedRef.current = {
                                                    ...filedRef.current,
                                                    [`habitaciones`]: el
                                                };
                                            }
                                        }
                                    >
                                        <option value="todas"># Habitaciones</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5 o más</option>
                                    </select>
                                </div>
                            </div>
                            <div className={showFiltro ? "col-4 col-xl-3 col-lg-4 col-md-4 mb-2" : "col-4 col-xl-3 col-lg-4 col-md-4 mb-2 d-none"}>
                                <div className="rld-single-input">
                                    <input type="text" placeholder="Precio Mínimo" name="precio_min" defaultValue={precioMin}
                                        onKeyUp={
                                            e => {
                                                setInputPrecio({ ...inputPrecio, precioMin: formatCurrency(e.target) });
                                            }
                                        }
                                        ref={
                                            el => {
                                                filedRef.current = {
                                                    ...filedRef.current,
                                                    [`precio_min`]: el
                                                };
                                            }
                                        }
                                    />
                                </div>
                            </div>
                            <div className={showFiltro ? "col-4 col-xl-3 col-lg-4 col-md-4 mb-2" : "col-4 col-xl-3 col-lg-4 col-md-4 mb-2 d-none"}>
                                <div className="rld-single-input">
                                    <input type="text" placeholder="Precio Máximo" name="precio_max" defaultValue={precioMax}
                                        onKeyUp={
                                            e => {
                                                setInputPrecio({ ...inputPrecio, precioMax: formatCurrency(e.target) });
                                            }
                                        }
                                        ref={
                                            el => {
                                                filedRef.current = {
                                                    ...filedRef.current,
                                                    [`precio_max`]: el
                                                };
                                            }
                                        }
                                    />
                                </div>
                            </div>

                            <div className={showFiltro ? "col-4 col-xl-3 col-lg-3 col-md-4 mb-2" : "col-4 col-xl-3 col-lg-3 col-md-4 mb-2 d-none"}>
                                <div className="rld-single-input">
                                    <input type="number" placeholder="M2 Construcción" name="construccion"
                                        ref={
                                            el => {
                                                filedRef.current = {
                                                    ...filedRef.current,
                                                    [`construccion`]: el
                                                };
                                            }
                                        }
                                    />
                                </div>
                            </div>
                            <div className={showFiltro ? "col-4 col-xl-2 col-lg-3 col-md-3 mb-2" : "col-4 col-xl-2 col-lg-3 col-md-3 mb-2 d-none"}>
                                <div className="rld-single-input">
                                    <input type="number" placeholder="M2 Terreno" name="terreno"
                                        ref={
                                            el => {
                                                filedRef.current = {
                                                    ...filedRef.current,
                                                    [`terreno`]: el
                                                };
                                            }
                                        }
                                    />
                                </div>
                            </div>
                            <div className={showFiltro ? "col-4 col-xl-2 col-lg-3 col-md-3 mb-2" : "col-4 col-xl-2 col-lg-3 col-md-3 mb-2 d-none"}>
                                <div className="rld-single-select">
                                    <select className="select single-select m-0" name="bathrooms"
                                        ref={
                                            el => {
                                                filedRef.current = {
                                                    ...filedRef.current,
                                                    [`bathrooms`]: el
                                                };
                                            }
                                        }
                                    >
                                        <option value="">Baños</option>
                                        <option value="1">1+</option>
                                        <option value="2">2+</option>
                                        <option value="3">3+</option>
                                        <option value="4">4+</option>
                                        <option value="5">5+</option>
                                    </select>
                                </div>
                            </div>
                            <div className={showFiltro ? "col-4 col-xl-3 col-lg-4 col-md-4 mb-2" : "col-4 col-xl-3 col-lg-4 col-md-4 mb-2 d-none"}>
                                <div className="rld-single-select">
                                    <select className="select single-select m-0" name="estacionamientos"
                                        ref={
                                            el => {
                                                filedRef.current = {
                                                    ...filedRef.current,
                                                    [`estacionamientos`]: el
                                                };
                                            }
                                        }
                                    >
                                        <option value="">Estacionamientos</option>
                                        <option value="0">0</option>
                                        <option value="1">1+</option>
                                        <option value="2">2+</option>
                                        <option value="3">3+</option>
                                        <option value="4">4+</option>
                                    </select>
                                </div>
                            </div>
                            <div className={showFiltro ? "col-xl-2 col-lg-4 col-md-4" : "col-xl-2 col-lg-4 col-md-4 readeal-top mt-2"}>
                                <button className="btn btn-yellow" type="button" onClick={() => setShowFiltro(showFiltro ? false : true)}>{showFiltro ? "Menos" : "Mas"} Filtros</button>
                            </div>
                            <div className="col-xl-2 col-lg-4 col-md-4 readeal-top mt-2">
                                <button className="btn btn-yellow" type="button" onClick={() => handleFilter()}>BUSCAR</button>
                            </div>
                        </div>
                        <div className="col-12 d-flex justify-content-end">
                            <div style={{ cursor: 'pointer' }} onClick={() => handleDesign(1)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <rect fill={design == 1 ? "#2764ae" : "#000000"} opacity="0.3" x="4" y="4" width="4" height="4" rx="1" />
                                        <path d="M5,10 L7,10 C7.55228475,10 8,10.4477153 8,11 L8,13 C8,13.5522847 7.55228475,14 7,14 L5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 L14,7 C14,7.55228475 13.5522847,8 13,8 L11,8 C10.4477153,8 10,7.55228475 10,7 L10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,13 C14,13.5522847 13.5522847,14 13,14 L11,14 C10.4477153,14 10,13.5522847 10,13 L10,11 C10,10.4477153 10.4477153,10 11,10 Z M17,4 L19,4 C19.5522847,4 20,4.44771525 20,5 L20,7 C20,7.55228475 19.5522847,8 19,8 L17,8 C16.4477153,8 16,7.55228475 16,7 L16,5 C16,4.44771525 16.4477153,4 17,4 Z M17,10 L19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 L17,14 C16.4477153,14 16,13.5522847 16,13 L16,11 C16,10.4477153 16.4477153,10 17,10 Z M5,16 L7,16 C7.55228475,16 8,16.4477153 8,17 L8,19 C8,19.5522847 7.55228475,20 7,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,17 C4,16.4477153 4.44771525,16 5,16 Z M11,16 L13,16 C13.5522847,16 14,16.4477153 14,17 L14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 L10,17 C10,16.4477153 10.4477153,16 11,16 Z M17,16 L19,16 C19.5522847,16 20,16.4477153 20,17 L20,19 C20,19.5522847 19.5522847,20 19,20 L17,20 C16.4477153,20 16,19.5522847 16,19 L16,17 C16,16.4477153 16.4477153,16 17,16 Z" fill={design == 1 ? "#2764ae" : "#000000"} />
                                    </g>
                                </svg>
                            </div>

                            <div style={{ cursor: 'pointer' }} onClick={() => handleDesign(2)}>
                                <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M10,4 L21,4 C21.5522847,4 22,4.44771525 22,5 L22,7 C22,7.55228475 21.5522847,8 21,8 L10,8 C9.44771525,8 9,7.55228475 9,7 L9,5 C9,4.44771525 9.44771525,4 10,4 Z M10,10 L21,10 C21.5522847,10 22,10.4477153 22,11 L22,13 C22,13.5522847 21.5522847,14 21,14 L10,14 C9.44771525,14 9,13.5522847 9,13 L9,11 C9,10.4477153 9.44771525,10 10,10 Z M10,16 L21,16 C21.5522847,16 22,16.4477153 22,17 L22,19 C22,19.5522847 21.5522847,20 21,20 L10,20 C9.44771525,20 9,19.5522847 9,19 L9,17 C9,16.4477153 9.44771525,16 10,16 Z" fill={design == 2 ? "#2764ae" : "#000000"} />
                                        <ellipse fill={design == 2 ? "#2764ae" : "#000000"} stroke={design == 2 ? "#2764ae" : "#000000"} cx="5.041" cy="5.796" rx="1.395" ry="1.376" />
                                        <ellipse fill={design == 2 ? "#2764ae" : "#000000"} stroke={design == 2 ? "#2764ae" : "#000000"} cx="5.079" cy="12.039" rx="1.318" ry="1.376" />
                                        <ellipse fill={design == 2 ? "#2764ae" : "#000000"} stroke={design == 2 ? "#2764ae" : "#000000"} cx="5.156" cy="18.068" rx="1.318" ry="1.376" />
                                    </g>
                                </svg>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br></br>
            <div className="container">
                <InfiniteScroll
                    dataLength={data.length}
                    hasMore={hasMore}
                    next={() => {
                        if (data.length > 0) {
                            setPage((prevPage) => prevPage + 1)
                        }
                    }}
                    loader={<h4 className="text-center">Buscando...</h4>}
                    style={{ overflow: 'none' }}
                >
                    {
                        oneFetch ?
                            <div className="row">
                                <PropiedadesRecomendadas data={data} design={design} />
                            </div>
                            :
                            data.length > 0 ?
                                <div className="row">
                                    <PropiedadesRecomendadas data={data} design={design} />
                                </div>
                                :
                                <div class="text-center my-5">
                                    <h5>
                                        No encontramos una búsqueda que cumpla tus criterios, te invitamos a seguir navegando en la colmena.
                                    </h5>
                                </div>
                    }
                </InfiniteScroll>
            </div>
        </div>
        {/* <TopAuthor /> */}
        <Footer />
    </div>
}

export default AvailableProperty

