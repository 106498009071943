const axios = require('axios').default;

const cribbeeAPI = axios.create({
    //baseURL: 'http://localhost:4000/v1/api'
    baseURL: 'https://backend.casyter.com/v1/api'
    
});

cribbeeAPI.interceptors.request.use(config => {
    config.headers.Authorization = localStorage.getItem('cribbeeToken');
    return config;
}, error => {
    return Promise.reject(error);
});

module.exports = {
    cribbeeAPI
};