import React, { useContext } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import AddNew from './section-components/add-new';
import Footer from './global-components/footer';
import Modal from 'react-bootstrap/Modal';

import { Link } from 'react-router-dom';

import Login from './section-components/login';

import { AuthContext } from "./../auth/AuthContext";
const AddProperty = () => {

    const { checkingLoading } = useContext(AuthContext);
    const token = localStorage.getItem('cribbeeToken');
    // const [show, setShow] = useState(true);
    return (
        <>
            {checkingLoading === "" ?
                <></>
                :
                <div className="py-2 loader-container text-center" style={{ height: "100vh", overflowY: "scroll", overflowX: "hidden", zIndex: "9999" }}>
                    <div>
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="loader"></div>
                        </div>
                        <br />
                        <h4><b style={{ color: "#fbb138" }}>{checkingLoading}</b></h4>
                    </div>
                </div>
            }
            <div style={checkingLoading === '' ? {} : { display: "none" }}>
                <Navbar />
                <PageHeader headertitle="Publicar propiedad" />
                <AddNew />
                <Footer />
                <Modal
                    show={token != null ? false : true}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <div className="text-right pt-4 pr-4">
                        {/* <button type="button" className="close" onClick={() => { window.location.href = "/"; }}><span aria-hidden="true">&times;</span></button> */}
                        <Link className="close" to="/"><span aria-hidden="true">&times;</span></Link>
                    </div>
                    <Login />
                </Modal>
            </div>
        </>
    );
}

export default AddProperty

