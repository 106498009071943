import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import Login from './section-components/login';
const SignIn = () => {
	return (
		<>
			<Navbar />
			<PageHeader headertitle="Login" />
			<div className="contact-area pd-top-100 pd-bottom-65">
				<div className="container">
					<div className="row d-flex justify-content-center">
						<div className="col-lg-5 text-center">
							<Login />
						</div>
					</div>

				</div>
			</div>
			<Footer />
		</>
	);
}

export default SignIn

