import React, { useRef } from 'react'


const Textarea = (props) => {
    const { placeholder, name, value, required, maxLength, message, inputRef, section, onChange } = props;
    let { otros } = props;
    const field = useRef(false);
    if (value != '') {
        otros = {
            ...otros,
            "defaultValue": value.replace(/<br\s*[\/]?>/gi, "\n")
        }
    }

    return (
        <>
            <div className="d-flex justify-content-end">
                <span className="small">Número de Caracteres : {value.length.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}/{parseInt(maxLength).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
            </div>
            <div className="rld-single-input">
                <textarea
                    placeholder={placeholder}
                    maxLength={maxLength}
                    onChange={
                        (e) => {
                            onChange(name, e.target.value);
                            field.current && e.target.value !== '' ? field.current.classList.remove("is-invalid") : field.current.classList.add("is-invalid");
                        }
                    }
                    required={required}
                    {...(otros)}
                    ref={
                        (el) => {
                            inputRef(name, el);
                            field.current = el;
                        }
                    }
                    data-section={section}
                ></textarea>
                <div className="invalid-feedback">{message}</div>
            </div>
        </>
    )


}

export default Textarea;
